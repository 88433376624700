<div class='row header-row'>
  <div class='col-12 col-md-12'>
    <p class='header1'>REGISTRIEREN</p>
  </div>
  <div class='col-12 col-md-12 paragraph'>
    Falls Sie sich noch nicht registriert haben, können Sie dies über das folgende Formular tun.
    Füllen Sie alle Felder aus und klicken Sie anschließend auf den Button "Registrieren".
  </div>
</div>

  <div class='alert alert-danger' *ngIf='(error$ | async)'>
    <p>{{ error$| async }}</p>
  </div>
  <div *ngIf='(loginLoading$ | async)' style='text-align: center;'>
    <app-loading-spinner></app-loading-spinner>
  </div>

  <form [formGroup]='registerForm' (ngSubmit)='onSubmit()' *ngIf='(loginLoading$ | async) === false'>
    <div class="row topic-row">
      <div class="col-6 topic-col">
        <img class='icon' [src]='GENERAL_INFORMATION'>
        <h3>Allgemeine Informationen</h3>
      </div>
    </div>

    <div class='row form-container'>
      <div class='col-md-4 col-12'>
        <app-text-input label="Name des Unternehmens" formControlName="companyname"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Vorname" formControlName="firstName"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Nachname" formControlName="lastName"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Mobilnummer" formControlName="phoneMobile"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Büronummer" formControlName="phoneOffice"></app-text-input>
      </div>
    </div>

    <div class="row topic-row">
      <div class="col-6 topic-col">
        <img class='icon' [src]='ADDRESS_ICON'>
        <h3>Adresse</h3>
      </div>
    </div>

    <div class='row form-container'>
      <div class='col-md-4 col-12'>
        <app-text-input label="Straße" formControlName="street"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Hausnummer" formControlName="housenumber"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Postleitzahl" formControlName="plz"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Stadt" formControlName="city"></app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <mat-select formControlName='country'>
            <mat-option *ngFor='let country of countries | keyvalue' [value]='country.key'>
              {{country.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>



    </div>

    <div class="row topic-row">
      <div class="col-6 topic-col">
        <img class='icon' [src]='KEY_ICON'>
        <h3>Anmeldedaten</h3>
      </div>
    </div>

    <div class='row form-container'>
      <div class='col-md-4 col-12'>
        <app-text-input type="email"
                        label="E-Mail Adresse"
                        formControlName="email"
                        [inputType]="'email'">
        </app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input type="password"
                        label="Passwort"
                        formControlName="password"
                        [inputType]="'password'">
        </app-text-input>
      </div>
      <div class='col-md-4 col-12'>
        <app-text-input label="Passwort wiederholen" formControlName="confirmPassword"
                        [additionalErrorTypes]="['notSame']"
                        [errorStateMatcher]="matcher"
                        [inputType]="'password'">
        </app-text-input>
      </div>
    </div>

    <div class='row topic-row'>
      <div class='col-md-4 col-12'>
        <button mat-raised-button type='submit' class='register-button w-100' color='primary' [disabled]='!registerForm.valid'>
          Registrieren
        </button>
        <div class='paragraph'>Bereits registriert?
          <a [routerLink]="['/' + LOGIN]">Jetzt einloggen</a>
        </div>
      </div>
    </div>

  </form>
