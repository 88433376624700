import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {Material} from "../../model/material";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, MatPaginatorIntl} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Observable, Subject} from "rxjs";
import {EnumHelper} from "../../model/enum-helper";
import {selectEnumHelper} from "../../core/store/core.reducer";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {Const} from "../../helper/const";
import {MatPaginatorIntlCro} from "../material-sites-table/MatPaginatorIntlDigmin";
import {take} from "rxjs/operators";
import {ENUM_CONSTS} from "../../types/enum-identifier";
import {MatDialog} from "@angular/material/dialog";
import {OverallProgress} from "../../helper/overallProgress";

@Component({
  selector: 'app-materials-table',
  templateUrl: './materials-table.component.html',
  styleUrls: ['./materials-table.component.scss'],
  providers: [[{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}]]
})
export class MaterialsTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() materials: Material[] | null | undefined;
  @Input() isBookingState = false;
  @Input() isLoading$: Observable<boolean> | undefined;

  @Output() selectMaterial = new EventEmitter<Material>();
  @Output() editBookingClicked = new EventEmitter<boolean>();

  private cleanupSubject$ = new Subject<void>();

  readonly MATERIAL_FLOW_DIRECTION = ENUM_CONSTS.MATERIAL_FLOW_DIRECTION;
  readonly ZUORDNUNGSKLASSE = ENUM_CONSTS.ZUORDNUNGSKLASSE;
  readonly ERSATZBAUSTOFF = ENUM_CONSTS.ERSATZBAUSTOFFE;
  readonly ROUTES = Const.ROUTES;
  readonly ARROW_ICON = '../assets/icons/arrow_right_icon.svg'
  readonly BOOKING_ICON = '../assets/icons/booking_icon.svg'
  readonly CIRCLE_ICON = '../assets/icons/circle.svg'

  dataSource = new MatTableDataSource<Material>();
  displayedColumns: string[] = [];

  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);
  enumHelper: EnumHelper | null = null;

  selectedMaterialId: string | null = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(public dialog: MatDialog, private store: Store, private router: Router) { }

  ngOnInit(): void {
    if (this.isBookingState){
      this.displayedColumns = ['materialColumn', 'materialFlowColumn', 'timePeriodColumn', 'zuordnungsklasseColumn', 'ersatzbaustoffColumn', 'quantityColumn', 'volumeColumn', 'percentColumn'];
    } else {
      this.displayedColumns = ['materialColumn', 'materialFlowColumn', 'timePeriodColumn', 'zuordnungsklasseColumn', 'ersatzbaustoffColumn', 'quantityColumn', 'percentColumn', 'bookingButtonColumn', 'detailButtonColumn'];
    }

    this.enumHelper$.pipe(take(1)).subscribe(enumHelper => {
      this.enumHelper = enumHelper;
    })
  }

  ngAfterViewInit() {
    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['materials'].currentValue){
      if (this.materials){
        this.dataSource.data = this.materials;
      }
    }
  }

  ngOnDestroy(): void {
    this.cleanupSubject$.next();
    this.cleanupSubject$.complete();
  }

  materialSelected(material: Material, isBooking?: boolean) {
    this.editBookingClicked.emit(isBooking);
    this.selectMaterial.emit(material);
    this.selectedMaterialId = material.id;
  }
}
