<mat-form-field class="address-input w-100">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <img *ngIf="(inputHintIcon?.length ?? 0) > 0" matSuffix [src]="ICON_BASE + inputHintIcon" alt="Input type hint">
  <button type="button" [disabled]="control.disabled" matSuffix mat-icon-button aria-label="Clear" (click)="onClearButtonClick()" tabindex="-1">
    <img [src]="ICON_BASE + CLEAR_ICON" alt="Clear input">
  </button>

  <input type="text"
         matInput
         [formControl]="control"
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getAddressLabel">
    <mat-option class="autosuggestLoading" *ngIf="loadingState">
      <app-loading-spinner></app-loading-spinner>
    </mat-option>
    <mat-option *ngIf="!initialState && !loadingState && (addressOptions?.length ?? 0) === 0">
      Keine Ergebnisse
    </mat-option>
    <mat-option [title]="option?.label" *ngFor="let option of addressOptions" [value]="option">
      {{option?.label}}
    </mat-option>
  </mat-autocomplete>



  <mat-hint *ngIf="(hint?.length ?? 0) > 0" >{{ hint }}</mat-hint>
  <mat-error *ngIf="control?.hasError('required')">Das ist ein Pflichtfeld</mat-error>
</mat-form-field>


