import { Pipe, PipeTransform } from '@angular/core';
import { MaterialFlowDirection } from '../../enum/material-flow-direction';

@Pipe({
  name: 'materialFlowSearchWording',
})
export class MaterialFlowSearchWordingPipe implements PipeTransform {
  transform(value: string): string {
    if(value === MaterialFlowDirection.input) {
      return "Ich suche eine Entsorgung"
    }
    return "Ich suche eine Lieferung";
  }
}
