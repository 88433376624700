import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appFileTypeDirective]'
})
export class FileTypeDirective implements OnInit{
  @Input() file: string | undefined;
  readonly PDF_ICON = '../../assets/icons/pdf_icon.svg';

  @HostBinding('attr.src') imgSrc = '';

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    var fileExt = this.file?.split('.').pop();
    switch (fileExt){
      case 'pdf':
        this.imgSrc = this.PDF_ICON;
        break;
    }

  }
}
