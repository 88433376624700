import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import { BaseInputComponent } from '../base-input.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {AppDateAdapter} from "../../../helper/appDateAdapter";

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS},
  ],
})
export class DateInputComponent extends BaseInputComponent implements OnInit, OnDestroy {

  @Input() icon = 'start_date.svg';
  @Input() max: Date | undefined;
  @Input() min: Date | undefined;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
