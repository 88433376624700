import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DisplayEnumObject } from '../../../model/display-enum-object';
import { Observable, Subject, take } from 'rxjs';
import { EnumHelper } from '../../../model/enum-helper';
import { selectEnumHelper } from '../../../core/store/core.reducer';
import { Store } from '@ngrx/store';
import { MaterialFlowSearchWordingPipe } from '../../pipes/material-flow-search-wording.pipe';
import { MaterialSiteSearchRequest } from '../../../model/material-site-search-request';
import { MaterialType } from '../../../enum/material-type';
import { Zuordnungsklasse } from '../../../enum/zuordnungsklasse';
import { ProjectType } from '../../../enum/project-type';
import { formatDate } from '@angular/common';
import { FilterObject } from '../../../model/filter-object';
import { StaticSourceType } from '../../../enum/static-source-type';
import { ENUM_CONSTS } from '../../../types/enum-identifier';
import { Ersatzbaustoff } from '../../../enum/ersatzbaustoff';

@Component({
  selector: 'app-filter-sheet',
  templateUrl: './filter-sheet.component.html',
  styleUrls: ['./filter-sheet.component.scss'],
})
export class FilterSheetComponent implements OnDestroy, OnChanges {
  @Input() filterOverlayClosed = true;
  @Input() materialSiteSearchRequest: MaterialSiteSearchRequest | null = null;
  @Input() isInMaterialFlowMode = true;

  @Output() emitSearch: EventEmitter<MaterialSiteSearchRequest> = new EventEmitter();
  @Output() resetSearch: EventEmitter<boolean> = new EventEmitter();

  cleanupSubject = new Subject<void>();
  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);

  PROJECT_TYPES: DisplayEnumObject[] = [];
  MATERIAL_TYPES: DisplayEnumObject[] = [];
  ERSATZBAUSTOFFE: DisplayEnumObject[] = [];
  MATERIAL_FLOW_DIRECTION: DisplayEnumObject[] = [];
  QUALITY: DisplayEnumObject[] = [];
  STATIC_SOURCE_TYPES: DisplayEnumObject[] = [];

  materialTypeErsatzbaustoff: DisplayEnumObject | undefined;

  selectedMaterialTypes: DisplayEnumObject[] = [];
  selectedErsatzbaustoffe: DisplayEnumObject[] = [];
  selectedMaterialFlowDirection: FilterObject = { value: null };
  materialFlowPipe = MaterialFlowSearchWordingPipe;

  selectedQuality: DisplayEnumObject[] = [];
  selectedMinValue: FilterObject = { value: null };

  selectedDateFrom: FilterObject = { value: undefined };
  selectedDateTo: FilterObject = { value: undefined };

  showOwnProjects: boolean = true;
  selectedProjectTypes: DisplayEnumObject[] = [];

  showStaticSource: boolean = true;
  selectedStaticSourceTypes: DisplayEnumObject[] = [];

  lastMaterialSiteSearchRequest: MaterialSiteSearchRequest | null = null;

  constructor(private store: Store) {
    this.enumHelper$.pipe(take(1)).subscribe(enumHelper => {
      this.PROJECT_TYPES = enumHelper.projectType;
      this.MATERIAL_TYPES = enumHelper.materialType;
      this.ERSATZBAUSTOFFE = enumHelper.ersatzbaustoffe;
      this.MATERIAL_FLOW_DIRECTION = enumHelper.materialFlowDirectionUserNeed;
      this.QUALITY = enumHelper.zuordnungsklasse;
      this.STATIC_SOURCE_TYPES = enumHelper.staticSourceType;

      this.materialTypeErsatzbaustoff = enumHelper.getDisplayEnumObject(
        MaterialType.Ersatzbaustoff,
        ENUM_CONSTS.MATERIAL_TYPE
      );
    });
  }

  updateMaterialSiteSearchRequest() {
    let materialOccurrenceStart = undefined;
    if (this.selectedDateFrom?.value) {
      materialOccurrenceStart = formatDate(this.selectedDateFrom.value, 'yyyy-MM-dd', 'de');
    }
    let materialOccurrenceEnd = undefined;
    if (this.selectedDateTo?.value) {
      materialOccurrenceEnd = formatDate(this.selectedDateTo.value, 'yyyy-MM-dd', 'de');
    }

    let types = [];
    if (this.showStaticSource) {
      types.push('staticSource');
    }
    if (this.showOwnProjects) {
      types.push('internalProject');
      types.push('externalProject');
    }

    let materialSiteSearchRequest: MaterialSiteSearchRequest = {
      materialTypes: this.selectedMaterialTypes.map<MaterialType>(selectedMaterialType => {
        return selectedMaterialType.value;
      }),
      materialErsatzbaustoffe: this.selectedErsatzbaustoffe.map<Ersatzbaustoff>(selectedErsatzbaustoff => {
        return selectedErsatzbaustoff.value;
      }),
      materialFlowDirection: this.selectedMaterialFlowDirection.value?.value,
      materialZuordnungsklassen: this.selectedQuality.map<Zuordnungsklasse>(selectedQuality => {
        return selectedQuality.value;
      }),
      projectTypes: this.selectedProjectTypes.map<ProjectType>(selectedProjectType => {
        return selectedProjectType.value;
      }),
      staticSourceTypes: this.selectedStaticSourceTypes.map<StaticSourceType>(selectedstaticSourceType => {
        return selectedstaticSourceType.value;
      }),
      materialMinVolumeInM3: this.selectedMinValue.value,
      materialOccurrencePeriodStart: materialOccurrenceStart,
      materialOccurrencePeriodEnd: materialOccurrenceEnd,
      types: types,
      includeDigiminStaticSources: this.showStaticSource,
    };

    // compare lastMaterialSiteSearchRequest with materialSiteSearchRequest
    if (JSON.stringify(this.lastMaterialSiteSearchRequest) !== JSON.stringify(materialSiteSearchRequest)) {
      this.lastMaterialSiteSearchRequest = materialSiteSearchRequest;
      this.emitSearch.emit(materialSiteSearchRequest);
    }
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['materialSiteSearchRequest']?.currentValue) {
      this.enumHelper$.pipe(take(1)).subscribe(enumHelper => {
        let materialSiteSearchRequest = changes['materialSiteSearchRequest'].currentValue;
        this.materialSiteSearchRequest = this.lastMaterialSiteSearchRequest = materialSiteSearchRequest;
        this.selectedMaterialTypes = this.MATERIAL_TYPES.filter(materialType => {
          return materialSiteSearchRequest.materialTypes?.includes(materialType.value);
        });
        this.selectedErsatzbaustoffe = this.ERSATZBAUSTOFFE.filter(ersatzbaustoff => {
          return materialSiteSearchRequest.materialErsatzbaustoffe?.includes(ersatzbaustoff.value);
        });
        this.selectedMaterialFlowDirection.value = enumHelper.getDisplayEnumObject(
          materialSiteSearchRequest.materialFlowDirection,
          ENUM_CONSTS.MATERIAL_FLOW_DIRECTION_USER_NEED
        );
        this.selectedQuality = this.QUALITY.filter(zuordnungsklasse => {
          return materialSiteSearchRequest.materialZuordnungsklassen?.includes(zuordnungsklasse.value);
        });

        this.selectedProjectTypes = this.PROJECT_TYPES.filter(projectType => {
          return materialSiteSearchRequest.projectTypes?.includes(projectType.value);
        });
        this.selectedStaticSourceTypes = this.STATIC_SOURCE_TYPES.filter(sourceTypes => {
          return materialSiteSearchRequest.staticSourceTypes?.includes(sourceTypes.value);
        });
        this.selectedMinValue.value = materialSiteSearchRequest.materialMinVolumeInM3;
        this.selectedDateTo.value = materialSiteSearchRequest.materialOccurrencePeriodEnd ?? null;
        this.selectedDateFrom.value = materialSiteSearchRequest.materialOccurrencePeriodStart ?? null;
        if (materialSiteSearchRequest.includeDigiminStaticSources !== undefined) {
          this.showStaticSource = materialSiteSearchRequest.includeDigiminStaticSources;
        }
        if (materialSiteSearchRequest.types?.includes('internalProject')) {
          this.showOwnProjects = true;
        } else {
          this.showOwnProjects = false;
        }
      });
    }
  }

  protected readonly MaterialType = MaterialType;
}
