import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from "@angular/core";
import {AbstractControlOptions, FormBuilder, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {MaterialFlowStateInterface} from "../../../../feature/material-flow/interface/material-flow-state.interface";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-material-filter-switch',
  templateUrl: './material-filter-switch.component.html',
  styleUrls: ['./material-filter-switch.component.scss']
})
export class MaterialFilterSwitchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedMaterialID: string | undefined;
  @Input() selectedMaterialIDsArray: string[] | undefined;
  @Input() allMaterialsSelected: boolean | undefined;
  @Output() selectedMaterialsEvent = new EventEmitter<string[]>();

  cleanupSubject = new Subject<void>();
  materialSelectionForm: FormGroup;


  constructor(private formBuilder: FormBuilder, private store: Store<MaterialFlowStateInterface>) {
    this.materialSelectionForm = this.formBuilder.group({
      selectedMaterials: [true],
    }, {} as AbstractControlOptions);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allMaterialsSelected']) {
      if (this.allMaterialsSelected === false) {
        this.materialSelectionForm.controls['selectedMaterials'].patchValue(false, {emitEvent: false})
      }
      if (this.allMaterialsSelected === true){
        this.materialSelectionForm.controls['selectedMaterials'].patchValue(true, {emitEvent: false})
      }
    }
  }

  ngOnInit() {
    this.materialSelectionForm.controls['selectedMaterials'].valueChanges.pipe(takeUntil(this.cleanupSubject)).subscribe(changedValues => {
      if (this.selectedMaterialID) {
        if (changedValues === true) {
          this.selectedMaterialIDsArray?.indexOf(this.selectedMaterialID) === -1 ? this.selectedMaterialIDsArray?.push(this.selectedMaterialID) : this.selectedMaterialIDsArray;
        } else if (changedValues === false) {
          let index = this.selectedMaterialIDsArray?.indexOf(this.selectedMaterialID);
          if (index != null) {
            this.selectedMaterialIDsArray?.splice(index, 1)
          }
        }
        this.selectedMaterialsEvent.emit(this.selectedMaterialIDsArray);
      }
    });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }
}
