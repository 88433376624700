import {NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {Const} from './helper/const';
import {HomeComponent} from './core/home/home.component';
import {LoginComponent} from './core/login/login.component';
import {AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo} from "@angular/fire/compat/auth-guard";
import {RegisterPageComponent} from "./core/registration/register-page.component";
import {AuthComponent} from "./core/auth/auth.component";
import {
  StaticMaterialSitesComponent
} from "./feature/material-sites/static-material-sites/static-material-sites.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([Const.ROUTES.LOGIN]);
const redirectLoggedInUserToDashboard = () => redirectLoggedInTo([Const.ROUTES.HOME_SCREEN.BASE]);
const appRoutes: Routes = [
  {
    path: Const.ROUTES.HOME_SCREEN.BASE,
    loadChildren: () => import('./feature/home-screen/home-screen.module').then(m => m.HomeScreenModule),
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: Const.ROUTES.MATERIAL_FLOW.BASE,
    loadChildren: () => import('./feature/material-flow/material-flow.module').then(m => m.MaterialFlowModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: Const.ROUTES.PROJECTS.BASE,
    loadChildren: () => import('./feature/material-sites/material-sites.module').then(m => m.MaterialSitesModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: Const.ROUTES.PROFILE.BASE,
    loadChildren: () => import('./feature/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  { path: '', component: HomeComponent,   canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInUserToDashboard }},
  { path: Const.ROUTES.REGISTER, component: RegisterPageComponent},
  { path: Const.ROUTES.LOGIN, component: LoginComponent},
  { path: Const.ROUTES.AUTH, component: AuthComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    // check app component. scrollPositionRestoration didnt work properly on forward navigation
    // scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
