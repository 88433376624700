<div class="container d-grid gap-3">
  <div class="row align-items-center">
    <div class="col-md-6 order-1 order-sm-1 order-md-0">
      <div class="row">
        <div class="col-md-12">
          <h1 class="header1">STOFFSTROMNAVIGATION</h1>
          <p class="header2">DEUTSCHLAND</p>
        </div>
      </div>
      <div class="row text-row">
        <div class="col-md-12">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 d-grid button-div">
          <button mat-raised-button type="button" color="primary" class="primary" [routerLink]="['/' + REGISTRATION]">Registrieren</button>
          <button mat-raised-button type="button" color="secondary" class="secondary" [routerLink]="['/' + LOGIN]">Einloggen</button>
        </div>
      </div>
    </div>
    <div class="col-md-6 order-0 order-sm-0 order-md-1">
      <div class="row">
        <div class="col-md-12" #lottieAnimation>
          <ng-lottie class="lottie"
           [options]="options"
           (animationCreated)="animationCreated($event)"
          ></ng-lottie>
        </div>
      </div>
    </div>
  </div>
</div>
