<div class="row mb-2 p-2 d-flex justify-content-between align-items-center dialog-title" mat-dialog-title>
  <div class="col-11 d-flex gap-2 align-items-center">
    <div><img [src]="CONE_ICON"></div>
    <div class="headline-text">{{data.materialSite?.name}}</div>
    <div class="seperator">|</div>
    <div class="type-flag" appColorPicker [checkValue]="data.material?.type">{{data?.material?.type}}</div>

    <ng-container *ngIf="data.material.quantity.isUnlimited; else isLimited">
      <div class="headline-text">(unendlich)</div>
    </ng-container>
    <ng-template #isLimited>
      <ng-container *ngIf="data.material.quantity.isMassPrimaryInput === true; else isVolumePrimaryInput">
        <div class="headline-text">{{(data?.material?.quantity?.massInTon | number : '1.0-2':'de') + ' t'}}</div>
      </ng-container>
      <ng-template #isVolumePrimaryInput>
        <div class="headline-text">{{(data?.material?.quantity?.volumeInM3 | number : '1.0-2':'de') + ' m³'}}</div>
      </ng-template>
    </ng-template>

    <ng-container *ngIf="data.material.flowDirection === 'input'; else isOutput">
      <div><img [src]="INPUT_ICON"></div>
    </ng-container>
    <ng-template #isOutput>
      <div><img [src]="OUTPUT_ICON"></div>
    </ng-template>
  </div>
  <div class="col-1 close-button">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <img [src]="CLOSE_ICON">
    </button>
  </div>
</div>

<div class="container content-container">
  <div class="row p-2">
    <div class="col-12">
      <h3>Buchung registrieren</h3>
    </div>
  </div>

  <mat-stepper [@.disabled]="true" linear #stepper>
    <ng-template matStepperIcon="edit">
      <app-icon class="check-icon" name="check_icon"></app-icon>
    </ng-template>
    <mat-step *ngIf='!this.referencedMaterialSite' [@.disabled]="true" [completed]="selectedProject !== null">
      <ng-template matStepLabel>Projekt auswählen</ng-template>
      <app-material-sites-table
        [isBookingState] = "true"
        [isStaticSource] = "false"
        [materialSitesLoading]="(materialSitesLoading$ | async)"
        [projects$]="projects$"
        (selectedMaterialSite)="setSelectedMaterialSite($event)">
      </app-material-sites-table>
    </mat-step>
    <mat-step [completed]="(selectedMaterial$ | async) !== null" [@.disabled]="true">
      <ng-template matStepLabel>Material auswählen</ng-template>
      <app-materials-table [materials]="(filteredMaterials$ | async)"
                           [isBookingState]="true"
                           [isLoading$]="isLoading$"
                           (selectMaterial)="handleSelectedMaterial($event)">
      </app-materials-table>
    </mat-step>
    <mat-step completed="false" [@.disabled]="true">
      <form [formGroup]="bookingForm" (ngSubmit)="addBooking()">
        <ng-template matStepLabel>Buchung hinzufügen</ng-template>
        <div class="row mb-2">
          <div class="col-4">
            <app-booking-input-tile [bookingForm]="bookingForm" [isMaterialFlowState]="true"></app-booking-input-tile>
          </div>
          <div class="col-8">
            <div class="row mb-3">
              <div class="col-4 progress-value">Gesamtstatus Buchungen</div>
              <div class="col-8 d-flex gap-2 align-items-center">
                <span class="progress-value">{{progressValue}}%</span>
                <mat-progress-bar class="progress" mode="determinate" color="primary"
                                  [value]="progressValue"></mat-progress-bar>
              </div>
            </div>
            <div class="col-12">
              <ng-container *ngIf="currentMaterialTransactions.length > 0; else noMaterialTransactions">
                <ng-container *ngFor="let materialTransaction of currentMaterialTransactions">
                  <app-booking-wizard-tile [material]="(selectedMaterial$ | async)"
                                           [materialTransaction]="materialTransaction"></app-booking-wizard-tile>
                </ng-container>
              </ng-container>

              <ng-template #noMaterialTransactions>
                <div class="row justify-content-center align-items-center no-booking-textbox">
                  <div class="col-12 justify-content-center align-items-center">
                    <p>Sie haben noch keine Buchung angelegt</p>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 p-0">
            <button mat-raised-button class="w-100" color="primary" type="submit" [disabled]='!bookingForm.valid'>
              Buchung hinzufügen
            </button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>

<mat-dialog-actions>
  <div class="container button-container">
    <div class="row justify-content-between align-self-end w-100 m-2">
      <div class="col-4">
        <button class="button-gray" mat-flat-button [mat-dialog-close]="false">Abbrechen</button>
      </div>
      <div class="col-8 d-flex justify-content-end gap-3">
        <button class="button-gray" mat-flat-button (click)="previousStep(stepper)" *ngIf="stepper.selectedIndex >= 1 ">
          Zurück
        </button>
        <ng-container *ngIf='referencedMaterialSite;else normalBooking'>
          <button class="custom-button" mat-raised-button color="primary" (click)="nextStep(stepper)"
                  [disabled]="(selectedMaterial$ | async) === null"
                  *ngIf="stepper.selectedIndex === 0">Weiter
          </button>
          <button class="custom-button" mat-raised-button color="primary" (click)="completeBooking(stepper)"
                  [mat-dialog-close]="true" *ngIf="stepper.selectedIndex === 1">Fertig
          </button>
        </ng-container>
        <ng-template #normalBooking>
          <button class="custom-button" mat-raised-button color="primary" (click)="nextStep(stepper)"
                  [disabled]="(stepper.selectedIndex === 0 && (selectedMaterialSite$ | async) === null) ||
                (stepper.selectedIndex === 1 && (selectedMaterial$ | async) === null)"
                  *ngIf="stepper.selectedIndex !== 2">Weiter
          </button>
          <button class="custom-button" mat-raised-button color="primary" (click)="completeBooking(stepper)"
                  [mat-dialog-close]="true" [disabled]="((materialTransactions$ | async)?.length === 0)"
                  *ngIf="stepper.selectedIndex === 2">Fertig
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</mat-dialog-actions>
