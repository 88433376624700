<div class="container main-container">

  <div class="row">
    <div class="col-12">
      <img class="img-fluid" [src]="test1">
    </div>
  </div>

  <div class="row header-row">
    <div class="col-12 col-md-12">
      <p class="header1" *ngIf="mode === 'resetPassword'">PASSWORT ZURÜCKSETZEN</p>
      <p class="header1" *ngIf="mode === 'verifyEmail'">E-MAIL VERIFIZIEREN</p>
    </div>
  </div>

  <div class="col-12 col-md-8 form-container" *ngIf="mode === 'resetPassword'">
    <form [formGroup]="frmSetNewPassword" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-6">
          <app-text-input type="password"
                          label="Passwort"
                          formControlName="password"
                          [inputType]="'password'">
          </app-text-input>
        </div>
        <div class="col-6">
          <app-text-input label="Passwort wiederholen" formControlName="confirmPassword"
                          [additionalErrorTypes]="['notSame']"
                          [errorStateMatcher]="matcher"
                          [inputType]="'password'">
          </app-text-input>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-6">
          <button mat-raised-button type="submit" class="w-100" color="primary" [disabled]="!frmSetNewPassword.valid">
            Bestätigen
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="col-12 col-md-8 form-container" *ngIf="mode === 'verifyEmail'">
    <div class="row">
      <div class="col-12 alert alert-danger" *ngIf="(authError)">
        <p>{{ authError }}</p>
      </div>

      <div class="col-12 text" *ngIf="(!authError)">
        <p>Ihre E-Mail Adresse wurde erfolgreich verifiziert!</p>
        <div class="col-md-6 col-12">
          <button mat-raised-button type="submit" class="w-100" color="primary" (click)="redirectMatFlow()">Zur
            Stoffstromsuche
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
