import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-map-key-dialog',
  templateUrl: './map-key-dialog.component.html',
  styleUrls: ['./map-key-dialog.component.scss']
})
export class MapKeyDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MapKeyDialogComponent>,) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
}
