import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../../service/auth.service';
import * as AuthActions from "../store/core.actions";
import {Store} from "@ngrx/store";
import {CoreStateInterface} from "../interface/core-state.interface";
import {selectIsAuthenticated, selectUser} from "../store/core.reducer";
import {User} from "../../model/user";
import {Const} from "../../helper/const";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy{
  readonly logo = 'assets/logo/Logo.png';
  readonly ROUTES = Const.ROUTES;

  private userSub!: Subscription;
  isAuthenticated$: Observable<boolean> = this.store.select(selectIsAuthenticated);
  user$: Observable< User | null > = this.store.select(selectUser);

  constructor(private authService: AuthService, private store: Store<CoreStateInterface>) { }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  onLogout(){
    this.store.dispatch(AuthActions.logoutStart());
  }
}
