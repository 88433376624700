import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {concatMap} from "rxjs";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {LocalStorageService} from "../../service/local-storage.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {selectMaterialSiteSearchRequest} from "./preferences-store.reducer";
import {PreferencesActions} from "./preferences-store.actions";

@Injectable()
export class PreferencesStoreEffects {
  getInfoBoxPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.getInfoBoxPreferencesStart, PreferencesActions.setInfoBoxPreferencesSuccess),
      concatMap(() => {
        return this.localStorageService.getInfoBoxPreferences().pipe(
          map((infoBoxPreferences) => {
            return PreferencesActions.getInfoBoxPreferencesSuccess({infoBoxPreferences: infoBoxPreferences})
          }),
        );
      })
    );
  });

  setInfoBoxPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.setInfoBoxPreferencesStart),
      map(({sectionType, show}) => {
        this.localStorageService.saveInfoBoxPreferences(sectionType, show);
        return PreferencesActions.setInfoBoxPreferencesSuccess()
      })
    )
  });


  getViewModeMaterialsPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.getViewModeMaterialsPreferencesStart, PreferencesActions.setViewModeMaterialsPreferencesSuccess),
      concatMap(() => {
        return this.localStorageService.getViewModeMaterialsPreferences().pipe(
          map((materialsViewModeIsTable) => {
            return PreferencesActions.getViewModeMaterialsPreferencesSuccess({materialsViewModeIsTable})
          }),
        );
      })
    );
  });

  setViewModeMaterialsPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.setViewModeMaterialsPreferencesStart),
      map(({materialsViewModeIsTable}) => {
        this.localStorageService.setViewModeMaterialsPreferences(materialsViewModeIsTable)
        return PreferencesActions.setViewModeMaterialsPreferencesSuccess()
      })
    )
  })

  getFilterPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.getFilterPreferencesStart),
      concatMap(() => {
        return this.localStorageService.getFilterPreferences().pipe(
          map((materialSiteSearchRequest) => {
            return PreferencesActions.getFilterPreferencesSuccess({materialSiteSearchRequest})
          })
        )
      })
    )
  })


  setFilterPrefs = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.setMaterialSiteSearchRequestForMaterialFlowComponent),
      concatLatestFrom(() => this.store.select(selectMaterialSiteSearchRequest)),
      map(([ , materialSiteFilter]) => {
        this.localStorageService.setFilterPreferences(materialSiteFilter)
        return PreferencesActions.setFilterPreferencesSuccess()
      })
    )
  })

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private localStorageService: LocalStorageService,
    private snackbar: MatSnackBar,
  ) {}
}
