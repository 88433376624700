import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";
import { environment } from '../../environments/environment';
import { MaterialSiteSearchRequest } from '../model/material-site-search-request';
import { MaterialSite } from '../model/material-site';

@Injectable({providedIn: 'root'})
export class ApiService {

  readonly GET_MATERIAL_SITES_URL = environment.API.BASE + environment.API.MATERIALSITE;
  readonly CREATE_MATERIAL_SITES_URL = environment.API.BASE + environment.API.MATERIALSITE;
  readonly UPDATE_MATERIAL_SITE_URL = environment.API.BASE + environment.API.MATERIALSITE;

  constructor(private httpClient: HttpClient) {
  }


  static getHeader(teamID?: string, langCode?: string, withoutContentType = false): {headers: HttpHeaders} {
    const headers = {
      headers: new HttpHeaders({
      })
    };
    if (teamID) {
      headers.headers = headers.headers.append('DigiMin-TeamId', teamID);
    }
    if (!withoutContentType) {
      headers.headers = headers.headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  createMaterialSite$(teamID: string | undefined, newMaterialSite: MaterialSite): Observable<MaterialSite> {
    const httpOptions = ApiService.getHeader(teamID);
    return this.httpClient.post<MaterialSite>(this.CREATE_MATERIAL_SITES_URL, newMaterialSite, httpOptions);
  }

  loadMaterialSites$(teamID: string | undefined): Observable<MaterialSite[]> {
    const httpOptions = ApiService.getHeader(teamID);
    return this.httpClient.get<MaterialSite[]>(this.GET_MATERIAL_SITES_URL,httpOptions);
  }

  deleteProject$(teamID: string | undefined, materialSiteID: string | undefined): Observable<void> {
    const httpOptions = ApiService.getHeader(teamID);
    return this.httpClient.delete<void>(this.GET_MATERIAL_SITES_URL + '/' + materialSiteID, httpOptions);
  }

  getFileForAttachment$(attachmentId: string, teamID: string): Observable<any> {
    const httpOptions = ApiService.getHeader(teamID);
    httpOptions.headers = httpOptions.headers.append('Cache-Control', 'max-age=604800');
    const url = environment.API.BASE + environment.API.ATTACHMENT + '/' + attachmentId + '/file';
    return this.httpClient.get(url, { ...httpOptions, responseType: 'blob' });
  }

  updateMaterialSite$(teamID: string | undefined, materialSite: MaterialSite): Observable<MaterialSite> {
    const httpOptions = ApiService.getHeader(teamID);
    return this.httpClient.put<MaterialSite>(this.UPDATE_MATERIAL_SITE_URL + '/' + materialSite.id, materialSite, httpOptions);
  }

  searchMaterialSites$(request: MaterialSiteSearchRequest, teamID: string): Observable<MaterialSite[]> {
    const httpOptions = ApiService.getHeader(teamID);
    const newRequest = structuredClone(request);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/search';
    return this.httpClient.post<MaterialSite[]>(url, JSON.stringify(newRequest), httpOptions);
  }
}
