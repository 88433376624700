<div class='container gray'>
  <div class='row document-row justify-content-start'>
    <div class='col-xl-4 col-12'>
      <p class='header'>Dokumente</p>
    </div>
  </div>

  <div class='row justify-content-start align-items-center'>
    <ng-container *ngFor='let attachment of (parentObjectList$ | async)?.attachments'>
      <div class='document-col col-xl-4 col-md-6 col-12' *ngIf="!attachment?.mimeType?.includes('image/')">

        <div class='document-loading-spinner' *ngIf='(loading$ | async)'>
          <app-loading-spinner></app-loading-spinner>
        </div>

        <ng-container *ngIf='(loading$ | async) === false'>
          <div class='row document-title-row justify-content-start align-items-center'>
            <div class='col-1 p-1 document-name'>
              <img class='fileIcon' appFileTypeDirective [file]='attachment?.filename' *ngIf='attachment?.filename'>
            </div>
            <div class='col-9 p-2 document-name'>{{attachment?.filename}}</div>
            <div class='col-2 p-1 document-button-container gx-5 gap-2' *ngIf='attachmentEdit'>
              <button mat-flat-button class='icon-button' (click)='deleteAttachment(attachment)'>
                <img [src]='deleteAttachmentIcon'>
              </button>
              <button mat-flat-button class='icon-button'>
                <a [download]=attachment.filename target='_blank' [href]="downloadMap.get(attachment?.id ?? '')">
                  <img [src]='downloadAttachmentIcon'>
                </a>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class='dropzone-col col-xl-4 col-md-6 col-12' *ngIf='attachmentEdit'>
      <div class='row document-dropzone justify-content-center align-items-center' (click)='fileUpload.click()'
           (fileDropped)='onFilesDropped($event)' appDnd>
        <input type='file'
               id='document-input'
               accept='text/*'
               style='display: none'
               (change)='onFileSelected($event)'
               #fileUpload
               multiple />

        <div class='customFileButton'>
          <h5 class='paragraph-text'>Neues Dokument hochladen</h5>
        </div>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-4'>
      <p class='header'>Bilder</p>
    </div>
  </div>

  <div class='row image-row justify-content-start align-items-center'>
    <ng-container *ngFor='let attachment of (parentObjectList$ | async)?.attachments'>
      <div class='img-col col-xl-4 col-md-6 col-12' *ngIf="attachment?.mimeType?.includes('image/')">
        <div class='image-loading-spinner' *ngIf='(loading$ | async)'>
          <app-loading-spinner></app-loading-spinner>
        </div>

        <ng-container *ngIf='(loading$ | async) === false'>
          <div class='button-container gx-5 gap-2' *ngIf='attachmentEdit'>
            <button mat-flat-button class='icon-button' (click)='deleteAttachment(attachment)'>
              <img [src]='deleteAttachmentIcon'>
            </button>
            <button mat-flat-button class='icon-button'>
              <a [download]=attachment.filename target='_blank' [href]="downloadMap.get(attachment?.id ?? '')">
                <img [src]='downloadAttachmentIcon'>
              </a>
            </button>

          </div>
          <img class='img' appDigiminImg [attachmentId]='attachment.id' [attachmentToken]='attachment.token'>
        </ng-container>
      </div>
    </ng-container>

    <div class='dropzone-col col-xl-4 col-md-6 col-12' *ngIf='attachmentEdit'>
      <div class='row image-dropzone justify-content-center align-items-center' id='imageDropzone' appDnd
           (fileDropped)='onFilesDropped($event)'
           (click)='imageUpload.click()'>
        <input type='file'
               id='image-input'
               accept='image/*'
               style='display: none'
               (change)='onFileSelected($event)'
               #imageUpload
               multiple />

        <div class='customFileButton'>
          <h5 class='paragraph-text'>Neues Bild hochladen</h5>
        </div>
      </div>
    </div>
  </div>


</div>
