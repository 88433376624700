import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MaterialSite } from '../../../../../../../model/material-site';
import {
  selectLoading,
  selectMaterialSitesToBookFrom,
  selectMaterialWithId
} from '../../../../../store/materialSites.reducer';
import { take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialSiteSearchRequest } from '../../../../../../../model/material-site-search-request';
import {searchMaterialSitesToBookFromStart} from "../../../../../store/materialSites.actions";
import {DisplayEnumObject} from "../../../../../../../model/display-enum-object";
import {EnumHelper} from "../../../../../../../model/enum-helper";
import {selectEnumHelper} from "../../../../../../../core/store/core.reducer";
import {ENUM_CONSTS} from "../../../../../../../types/enum-identifier";

@Component({
  selector: 'app-select-material-site-dialog',
  templateUrl: './select-material-site-dialog.component.html',
  styleUrls: ['./select-material-site-dialog.component.scss']
})
export class SelectMaterialSiteDialogComponent implements OnInit{

  isLoading$: Observable<boolean> = this.store.select(selectLoading);
  materialSitesToBookFrom$: Observable<MaterialSite[] | null> = this.store.select(selectMaterialSitesToBookFrom);

  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);
  MATERIAL_FLOW_DIRECTION: DisplayEnumObject[] = [];

  constructor(private store: Store, public dialogRef: MatDialogRef<SelectMaterialSiteDialogComponent>) { }

  newMaterialSite: MaterialSite| null = null;
  materialSiteSearchRequest : MaterialSiteSearchRequest | null = null;

  ngOnInit(): void {
    this.store.select(selectMaterialWithId).pipe(take(1)).subscribe(material => {
      if(material){
        this.enumHelper$.pipe(take(1)).subscribe(enumHelper => {
          this.MATERIAL_FLOW_DIRECTION = enumHelper.materialFlowDirection;
          let materialFlow = enumHelper.getDisplayEnumObject(material.flowDirection, ENUM_CONSTS.MATERIAL_FLOW_DIRECTION);
          if (materialFlow == enumHelper.getDisplayEnumObject("input", ENUM_CONSTS.MATERIAL_FLOW_DIRECTION)) {
            materialFlow = enumHelper.getDisplayEnumObject("output", ENUM_CONSTS.MATERIAL_FLOW_DIRECTION);
          }
          else {
            materialFlow = enumHelper.getDisplayEnumObject("input", ENUM_CONSTS.MATERIAL_FLOW_DIRECTION);
          }
          this.materialSiteSearchRequest =
            {
              materialTypes: [material.type],
              materialFlowDirection: materialFlow?.value,
              materialZuordnungsklassen: [],
              projectTypes: [],
              includeDigiminStaticSources: true,

              types: [
                "internalProject",
                "externalProject",
                "staticSource"
              ],
            }
          this.store.dispatch(searchMaterialSitesToBookFromStart({ request: this.materialSiteSearchRequest, newRequest: true}));
        });



        // TODO: Hier muss eine eigene Action mit eigenen Daten die verarbeitet werden.

      }
    });
  }

  newSelection(newMaterialSite: MaterialSite| null) {
    this.newMaterialSite = newMaterialSite
  }

  materialSiteSelected() {
      this.dialogRef.close(this.newMaterialSite)
  }

  materialSiteSearchRequestChanged(materialSiteSearchRequest: MaterialSiteSearchRequest) {
    this.store.dispatch(searchMaterialSitesToBookFromStart({request: materialSiteSearchRequest, newRequest: true}))
  }
}
