<div class='dialog-close' (click)='closeDetails()' *ngIf="(isGanttChart$ | async) === false">
  <img class='closeIcon' [src]='CLOSE_ICON'>
</div>

<div class="row justify-content-start">
  <div class='col-4 justify-content-center align-items-center details-box'>
    <div class='row content-row justify-content-center'>
      <div class='col-12 pt-2 details-paragraphs'>
        <h3 class='header3'>
          {{selectedMaterialSite?.name}}
        </h3>
      </div>

      <div class='col-12 d-flex details-paragraphs'>
        <app-icon class="address-icon flex-shrink-0" name="stroked_pin"></app-icon>
        <h4 class="header5 gray" *ngLet='selectedMaterialSite?.address as address'>
          {{address?.street}},  {{address?.zip}} {{address?.city}}
        </h4>
      </div>
      <ng-container
        *ngIf="materialSiteType.internalProject === (selectedMaterialSite?.type | enumStringValue:materialSiteType)">
        <div class="col-12 p-0">
          <button mat-raised-button color='primary' class='w-100'>
            <a class="link-color d-flex justify-content-between"
               [routerLink]="['/' + ROUTES.PROJECTS.BASE + '/' + ROUTES.PROJECTS.MYPROJECTS + '/' + ROUTES.PROJECTS.DETAIL + '/' + selectedMaterialSite?.id]">
              Details ansehen
              <app-icon class="arrow-icon" name="arrow_right"></app-icon>
            </a>
          </button>
        </div>
      </ng-container>
    </div>

    <ng-container
      *ngIf="materialSiteType.internalProject === (selectedMaterialSite?.type | enumStringValue:materialSiteType)">
      <div class="row content-row container-white">
        <div class="col-12 details-col">
          <h4 class="header5 gray mb-1">Ansicht</h4>
          <div class="row d-flex align-items-center">
            <div class="col-12 d-flex align-items-center gap-2">
              <div class="view-mode-section">
                <button mat-flat-button (click)="onMapView()"
                        [ngClass]="viewModeIsMap === true ? 'view-mode-active' : 'view-mode-inactive'">Karte
                </button>
                <button mat-flat-button (click)="onGanttView()"
                        [ngClass]="viewModeIsMap === false ? 'view-mode-active' : 'view-mode-inactive'">Gantt-Chart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(isGanttChart$|async) === false; else isGanttState">
      <div class="row content-row container-white">
        <div class="col-12 details-col">
          <h4 class="header5 gray mb-1">Material/ien</h4>
          <ng-container *ngFor='let material of selectedMaterialSite?.materials; let i = index'>
            <div class="row gap-1 material-container" [ngClass]="{'collapsable' : i > 1}" [class.collapse]="i > 1">
              <div class="col-12 material-info-col">
                <div class="colored-bg" appColorPicker
                     [checkValue]="material?.type">{{material?.type | enumStringValue: materialTypeEnum}}</div>

                <ng-container *ngIf='(material?.quantity?.isUnlimited ?? false) === true; else notUnlimited'>
                  <h5 class="header5">(Unendlich)</h5>
                </ng-container>
                <ng-template #notUnlimited>
                  <ng-container *ngIf="material?.quantity?.isMassPrimaryInput; else isVolumePrimaryInput">
                    <h5 class="header5">{{material?.quantity?.massInTon | number : '1.0-2':'de' }} t</h5>
                  </ng-container>
                  <ng-template #isVolumePrimaryInput>
                    <h5 class="header5">{{material?.quantity?.volumeInM3 | number : '1.0-2':'de' }} m³</h5>
                  </ng-template>
                </ng-template>

                <ng-container
                  *ngIf="(material?.flowDirection | enumStringValue:materialFlowDirectionEnum) === materialFlowDirectionEnum.input; else outputCase">
                  <div><img [src]="INPUT_ICON"></div>
                </ng-container>
                <ng-template #outputCase>
                  <div><img [src]="OUTPUT_ICON"></div>
                </ng-template>
              </div>

              <div class="col-12 button-col">
                <button
                  *ngIf="(material?.flowDirection | enumStringValue:materialFlowDirectionEnum) === materialFlowDirectionEnum.input"
                  mat-flat-button class="button-class w-100" (click)="openBookingWizard(material)">Entsorgung buchen
                </button>
                <button
                  *ngIf="(material?.flowDirection | enumStringValue:materialFlowDirectionEnum) === materialFlowDirectionEnum.output"
                  mat-flat-button class="button-class w-100" (click)="openBookingWizard(material)">Lieferung buchen
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMaterialSite?.materials?.length !== 0 || undefined">
            <div class="row gap-1" *ngIf="selectedMaterialSite!.materials!.length > 2">
              <div class="col-12 material-info-col">
                <button mat-flat-button type="button" data-bs-toggle="collapse" data-bs-target=".collapsable"
                        aria-expanded="false" aria-controls="material-container"
                        class="collapse-button w-100 collapsed">
                  <h5
                    class="header5 if-collapsed">{{'+ ' + (selectedMaterialSite!.materials!.length >= 4 ? selectedMaterialSite!.materials!.length - 2 : '') + ' weitere anzeigen'}}</h5>
                  <h5 class="header5 if-not-collapsed">{{'- Material/ien verbergen'}}</h5>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row content-row container-white">
        <div class="col-12 details-col">
          <h4 class="header5 gray">Projekttyp</h4>
          <h5 class="header5">{{selectedMaterialSite?.type | enumStringValue:materialSiteType}}</h5>
        </div>
        <ng-container
          *ngIf="materialSiteType.internalProject === (selectedMaterialSite?.type | enumStringValue:materialSiteType)">
          <div class="col-12 details-col">
            <h4 class="header5 gray">Bauvorhaben</h4>
            <ng-container *ngIf="selectedMaterialSite?.projectType; else noProjectType">
              <h5 class="header5">{{selectedMaterialSite?.projectType | enumStringValue:projectType}}</h5>
            </ng-container>
            <ng-template #noProjectType>
              <h5 class="header5">-</h5>
            </ng-template>
          </div>
          <div class="col-12 details-col">
            <h4 class="header5 gray">Zeitraum</h4>
            <h5 class="header5">
              {{selectedMaterialSite?.projectStart | date :'dd.MM.y'}} -
              {{selectedMaterialSite?.projectEnd | date :'dd.MM.y'}}
            </h5>
          </div>
          <div class="col-12 details-col">
            <h4 class="header5 gray">Projektphase</h4>
            <ng-container *ngIf="selectedMaterialSite?.projectPhase; else noProjectPhase">
              <h5 class="header5">{{selectedMaterialSite?.projectPhase | enumStringValue:projectPhase}}</h5>
            </ng-container>
            <ng-template #noProjectPhase>
              <h5 class="header5">-</h5>
            </ng-template>
          </div>
          <div class="col-12 details-col">
            <h4 class="header5 gray">Auftraggeber</h4>
            <h5 class="header5">{{selectedMaterialSite?.projectPrincipal ?? '-'}}</h5>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #isGanttState>
      <form [formGroup]="materialSelectionForm">
        <div class="row content-row container-white">
          <div class="col-12 details-col">
            <div class="col-12 d-flex align-items-center justify-content-between gap-3 button-col">
              <h4 class="header5 gray mb-1">Material/ien</h4>
              <mat-slide-toggle formControlName='selectedMaterials'></mat-slide-toggle>
            </div>

            <div class="row gap-1 material-container" *ngFor="let material of selectedMaterialSite?.materials">
              <div class="col-12 material-info-col">
                <div class="colored-bg" appColorPicker
                     [checkValue]="material?.type">{{material?.type | enumStringValue: materialTypeEnum}}</div>

                <ng-container *ngIf='(material?.quantity?.isUnlimited ?? false) === true; else notUnlimited'>
                  <h5 class="header5">(Unendlich)</h5>
                </ng-container>
                <ng-template #notUnlimited>
                  <h5 class="header5">{{material?.quantity?.massInTon | number : '1.0-2':'de' }} t</h5>
                </ng-template>

                <ng-container
                  *ngIf="(material?.flowDirection | enumStringValue:materialFlowDirectionEnum) === materialFlowDirectionEnum.input; else outputCase">
                  <div><img [src]="INPUT_ICON"></div>
                </ng-container>
                <ng-template #outputCase>
                  <div><img [src]="OUTPUT_ICON"></div>
                </ng-template>
              </div>

              <div class="col-12 d-flex align-items-center justify-content-between gap-3 button-col">
                <p class="paragraph m-0">Im Gantt-Chart anzeigen</p>
                <app-material-filter-switch [selectedMaterialIDsArray]="selectedMaterialIDsArray"
                                            [selectedMaterialID]="material?.id"
                                            [allMaterialsSelected]="allMaterialsSelected"
                                            (selectedMaterialsEvent)="getSelectedMaterials($event)"></app-material-filter-switch>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>

  </div>
</div>
