import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {AnimationItem} from "lottie-web";
import {Const} from "../../helper/const";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('lottieAnimation') lottieAnimation: ElementRef | undefined;

  options: AnimationOptions = {
    path: './assets/lotties/under.construction.json'
  };

  private animationItem: any;
  readonly LOGIN = Const.ROUTES.LOGIN;
  readonly REGISTRATION = Const.ROUTES.REGISTER;

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    // console.log(animationItem);
    this.animationItem = animationItem;
  }

}
