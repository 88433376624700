import { Directive, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {AttachmentService} from "../../service/attachment.service";

@Directive({
  selector: '[appDigiminImg]',
})
export class DigiminImgDirective implements OnInit, OnChanges, OnDestroy {
  // [N.P]: necessary to remove img element borders if no img src is provided
  readonly EMPTY_IMAGE_SRC = 'data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7';

  @HostBinding('attr.src') imgSrc = this.EMPTY_IMAGE_SRC;
  @HostBinding('class.skeleton-box') loading = true;

  @Input() attachmentId: string | undefined;
  @Input() attachmentToken: string | undefined;

  private cleanupSubject = new Subject<void>();

  // TODO: add file broken placeholder image src
  private readonly FILE_BROKEN_PLACEHOLDER = '';
  private readonly NO_ATTACHMENT_UPLOADED = '../../assets/images/excavator.png';
  private intersectionObserver: IntersectionObserver | undefined;
  private alreadyLoaded = false;

  constructor(private elementRef: ElementRef, private attachmentService: AttachmentService) {}

  ngOnInit() {
    this.createLastIntersectionObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.['attachmentId']?.firstChange && changes?.['attachmentId']?.currentValue) {
      this.getImg(changes['attachmentId'].currentValue, changes['attachmentToken'].currentValue);
    }
    // TODO: check if this is a adequate way to view a default image before any attachments are updated
    else {
      this.imgSrc = this.NO_ATTACHMENT_UPLOADED;
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
    this.intersectionObserver?.disconnect();
  }

  private getImg(attachmentId: string, attachmentToken: string): void {
      this.alreadyLoaded = false;
      this.attachmentService.getAttachmentUrl$(attachmentId, attachmentToken)
        .pipe(
          takeUntil(this.cleanupSubject)
        )
        .subscribe({
          next: (attachmentUrl) => {
            if (attachmentUrl) {
              this.imgSrc = attachmentUrl;
              this.loading = false;
              this.alreadyLoaded = true;
              this.intersectionObserver?.disconnect();
            }
          },
          error: err => {
            // console.log(err);
            this.loading = false;
            this.imgSrc = this.FILE_BROKEN_PLACEHOLDER;
          },
        });
  }

  private createLastIntersectionObserver(): void {
    let options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.5,
    };
    this.intersectionObserver = new IntersectionObserver((entries, observer) => {
      if (entries.length > 0) {
        const entry = entries[0];
        if (entry.isIntersecting && !this.alreadyLoaded) {
          if (this.attachmentId && this.attachmentToken) {
            this.getImg(this.attachmentId, this.attachmentToken);
          }
        }
      }
    }, options);
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }
}
