import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {MaterialSiteGanttSearchRequest} from "../../../model/material-site-gantt-search-request";
import {FilterObject} from "../../../model/filter-object";
import {DisplayEnumObject} from "../../../model/display-enum-object";
import {Observable, Subject, combineLatest} from "rxjs";
import {EnumHelper} from "../../../model/enum-helper";
import {selectEnumHelper} from "../../../core/store/core.reducer";
import {ProjectType} from "../../../enum/project-type";
import {
  selectLastGanttSearchRequest
} from "../../../feature/material-flow/store/material-flow.reducer";
import {ENUM_CONSTS} from "../../../types/enum-identifier";
import {StaticSourceType} from "../../../enum/static-source-type";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-gantt-filter-sheet',
  templateUrl: './gantt-filter-sheet.component.html',
  styleUrls: ['./gantt-filter-sheet.component.scss']
})
export class GanttFilterSheetComponent implements OnDestroy, OnInit {
  @Input() filterOverlayClosed = true;
  @Input() referenceMaterialSiteID: string | undefined;
  @Output() emitGanttSearch: EventEmitter<MaterialSiteGanttSearchRequest> = new EventEmitter();

  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);
  lastGanttSearchRequest$: Observable<MaterialSiteGanttSearchRequest | null> = this.store.select(selectLastGanttSearchRequest);
  private cleanupSubject = new Subject<void>();
  lastGanttSearch: MaterialSiteGanttSearchRequest | undefined;

  RADIUS: DisplayEnumObject[] = [];
  PROJECT_TYPES: DisplayEnumObject[] = [];
  DEPONIEKLASSEN: DisplayEnumObject[] = [];
  TYPES: DisplayEnumObject[] = [];

  INTERNAL_PROJECT: DisplayEnumObject | undefined;
  EXTERNAL_PROJECT: DisplayEnumObject | undefined;
  STATIC_SOURCE: DisplayEnumObject | undefined;

  selectedRadius: FilterObject = {value: null};

  showOwnProjects: boolean = true;
  selectedProjectTypes: DisplayEnumObject[] = [];

  showStaticSource: boolean = true;
  selectedTypes: DisplayEnumObject[] = [];

  constructor(private store: Store) {
  }

  ngOnInit() {
    /** Get the lastGanttRequest to set some initial Radius and to get the referenceMaterialSiteID and the
     includeMaterialIds which are necessary to display some gantt-materials at all;
     Initial gantt-search Parameters are currently set within the gantt-chart component OnInit **/

    combineLatest([this.enumHelper$, this.lastGanttSearchRequest$]).pipe(takeUntil(this.cleanupSubject)).subscribe(([enumHelper, lastGanttRequest]) => {
      this.RADIUS = enumHelper.radius;
      this.PROJECT_TYPES = enumHelper.projectType;
      this.DEPONIEKLASSEN = enumHelper.deponieklasse;
      this.TYPES = enumHelper.staticSourceType;

      this.INTERNAL_PROJECT = enumHelper.getDisplayEnumObject('internalProject', ENUM_CONSTS.MATERIAL_SITE_TYPE);
      this.EXTERNAL_PROJECT = enumHelper.getDisplayEnumObject('externalProject', ENUM_CONSTS.MATERIAL_SITE_TYPE);
      this.STATIC_SOURCE = enumHelper.getDisplayEnumObject('staticSource', ENUM_CONSTS.MATERIAL_SITE_TYPE);

      if (lastGanttRequest) {
        this.lastGanttSearch = lastGanttRequest;
        this.selectedRadius.value = enumHelper.getDisplayEnumObject(lastGanttRequest.searchRadius, ENUM_CONSTS.RADIUS);
      }
    });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  updateFilter() {
    let materialSiteTypes = []

    if (this.lastGanttSearch) {
      let ganttSearchRequest: MaterialSiteGanttSearchRequest = {
        ...this.lastGanttSearch,
      }

      if (this.selectedRadius?.value?.value != null) {
        ganttSearchRequest.searchRadius = this.selectedRadius?.value?.value;
      }

      if (this.showOwnProjects) {
        materialSiteTypes.push(this.INTERNAL_PROJECT?.value);
        materialSiteTypes.push(this.EXTERNAL_PROJECT?.value);
        ganttSearchRequest.projectTypes = this.selectedProjectTypes.map<ProjectType>(selectedProjectType => {
          return selectedProjectType.value
        });
      }

      if (this.showStaticSource) {
        materialSiteTypes.push(this.STATIC_SOURCE?.value);
        ganttSearchRequest.includeDigiminStaticSources = true;
        ganttSearchRequest.staticSourceTypes = this.selectedTypes.map<StaticSourceType>(selectedStaticSourceType => {
          return selectedStaticSourceType.value
        });
      } else {
        ganttSearchRequest.includeDigiminStaticSources = false;
      }
      ganttSearchRequest.types = materialSiteTypes

      if (JSON.stringify((this.lastGanttSearch)) !== JSON.stringify(ganttSearchRequest)) {
        this.lastGanttSearch = ganttSearchRequest;
        this.emitGanttSearch.emit(ganttSearchRequest);
      }
    }

  }
}
