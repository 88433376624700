import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2, SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.component';
import { AddressOption } from '../../../interface/address-option';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
export class AddressInputComponent extends BaseInputComponent implements OnInit, OnDestroy, OnChanges {
  readonly CLEAR_ICON = 'close_Icon.svg';

  @Input() inputHintIcon: string | undefined;
  @Input() inputPrefixIcon: string | undefined;
  @Input() placeholder: string = '';
  @Input() addressOptions: AddressOption[] = [];
  @Input() loadingState: boolean | undefined | null;

  @Output() clearButtonClicked = new EventEmitter<void>();

  initialState = true;

  constructor(private changeDetectorRef: ChangeDetectorRef, private renderer2: Renderer2, injector: Injector) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['addressOptions'] && !changes['addressOptions']?.firstChange) {
      this.initialState = false;
    }
  }

  onClearButtonClick(): void {
    this.control.patchValue('');
    this.clearButtonClicked.emit();
    this.initialState = true;
  }

  getAddressLabel(option: AddressOption) {
    return option?.label ?? '';
  }
}
