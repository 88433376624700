import {Injectable} from "@angular/core";
import {tapResponse} from "@ngrx/component-store";
import {MaterialSite} from "../../../../model/material-site";
import {ProjectFilter} from "../../../../model/project-filter";
import {Material} from "../../../../model/material";
import {MaterialTransaction} from "../../../../model/material-transaction";
import {concatMap, Observable, of, tap} from "rxjs";
import {MaterialSiteService} from "../../../../service/materialSite.service";
import {concatLatestFrom} from "@ngrx/effects";
import {selectTeam} from "../../../../core/store/core.reducer";
import {Store} from "@ngrx/store";
import {MaterialSiteSearchRequest} from "../../../../model/material-site-search-request";
import {ImmerComponentStore} from "ngrx-immer/component-store";

export interface BookingWizardState {
  materialSites: MaterialSite[] | null;
  loading: boolean;
  materialSiteId: string | null;
  mapMaterialSiteId: string | null;
  materialSitesFilter: ProjectFilter | null;
  filteredMaterials: Material[] | null;

  materialId: string | null;
  materialTransactionId: string | null;
  materialTransactions: MaterialTransaction[] | null;
}

@Injectable()
export class BookingWizardStore extends ImmerComponentStore<BookingWizardState> {

// Reducer

  readonly setSelectedMapMaterialSite = this.updater((state, mapMaterialSiteId: string) => {
    state.mapMaterialSiteId = mapMaterialSiteId;
  });

  readonly searchStart = this.updater((state, materialSitesFilter: ProjectFilter) => {
    state.materialSitesFilter = materialSitesFilter;
    state.loading = true;
  });

  readonly searchSuccess = this.updater((state, materialSites: MaterialSite[]) => {
    state.materialSites = materialSites.filter(materialSite => materialSite.id != state.mapMaterialSiteId);
    state.loading = false;
  });

  readonly searchFail = this.updater((state) => {
    state.loading = false;
  });

  readonly setSelectedProject = this.updater((state, materialSite: MaterialSite) => {
    state.materialSiteId = materialSite.id;

    state.filteredMaterials =
      materialSite.materials.filter( material => material.flowDirection === state.materialSitesFilter?.flowDirection
        && material.type === state.materialSitesFilter.materialType) ?? [];
  });

  readonly setSelectedMaterial = this.updater((state, material: Material) => {
    state.materialId = material.id;
  });

  readonly createMaterialTransactionStart = this.updater((state, materialTransaction: MaterialTransaction) => {
    state.loading = true;
  });

  readonly createMaterialTransactionSuccess = this.updater((state, materialTransaction: MaterialTransaction) => {
    state.loading = false;
    const draftMaterialSite = this.getMaterialSite(state.materialSites, state.materialSiteId);
    if (draftMaterialSite) {
      const draftMaterial = this.getMaterial(draftMaterialSite.materials, state.materialId);
      if (draftMaterial != null) {
        if (draftMaterial.transactions === null) {
          draftMaterial.transactions = [];
        }
        draftMaterial.transactions.unshift(materialTransaction);
      }
    }
  });

  readonly createMaterialTransactionFail = this.updater((state) => {
    state.loading = false;
  });


//Utility functions

  getMaterialSite = (materialSites: MaterialSite[] | null, materialSiteId: String | null): MaterialSite | null => {
    if (materialSiteId === null)
      return null;
    return materialSites?.find(materialSite => materialSite.id === materialSiteId) ?? null;
  };

  getMaterial = (materials: Material[] | null, materialId: String | null): Material | null => {
    if (materialId === null)
      return null;
    return materials?.find(material => material.id === materialId) ?? null;
  };


//Effects

  readonly startMaterialSitesSearch = this.effect((params$: Observable<{ materialSitesFilter: ProjectFilter, mapMaterialSiteID: string }>) => {
    return params$.pipe(
      tap(({materialSitesFilter, mapMaterialSiteID}) => {
        this.setSelectedMapMaterialSite(mapMaterialSiteID);
        this.searchStart(materialSitesFilter);
      }),
      concatLatestFrom(() => this.store.select(selectTeam)),
      concatMap(([{materialSitesFilter}, team]) => {

        const materialTypes = [];
        if (materialSitesFilter?.materialType) {
          materialTypes.push(materialSitesFilter.materialType);
        }
        const searchRequest: MaterialSiteSearchRequest = {
          projectTypes: materialSitesFilter?.projectType ? [materialSitesFilter?.projectType] : undefined,
          types: materialSitesFilter?.types ? materialSitesFilter?.types : undefined,
          staticSourceTypes: materialSitesFilter?.staticSourceType ? [materialSitesFilter?.staticSourceType] : undefined,
          materialTypes,
          materialFlowDirection: materialSitesFilter?.flowDirection,
        };

        if (!team?.id) {
          return of(this.searchFail());
        }
        return this.materialSiteService.searchMaterialSites$(searchRequest, team.id).pipe(
          tapResponse(
            (selectedMaterialSites) => this.searchSuccess(selectedMaterialSites),
            (error) => {
              this.searchFail();
            }
          )
        )
      }),
    );
  });

  readonly createMaterialTransaction = this.effect((params$: Observable<{ materialTransaction: MaterialTransaction }>) => {
    return params$.pipe(
      tap(({materialTransaction}) => {
        this.createMaterialTransactionStart(materialTransaction);
      }),
      concatLatestFrom(() => [
        this.store.select(selectTeam),
        this.materialSiteId$,
        this.materialId$
      ]),
      concatMap(([{materialTransaction}, team, materialSiteID, materialID]) => {
        if (!team?.id || materialSiteID == null || materialID == null) {
          return of(this.createMaterialTransactionFail());
        }
        return this.materialSiteService.createMaterialTransaction$(materialTransaction, materialSiteID, materialID, team.id).pipe(
          tapResponse(
            (createdMaterialTransaction) => {
              this.createMaterialTransactionSuccess(createdMaterialTransaction)
            },
            (error) => {
              this.createMaterialTransactionFail();
            }
          )
        )
      }),
    );
  });


//Selectors

  readonly materialSites$: Observable<MaterialSite[] | null> = this.select(state => state.materialSites);
  readonly loading$: Observable<boolean> = this.select(state => state.loading);
  readonly materialSiteId$: Observable<string | null> = this.select(state => state.materialSiteId);
  readonly materialSitesFilter$: Observable<ProjectFilter | null> = this.select(state => state.materialSitesFilter);
  readonly filteredMaterials$: Observable<Material[] | null> = this.select(state => state.filteredMaterials);
  readonly materialId$: Observable<string | null> = this.select(state => state.materialId);
  readonly materialTransactionId$: Observable<string | null> = this.select(state => state.materialTransactionId);

  readonly selectMaterialSiteWithId$ = this.select(
    this.materialSites$,
    this.materialSiteId$,
    (materialSites, selectedMaterialSiteId) => {
      if (selectedMaterialSiteId === null) {
        return null;
      }
      return materialSites?.find(materialSite => materialSite.id === selectedMaterialSiteId) ?? null;
    },
  );

  readonly selectMaterialWithId$ = this.select(
    this.selectMaterialSiteWithId$,
    this.materialId$,
    (selectedMaterialSite, selectedMaterialId) => {
      if (selectedMaterialSite === null || selectedMaterialId === null){
        return null;
      }
      return selectedMaterialSite?.materials.find(material => material.id === selectedMaterialId) ?? null;
    }
  );

  readonly selectMaterialTransactions$ = this.select(
    this.selectMaterialWithId$,
    (selectedMaterial) => {
      if (selectedMaterial === null){
        return null;
      }
      return selectedMaterial.transactions;
    },
  );

  readonly selectMaterialTransactionWithId$ = this.select(
    this.materialSites$,
    this.materialSiteId$,
    this.materialId$,
    this.materialTransactionId$,
    (materialSites, selectedMaterialSiteId, selectedMaterialId, selectedMaterialTransactionId) => {
      if (selectedMaterialSiteId === null || selectedMaterialId === null || selectedMaterialTransactionId) {
        return null;
      }
      return materialSites?.find(materialSite => materialSite.id === selectedMaterialSiteId)?.materials?.find(
        material => material.id === selectedMaterialSiteId)?.transactions?.find(
          materialTransaction => selectedMaterialTransactionId === materialTransaction.id) ?? null;
    },
  );

  constructor(private materialSiteService: MaterialSiteService, private store: Store) {
    super({
      materialSites: null,
      loading: true,
      materialSiteId: null,
      mapMaterialSiteId: null,
      materialSitesFilter: null,
      filteredMaterials: null,

      materialId: null,
      materialTransactionId: null,
      materialTransactions: null,
    });
  }

}
