<div class="container-fluid border-bottom">
  <nav class="navbar navbar-expand-lg header">
    <div class="container">
      <ng-container *ngIf="((user$| async)?.teams?.length !== 0); else notAuthenticated">
        <a class="navbar-brand" [routerLink]="['']"><img [src]="logo" class="logo"></a>
      </ng-container>
      <ng-template #notAuthenticated>
        <a class="navbar-brand"><img [src]="logo" class="logo"></a>
      </ng-template>
      <div class="justify-content-end" *ngIf="(isAuthenticated$ | async)">
        <ul class="navbar-nav me-3">
          <li *ngIf="(user$| async)?.teams?.length !== 0" class="nav-item">
            <a class="nav-link header-item" [routerLink]="['/' + ROUTES.HOME_SCREEN.BASE]" [routerLinkActive]="['routerActive']">Home</a>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse me-auto mb-2 mb-lg-0 justify-content-end" *ngIf="(isAuthenticated$ | async)">
        <ul class="navbar-nav me-3">
          <li *ngIf="(user$| async)?.teams?.length !== 0" class="nav-item">
            <a class="nav-link header-item" [routerLink]="['/' + ROUTES.MATERIAL_FLOW.BASE]" [routerLinkActive]="['routerActive']">Kartensuche</a>
          </li>
          <li *ngIf="(user$| async)?.teams?.length !== 0" class="nav-item">
            <a class="nav-link header-item" [routerLink]="['/' + ROUTES.PROJECTS.BASE, ROUTES.PROJECTS.MYPROJECTS]" [routerLinkActive]="['routerActive']">Meine Projekte</a>
          </li>
          <li *ngIf="(user$| async)?.teams?.length !== 0" class="nav-item">
            <a class="nav-link header-item" [routerLink]="['/' + ROUTES.PROJECTS.BASE, ROUTES.PROJECTS.STATIC]" [routerLinkActive]="['routerActive']">Quellen & Senken</a>
          </li>
          <li class="nav-item">
            <a class="nav-link header-item" [routerLink]="['/' + ROUTES.PROFILE.BASE]" [routerLinkActive]="['routerActive']">Profil</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
