import { ChangeDetectorRef, Component, forwardRef, Injector, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseInputComponent } from '../base-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent extends BaseInputComponent implements OnInit, OnDestroy {

  constructor(private changeDetectorRef: ChangeDetectorRef, private renderer2: Renderer2, injector: Injector) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

}
