import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'dateYearCounterFromMonthsNumber',
})
export class DateYearCounterFromMonthsNumberPipe implements PipeTransform{
  transform(value: number, earliestDateMonth: number, index: number): Date {
    let completeTimeSpan = (earliestDateMonth + (index));
    let result = 0;
    if (Math.floor(completeTimeSpan / 12) >= 1 && completeTimeSpan % 12 >= 1) {
      result = Math.floor(completeTimeSpan / 12);
    } else if (Math.floor(completeTimeSpan / 12) >= 1 && completeTimeSpan % 12 === 0) {
      result = Math.floor(completeTimeSpan / 12) - 1;
    }

    const date = new Date();
    date.setFullYear(result + value)
    return date;
  }
}
