<app-flat-button [matMenuTriggerFor]='disabled?null:menu' #menuTrigger='matMenuTrigger' [disabled]='disabled' [filter_name]='filter_name' [filter_values]='selectedValue.value'></app-flat-button>
<mat-menu #menu='matMenu'>
  <form [formGroup]='txtForm'>
    <div class='flat-filter-button-menu m-2' (click)='$event.stopPropagation()'>
      <app-text-input appearance='outline'
                      [inputType]="'number'" label='{{filter_name}}' formControlName='input'
                      [inputHintIcon]='cubicmeter_icon' class='flex-fill w-0'></app-text-input>
    </div>
    <app-flat-button-actions (submitValue)='okClicked()' (resetValue)='xClicked()'></app-flat-button-actions>
  </form>
</mat-menu>
