<button [disabled]="disabled" mat-raised-button (click)="overlayOpen = !overlayOpen" [class.active]="isActive" [class.inactive]="isActive" cdkOverlayOrigin #projectTypeFilterButton="cdkOverlayOrigin">
  {{filter_name}} {{value ? (': ' + value) : ''}}
  <img *ngIf="!isActive" [class.active]="isActive" [class.inactive]="isActive" [class.svg-color]="isActive" [src]="arrow_down_icon">
  <img *ngIf="isActive" [class.active]="isActive" [class.inactive]="isActive" [src]="arrow_down_white_icon">
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="projectTypeFilterButton"
  [cdkConnectedOverlayOpen]="overlayOpen"
  (backdropClick)="overlayOpen = !overlayOpen">
  <div class="overlay">
    <ng-content></ng-content>
  </div>
</ng-template>



