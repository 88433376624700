import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../model/user';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private httpClient: HttpClient) {
  }

  getOwnUser(): Observable<User> {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER;
    return this.httpClient.get<User>(url, httpOptions);
  }

  createOwnUser(email: string, firstname: string, lastname: string): Observable<User> {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER;
    const user: Partial<User> = {
      email,
      firstname,
      lastname
    };
    return this.httpClient.post<User>(url, JSON.stringify(user), httpOptions);
  }

  resetPassword(email: string) {
    const emailBody = {
      email
    };
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER + environment.API.RESET;
    return this.httpClient.post(url, JSON.stringify(emailBody), httpOptions);
  }

  deleteUser$() {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER;
    return this.httpClient.delete<User>(url, httpOptions);
  }

  updateUser(user: User): Observable<User> {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER;
    return this.httpClient.put<User>(url, JSON.stringify(user), httpOptions);
  }
}
