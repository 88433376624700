// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    locationId: 'europe-west3',
    apiKey: "AIzaSyAt6t2tokgz0WnNsbtjLdv1vaadYeTykmQ",
    authDomain: "digimin-dev.firebaseapp.com",
    projectId: "digimin-dev",
    storageBucket: "digimin-dev.appspot.com",
    messagingSenderId: "578799731898",
    appId: "1:578799731898:web:ed5b7b003167b816ae2e83"
  },
  production: false,
  // hereAppKey: 'yrw1AUCbLR_0X8NfEUEwvzIBp2CGLvFTpSiSWVuXqJg',
  hereAppKey: 'rrbZtWsHPkSP-XNEbDFAQN5bmn6g5kRD2dt7ai2QJ-o',
  geoapifyApiKey: '960e825984f34709a1c06d04927b6e46',
  API: {
    BASE: 'https://dev.api.digimin.de/api/v1',
    USER: '/user',
    TEAM: '/team',
    ADMIN: '/admin',
    TEAM_USER:'/teamUser',
    RESET: '/sendResetPasswordMessage',
    VERIFY: '/checkUserEmailVerificationStatus',
    MATERIALSITE: '/materialSite',
    MATERIAL: '/material',
    MATERIAL_TRANSACTION: '/materialTransaction',
    ATTACHMENT: '/attachment',
    IMPORT_MATERIAL_SITE: '/importProjects',
    GANTT_SEARCH: '/searchForGanttChart',
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
