import { MaterialSite } from '../../../model/material-site';
import { AddressOption } from '../../../interface/address-option';

export class SearchPoi {
  constructor(data: AddressOption | MaterialSite) {
    this.data = data;
  }
  data: AddressOption | MaterialSite;

  showRadius = false;
  selectedRadius = 50;
}
