<div class="d-flex flex-row justify-content-between">
  <div>
    <ng-container *ngIf="!isStaticSource">
      <button mat-raised-button class="gray-button" [routerLink]="['/' + ROUTES.MATERIAL_FLOW.BASE]">
        <img [src]="map_view_icon" class="button-icon">
        Zur Kartensuche
      </button>
    </ng-container>
  </div>
  <div class="d-flex flex-row" style="gap: 16px">
    <ng-container *ngIf="!isStaticSource; else staticSource">
      <button mat-raised-button class="gray-button" (click)="projectListUpload.click()">
        <img [src]="import_projects_icon" class="button-icon">
        Projektliste importieren
      </button>
      <input type="file"
             id="projectList-input"
             accept="text/csv"
             style="display: none"
             (change)="onFileSelected($event)"
             onclick="value = null"
             #projectListUpload>
      <button mat-raised-button color="primary" (click)="onNewProject()">
        <img [src]="add_icon" class="button-icon">
        Neues Projekt anlegen
      </button>
    </ng-container>
    <ng-template #staticSource>
      <button mat-raised-button color="primary" (click)="onNewStaticSource()">
        <img [src]="add_icon" class="button-icon">
        Neue Quelle / Senke anlegen
      </button>
    </ng-template>
  </div>
</div>
