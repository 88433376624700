import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

// set "allowSyntheticDefaultImports": true in the tsconfig.json to import H and onResize this way
import { MaterialSite } from '../../../model/material-site';
import { MaterialSiteSearchRequest } from '../../../model/material-site-search-request';
import H from '@here/maps-api-for-javascript';
import { EnumHelper } from '../../../model/enum-helper';
import { Subject } from 'rxjs';
import { MaterialFlowService } from '../../../service/material-flow.service';
import { Store } from '@ngrx/store';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { selectEnumHelper } from '../../../core/store/core.reducer';
import { take } from 'rxjs/operators';
import onResize from 'simple-element-resize-detector';
import { environment } from '../../../../environments/environment';
import { MapKeyDialogComponent } from '../map-key-dialog/map-key-dialog.component';
import { SearchPoi } from './model-map';
import { Utils } from '../../../helper/utils';
import { AddressOption } from 'src/app/interface/address-option';
import { MapUtils } from './utils-map';
import Strategy = H.clustering.Provider.Strategy;
import INoisePoint = H.clustering.INoisePoint;

const SEARCH_PLACEHOLDER_IMG = new H.map.Icon('assets/icons/pin_my_search.svg');
const SEARCH_PLACEHOLDER_ADDON_IMG = new H.map.Icon('assets/icons/search-addon.svg');

const _apikey = environment.hereAppKey;

@Component({
  selector: 'app-default-map',
  templateUrl: './default-map.component.html',
  styleUrls: ['./default-map.component.scss'],
  providers: [MaterialFlowService, DecimalPipe],
})
export class DefaultMapComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() width: string = '100%';
  @Input() height: string = '500px';

  @Input() materialSites: MaterialSite[] = [];
  @Input() selectedMaterialSite: MaterialSite | null | undefined;
  @Input() activeSearch: SearchPoi | null | undefined;
  @Input() radiusValue = 0;

  @Output() selectedMaterialSiteEvent = new EventEmitter<MaterialSite | null>();
  @Output() emitSearch: EventEmitter<MaterialSiteSearchRequest> = new EventEmitter();
  @Output() emitSelectedMaterialSiteEvent = new EventEmitter<MaterialSite>();

  private readonly DEFAULT_LOCATION = {
    lat: 51.158627,
    lng: 10.445921,
  };
  private readonly DEFAULT_ZOOM = 7;

  @ViewChild('map') mapDiv?: ElementRef;

  private ui: any;
  private map!: H.Map;

  private searchMarker: H.map.Marker | undefined = undefined;
  private searchMarkerAddOn: H.map.Marker | undefined = undefined;
  private bubblesArr: H.ui.InfoBubble[] = [];

  private radiusCircleLayer: H.map.layer.Layer | undefined;

  private selectedMarkerLayer: H.map.layer.Layer | undefined;
  private selectedMarkerProvider: H.map.provider.LocalObjectProvider | undefined;

  private dataLayer: H.map.layer.Layer | undefined;
  private clusteredDataProvider: H.clustering.Provider | undefined;

  private hiddenObject: H.map.Object | undefined;

  private isMarker = false;
  private enumHelper?: EnumHelper;
  private cleanupSubject = new Subject<void>();

  private zoom: number | undefined;

  selectedMaterialSiteMarker: H.map.Marker | null = null;
  materialMarkerGroup: any;

  constructor(
    private materialFlowService: MaterialFlowService,
    private store: Store,
    private decimalPipe: DecimalPipe,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.map) {
      if (changes['materialSites']?.currentValue) {
        this.updateMap();
      }
      if (changes['activeSearch']) {
        this.updateActiveSearch();
      }
      if (changes['selectedMaterialSite']) {
        this.updateMaterialMarker();
      }
      if (changes['radiusValue']) {
        this.updateRadiusCircle();
      }
    }
  }

  ngOnInit() {
    this.store
      .select(selectEnumHelper)
      .pipe(take(1))
      .subscribe(enumHelper => {
        this.enumHelper = enumHelper;
      });
  }

  ngAfterViewInit(): void {
    this.initializeHereMap();
    this.updateMap();
  }

  initializeHereMap(): void {
    if (!this.map && this.mapDiv) {
      let platform = new H.service.Platform({
        apikey: _apikey,
      });

      let layers = platform.createDefaultLayers({
        lg: 'de',
      });

      this.map = new H.Map(this.mapDiv.nativeElement, layers.vector.normal.map, {
        pixelRatio: window.devicePixelRatio,
        center: { lat: this.DEFAULT_LOCATION.lat, lng: this.DEFAULT_LOCATION.lng },
        zoom: this.zoom ?? this.DEFAULT_ZOOM,
      });

      new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      this.ui = H.ui.UI.createDefault(this.map, layers, 'de-DE');
      const mapSettings = this.ui.getControl('mapsettings');
      const zoom = this.ui.getControl('zoom');
      const scalebar = this.ui.getControl('scalebar');

      mapSettings.setAlignment('bottom-right');
      scalebar.setAlignment('bottom-right');
      zoom.setAlignment('bottom-top');

      onResize(this.mapDiv.nativeElement, () => {
        this.map.getViewPort().resize();
      });

      this.map.addEventListener(
        'pointermove',
        (event: { target: any }) => {
          if (event.target instanceof H.map.Marker) {
            this.map.getViewPort().element.style.cursor = 'pointer';
          } else {
            this.map.getViewPort().element.style.cursor = 'auto';
          }
        },
        false
      );
    }
  }

  private updateMap() {
    this.removeObjects();
    this.setClusterMaterialMarkers();
  }

  private removeObjects(): void {
    if (this.dataLayer) {
      this.map.removeLayer(this.dataLayer);
      this.dataLayer = undefined;
      this.clusteredDataProvider?.removeEventListener('tap', this.onMarkerClick.bind(this), false);
      this.clusteredDataProvider = undefined;
    }
    if (this.isMarker) {
      if (this.materialMarkerGroup) {
        this.map.removeObject(this.materialMarkerGroup);
        this.materialMarkerGroup.removeAll();
        this.materialMarkerGroup = undefined;
      }
      this.bubblesArr = this.ui.getBubbles();
      for (let bubble of this.bubblesArr) {
        bubble.close();
      }
    }
  }

  setClusterMaterialMarkers() {
    const dataPoints = [];
    for (let i = 0; i < this.materialSites.length; i++) {
      let lat = this.materialSites[i].geoLocation.latitude!;
      let lng = this.materialSites[i].geoLocation.longitude!;

      const noisePoint = new H.clustering.DataPoint(lat, lng);
      noisePoint['data'] = this.materialSites[i];
      dataPoints.push(noisePoint);
    }

    this.clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      min: 4,
      clusteringOptions: {
        eps: 40,
        minWeight: 2,
        strategy: Strategy.FASTGRID,
      },
      theme: {
        getClusterPresentation: function (cluster) {
          const domIcon = new H.map.DomIcon(
            "<div style='cursor: pointer;position: relative;text-align: center;color: white;display: inline;'><div><img class='icon' style='width:36px;height:36px;opacity: 0.8' src='../assets/icons/cluster.svg'><span style='font-size: 11px; position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);'>" +
              cluster.getWeight() +
              '</span></div></div>'
          );
          const truckMarker = new H.map.DomMarker(cluster.getPosition(), {
            icon: domIcon,
            data: {},
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
          });
          truckMarker.setData(cluster);

          return truckMarker;
        },
        getNoisePresentation: (noisePoint: INoisePoint) => {
          let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
            zIndex: 50,
            data: noisePoint.getData(),
            volatility: true,
            min: noisePoint.getMinZoom(),
          });
          noiseMarker.setData(noisePoint);
          noiseMarker.setIcon(MapUtils.getMaterialSiteIcon(noisePoint.getData() as MaterialSite));
          noiseMarker.addEventListener('pointerenter', this.onPointerEnter.bind(this));
          noiseMarker.addEventListener('pointerleave', this.onPointerLeave.bind(this));
          return noiseMarker;
        },
      },
    });

    this.clusteredDataProvider.addEventListener('tap', this.onMarkerClick.bind(this), false);
    this.dataLayer = new H.map.layer.ObjectLayer(this.clusteredDataProvider);
    this.map.addLayer(this.dataLayer, 2);
  }

  onMarkerClick(event: { target: H.map.Marker; currentPointer: any }): void {
    if (!event.target.getData().isCluster()) {
      this.closeBubbles();
      const mapObject = event.target as H.map.Object;
      const materialSite = mapObject.getData().getData();
      this.emitSelectedMaterialSiteEvent.emit(materialSite);
    } else {
      this.map.setCenter(
        this.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY) as H.geo.Point
      );
      this.map.setZoom(this.map.getZoom() + 1, false);
    }
  }

  onPointerEnter(event: { target: H.map.Marker }): void {
    const target = event.target;
    if (target.getData().isCluster()) {
      return;
    }
    const hoverMaterialSite: MaterialSite = target.getData().getData();
    let myHTML = MapUtils.createBubbleHTML(hoverMaterialSite, this.enumHelper, this.decimalPipe);
    let targetGeoPosition = MapUtils.correctBubblePosition(target.getGeometry() as H.geo.Point, this.map);
    if (targetGeoPosition) {
      const bubble = new H.ui.InfoBubble(targetGeoPosition, {
        content: myHTML,
      });
      bubble.addClass('infoBubble');
      this.ui.addBubble(bubble);
    }
  }

  onPointerLeave(event: H.util.Event): void {
    this.closeBubbles();
  }

  private closeBubbles() {
    this.ui.getBubbles().forEach((bubble: H.ui.InfoBubble) => this.ui.removeBubble(bubble));
  }

  private updateActiveSearch() {
    if (this.searchMarker) {
      if (this.searchMarkerAddOn) {
        this.map.removeObject(this.searchMarkerAddOn);
        this.searchMarkerAddOn = undefined;
      }
      this.map.removeObject(this.searchMarker);
      this.searchMarker = undefined;
    }
    if (this.activeSearch) {
      if (Utils.isAddressOption(this.activeSearch.data)) {
        let addressOption = this.activeSearch.data as AddressOption;
        this.searchMarker = new H.map.Marker(
          { lat: addressOption.lat, lng: addressOption.lon },
          { icon: SEARCH_PLACEHOLDER_IMG, data: undefined }
        );
      } else if (Utils.isMaterialSite(this.activeSearch.data)) {
        let materialSite = this.activeSearch.data as MaterialSite;
        let geoLocation = materialSite.geoLocation;
        this.searchMarkerAddOn = new H.map.Marker(
          { lat: geoLocation.latitude!, lng: geoLocation.longitude! },
          { icon: SEARCH_PLACEHOLDER_ADDON_IMG, data: undefined }
        );
        this.searchMarker = new H.map.Marker(
          { lat: geoLocation.latitude!, lng: geoLocation.longitude! },
          { icon: MapUtils.getMaterialSiteIcon(materialSite), data: undefined }
        );
      }
      if (this.searchMarker) {
        if (this.searchMarkerAddOn) {
          this.map.addObject(this.searchMarkerAddOn);
        }
        this.map.addObject(this.searchMarker);
        this.map.setCenter(this.searchMarker.getGeometry() as H.geo.Point);
        if (this.map.getZoom() < 12) {
          this.map.setZoom(12);
        }
      }
    }
    this.updateRadiusCircle();
  }

  private updateRadiusCircle() {
    if (this.radiusCircleLayer) {
      this.map.getLayers().remove(this.radiusCircleLayer);
      this.radiusCircleLayer = undefined;
    }
    if (this.searchMarker && this.radiusValue > 0) {
      let geometry = this.searchMarker.getGeometry() as H.geo.Point;
      const circle = new H.map.Circle({ lat: geometry.lat, lng: geometry.lng }, this.radiusValue * 1000 ?? 0, {
        // @ts-ignore
        style: {
          strokeColor: 'rgba(0,0,0, .15)', // Color of the perimeter
          lineWidth: 2,
          fillColor: 'rgba(0,0,0, .15)', // Color of the circle
        },
        zIndex: 1,
      });
      const objectProvider = new H.map.provider.LocalObjectProvider();
      this.radiusCircleLayer = new H.map.layer.ObjectLayer(objectProvider);
      objectProvider.getRootGroup().addObject(circle);
      this.map.getLayers().add(this.radiusCircleLayer, 1);
      const circleBoundingBox = circle.getBoundingBox();
      if (circleBoundingBox) {
        this.map.getViewModel().setLookAtData({ bounds: circleBoundingBox });
      }
    }
  }

  private updateMaterialMarker(): void {
    if (this.selectedMarkerLayer) {
      this.map.removeLayer(this.selectedMarkerLayer);
      this.hiddenObject?.setVisibility(true);
    }
    if (this.selectedMaterialSite) {
      this.selectedMaterialSiteMarker = new H.map.Marker(
        { lat: this.selectedMaterialSite.geoLocation.latitude!, lng: this.selectedMaterialSite.geoLocation.longitude! },
        { icon: MapUtils.getMaterialSiteSelectIcon(this.selectedMaterialSite), data: undefined }
      );
      this.selectedMarkerProvider = new H.map.provider.LocalObjectProvider();
      this.selectedMarkerLayer = new H.map.layer.ObjectLayer(this.selectedMarkerProvider);
      this.selectedMarkerProvider.getRootGroup().addObject(this.selectedMaterialSiteMarker);
      this.map.addLayer(this.selectedMarkerLayer, 10);

      this.hiddenObject = MapUtils.hideSmallIconMarkerForSelected(
        this.selectedMaterialSite,
        this.clusteredDataProvider?.getRootGroup()?.getObjects() ?? []
      );
    }
  }

  openMapKey() {
    const dialogRef = this.dialog.open(MapKeyDialogComponent, {
      disableClose: true,
      width: '640px',
    });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }
}
