import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AddressOption } from '../interface/address-option';
import { HereService } from './here.service';
import { GeoapifyService } from './geoapify.service';
import { GeoSearchAccuracy } from '../types/geo-search-accuracy';
import {GeoLocation} from "../model/geo-location";

@Injectable({ providedIn: 'root' })
export class GeoService {
  currentGeoService: HereService | GeoapifyService;

  constructor(private hereService: HereService, private geoapifyService: GeoapifyService) {
    this.currentGeoService = this.hereService;
  }

  getSuggestions$(query: string, accuracy: GeoSearchAccuracy): Observable<Array<AddressOption>> {
    return this.currentGeoService.getSuggestions$(query, accuracy).pipe(
      catchError((err: HttpErrorResponse) => {
        // If call fails because of api limit try different service
        if (err.status === 401 || err.status === 429) {
          if (!(this.currentGeoService instanceof GeoapifyService)) {
            this.currentGeoService = this.geoapifyService;
            return this.getSuggestions$(query, accuracy);
          }
        }
        return throwError(() => err);
      })
    );
  }

  getDetails$(id: string | undefined): Observable<AddressOption> {
    return this.currentGeoService.getDetails$(id).pipe(
      catchError((err: HttpErrorResponse) => {
        // If call fails because of api limit try different service
        if (err.status === 401 || err.status === 429) {
          if (!(this.currentGeoService instanceof GeoapifyService)) {
            this.currentGeoService = this.geoapifyService;
            return this.getDetails$(id);
          }
        }
        return throwError(() => err);
      })
    );
  }

  getAddress$(geolocation: GeoLocation, accuracy: GeoSearchAccuracy): Observable<AddressOption[]> {
    return this.currentGeoService.getAddress$(geolocation, accuracy).pipe(
      catchError((err: HttpErrorResponse) => {
        // If call fails because of api limit try different service
        if (err.status === 401 || err.status === 429) {
          if (!(this.currentGeoService instanceof GeoapifyService)) {
            this.currentGeoService = this.geoapifyService;
            return this.getAddress$(geolocation, accuracy);
          }
        }
        return throwError(() => err);
      })
    );
  }


}
