import { Component, EventEmitter, Input, Output, Pipe, ViewChild } from '@angular/core';
import { DisplayEnumObject } from '../../../model/display-enum-object';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-flat-button-multi',
  templateUrl: './flat-button-multi.component.html',
  styleUrls: ['./flat-button-multi.component.scss'],
})
export class FlatButtonMultiComponent {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | null = null;

  @Input() filter_name = 'filter_name';
  @Input() disabled = false;

  @Input() valuesToShow: DisplayEnumObject[] = [];

  // For multi selection
  @Input() selectedValues: DisplayEnumObject[] = [];

  @Input() wordingPipe: Pipe | null = null;
  @Output() changedSelection:EventEmitter<boolean> = new EventEmitter();

  dropDownForm: FormGroup | undefined ;

  // Needs to handle default behavior when a user clicks outside - is handled similar to okClicked
  menuClosedHandled:Boolean = false

  constructor() { }

  okClicked() {
    this.commitValues();
    this.close();
  }

  xClicked() {
    this.selectedValues.length = 0;
    this.close();
  }

  private close() {
    this.menuClosedHandled = true;
    this.menuTrigger?.closeMenu();
  }

  private commitValues() {
    this.valuesToShow.forEach(displayEnum => {
      let formControl = this.dropDownForm?.get(displayEnum.value);
      if (JSON.parse(formControl?.value)) {
        if (!this.selectedValues.includes(displayEnum)) {
          this.selectedValues.push(displayEnum);
        }
      } else {
        const index = this.selectedValues.indexOf(displayEnum, 0);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      }
    });
  }

  menuOpened() {
    const group: any = {};
    this.valuesToShow.forEach(displayEnum => {
      group[displayEnum.value] = new FormControl(this.selectedValues.includes(displayEnum))
    });
    this.dropDownForm = new FormGroup(group);
    this.menuClosedHandled = false
  }

  menuClosed() {
    if(!this.menuClosedHandled) {
      this.commitValues()
    }
    this.changedSelection.emit(true)
  }
}
