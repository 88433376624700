import {Component} from "@angular/core";
import {AbstractControlOptions, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ComparisonErrorStateMatcher} from "../register-form/register-form.component";
import * as FromProfileActions from "../../feature/profile/store/profile.actions";
import {Store} from "@ngrx/store";
import {CoreStateInterface} from "../../core/interface/core-state.interface";
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})

export class ChangePasswordDialogComponent {

  changePasswordForm: FormGroup;
  matcher = new ComparisonErrorStateMatcher();

  constructor(private formBuilder: FormBuilder, private store: Store<CoreStateInterface>, private angularFire: AngularFireAuth) {

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.minLength(6), Validators.required]],
      newPassword: ['', [Validators.minLength(6), Validators.required]],
      confirmNewPassword: ['', [Validators.minLength(6), Validators.required]],
    }, { validators: this.validatePasswords } as AbstractControlOptions);

    this.angularFire.user.subscribe( user => {
      console.log(user);
    })
  }

  validatePasswords(group: FormGroup){
    let pass = group.controls['newPassword'].value;
    let confirmPass = group.controls['confirmNewPassword'].value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit() {
    const oldPassword = this.changePasswordForm.controls['oldPassword'].value;
    const newPassword = this.changePasswordForm.controls['newPassword'].value;
    console.log('was submitted!');
    this.store.dispatch(FromProfileActions.changePasswordStart({oldPassword, newPassword}));
  }
}
