import { Component, EventEmitter, Input, Output, Pipe, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {AbstractControlOptions, FormBuilder, FormGroup} from '@angular/forms';
import {DateHelper} from "../../../helper/dateHelper";
import {formatDate} from "@angular/common";
import {FilterObject} from "../../../model/filter-object";

@Component({
  selector: 'app-flat-button-date',
  templateUrl: './flat-button-date.component.html',
  styleUrls: ['./flat-button-date.component.scss'],
})
export class FlatButtonDateComponent {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | null = null;

  @Input() filter_name = 'filter_name';
  @Input() value_default = 'alle';
  @Input() disabled = false;

  @Input() selectedValue: FilterObject = {value: undefined};

  @Input() tmpSelectedValue: Date| null= null;

  @Input() wordingPipe: Pipe | null = null;

  @Output() changedSelection:EventEmitter<boolean> = new EventEmitter();

  readonly cubicmeter_icon = 'cubicmeter_icon.svg';

  // Needs to handle default behavior when a user clicks outside - is handled similar to okClicked
  menuClosedHandled:Boolean = false

  constructor() {
  }


  okClicked() {
    this.commitValues();
    this.close();
  }

  xClicked() {
    this.selectedValue.value = undefined;
    this.changedSelection.emit(true)
    this.close()
  }

  private close() {
    this.menuClosedHandled = true;
    this.menuTrigger?.closeMenu();
  }

  private commitValues() {
    if (this.tmpSelectedValue) {
      this.selectedValue.value = formatDate(this.tmpSelectedValue,'yyyy-MM-dd', "de")
    }
    else {
      this.selectedValue.value = undefined;
    }
      this.changedSelection.emit(true)
  }

  menuOpened() {
    if (this.selectedValue) {
      this.tmpSelectedValue = new Date(this.selectedValue.value);
    }
    else {
      this.tmpSelectedValue = null;
    }

  }
}
