import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ProjectsStateInterface } from '../interface/projects-state.interface';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import * as FromProjectsActions from './materialSites.actions';
import { ViewMode } from '../ViewMode';
import { MaterialSite } from '../../../model/material-site';
import { MaterialTransaction } from '../../../model/material-transaction';
import { Material } from '../../../model/material';
import { Attachment } from '../../../model/attachment';
import {immerOn} from "ngrx-immer/store";

export const PROJECTS_FEATURE_KEY = 'projects';

export const projectsAdapter: EntityAdapter<MaterialSite> = createEntityAdapter<MaterialSite>();

export const initialState: ProjectsStateInterface = projectsAdapter.getInitialState({
  materialSites: null,
  search: null,
  loading: true,
  autosuggestLoading: false,
  viewMode: ViewMode.LIST,
  materialId: null,
  materialSiteId: null,
  materialTransactionId: null,
  isInTransactionCreateMode: false,
  materialSitesFilter: null,
  autosuggestions: null,
  locationLookupResult: null,
  materialSitesToBookFrom: null,
  addressOptions: null,
});

export const projectsFeature = createFeature({
  name: PROJECTS_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    immerOn(FromProjectsActions.searchStart, (state, { filter, newRequest }) => {
      state.materialSitesFilter = filter ?? null;
      state.loading = newRequest;
      state.materialSiteId = null;
    }),
    immerOn(FromProjectsActions.searchSuccess, (state, {response, newRequest}) => {
      state.materialSites = response;
      state.loading = false;
    }),
    immerOn(FromProjectsActions.searchFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.importProjectListStart, (state, {file}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.importProjectListSuccess, (state) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.changeViewMode, (state, {payload}) => {
      state.viewMode = payload;
    }),

    immerOn(FromProjectsActions.createProjectStart, (state, {project}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.createProjectSuccess, (state, {project}) => {
      state.loading = false;
      if (state.materialSites == null) {
        state.materialSites = [];
      }
      state.materialSites.push(project);
    }),
    immerOn(FromProjectsActions.createProjectFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.setSelectedProject, (state, {project}) => {
      state.materialSiteId = project.id;
      state.loading = false;
    }),

    immerOn(FromProjectsActions.updateProjectStart, (state, {project}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.updateProjectSuccess, (state, {materialSite}) => {
      state.loading = false;
      const index = getMaterialSiteIndex(state.materialSites, materialSite.id);
      if (index !== null && state.materialSites) {
        state.materialSites[index] = materialSite;
      }
    }),
    immerOn(FromProjectsActions.updateProjectFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.deleteProjectStart, (state, {request, newRequest}) => {
      state.search = request;
      state.loading = newRequest;
    }),
    immerOn(FromProjectsActions.deleteProjectSuccess, (state, {newRequest, materialSiteID}) => {
      state.materialSites = state.materialSites?.filter(materialSite => materialSite.id != materialSiteID) ?? [];
      state.loading = false;
    }),
    immerOn(FromProjectsActions.deleteProjectFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.createMaterialSiteAttachmentStart, (state, {files}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.createMaterialSiteAttachmentSuccess, (state, {materialSite}) => {
      state.loading = false;
      if (state.materialSiteId && state.materialSites) {
        const index = getMaterialSiteIndex(state.materialSites, state.materialSiteId);
        if (index != null) {
          state.materialSites[index] = materialSite;
        }
      }
    }),
    immerOn(FromProjectsActions.createMaterialSiteAttachmentFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.deleteMaterialSiteAttachmentStart, (state, {}) => {
      state.loading = true;
      },
    ),
    immerOn(FromProjectsActions.deleteMaterialSiteAttachmentSuccess, (state, {attachmentID}) => {
      state.loading = false;
      let draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        let attachmentIndex = getAttachmentsIndex(draftMaterialSite?.attachments, attachmentID);
        if (attachmentIndex !== null) {
          draftMaterialSite?.attachments?.splice(attachmentIndex, 1);
        }
      }
    }),
    immerOn(FromProjectsActions.deleteMaterialSiteAttachmentFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.createMaterialStart, (state, {}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.createMaterialSuccess, (state, {material}) => {
      state.loading = false;
      state.materialId = material.id;

      let materialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (materialSite) {
        if (materialSite.materials == null) {
          materialSite.materials = [];
        }
        materialSite.materials.push(material);
      }
    }),
    immerOn(FromProjectsActions.createMaterialFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.updateMaterialStart, (state, {}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.updateMaterialSuccess, (state, {material}) => {
      state.loading = false;
      const draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        const materialIndex = getMaterialIndex(draftMaterialSite.materials, material.id);
        if (materialIndex !== null) {
          draftMaterialSite.materials[materialIndex] = material;
        }
      }
    }),
    immerOn(FromProjectsActions.updateMaterialFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.deleteMaterialStart, (state, {}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.deleteMaterialSuccess, (state, {material, materialID}) => {
      state.loading = false;
      let draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        let materialIndex = getMaterialIndex(draftMaterialSite.materials, materialID);
        if (materialIndex !== null) {
          draftMaterialSite.materials.splice(materialIndex, 1);
        }
      }
    }),
    immerOn(FromProjectsActions.deleteMaterialFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.setSelectedMaterial, (state, {material}) => {
      state.materialId = material.id;
    }),

    immerOn(FromProjectsActions.createMaterialAttachmentStart, (state, {files}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.createMaterialAttachmentSuccess, (state, {material}) => {
      state.loading = false;
      let draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        if (state.materialId) {
          let index = getMaterialIndex(draftMaterialSite.materials, state.materialId);
          if (index != null) {
            draftMaterialSite.materials[index] = material;
          }
        }
      }
    }),
    immerOn(FromProjectsActions.createMaterialAttachmentFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.deleteMaterialAttachmentStart, (state, {}) => {
        state.loading = true;
      },
    ),
    immerOn(FromProjectsActions.deleteMaterialAttachmentSuccess, (state, {attachmentID}) => {
      state.loading = false;
      let draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        let draftMaterial = getMaterial(draftMaterialSite.materials, state.materialId);
        if (draftMaterial) {
          let attachmentIndex = getAttachmentsIndex(draftMaterial?.attachments, attachmentID);
          if (attachmentIndex !== null) {
            draftMaterial?.attachments?.splice(attachmentIndex, 1);
          }
        }
      }
    }),
    immerOn(FromProjectsActions.deleteMaterialAttachmentFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.createMaterialTransactionStart, (state, {materialTransaction}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.createMaterialTransactionSuccess, (state, {materialTransaction}) => {
      state.loading = false;
      const draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        const material = getMaterial(draftMaterialSite.materials, state.materialId);
        if (material !== null) {
          if (material.transactions === null) {
            material.transactions = [];
          }
          material.transactions.unshift(materialTransaction);
        }
      }
    }),
    immerOn(FromProjectsActions.createMaterialTransactionFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.updateMaterialTransactionStart, (state, {materialTransaction}) => {
        state.loading = true;
      },
    ),
    immerOn(FromProjectsActions.updateMaterialTransactionSuccess, (state, {materialTransaction}) => {
      state.loading = false;
      const draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        const draftMaterial = getMaterial(draftMaterialSite.materials, state.materialId);
        if (draftMaterial !== null) {
          let materialTransactionsIndex = getMaterialTransactionsIndex(draftMaterial.transactions, materialTransaction.id);
          if (materialTransactionsIndex !== null) {
            draftMaterial.transactions[materialTransactionsIndex] = materialTransaction;
          }
        }
      }
    }),
    immerOn(FromProjectsActions.updateMaterialTransactionFail, (state, {}) => {
      state.loading = false;
    }),
    immerOn(FromProjectsActions.deleteMaterialTransactionStart, (state, {}) => {
      state.loading = true;
      },
    ),
    immerOn(FromProjectsActions.deleteMaterialTransactionSuccess, (state, {materialTransactionID}) => {
      state.loading = false;
      let draftMaterialSite = getMaterialSite(state.materialSites, state.materialSiteId);
      if (draftMaterialSite) {
        let draftMaterial = getMaterial(draftMaterialSite.materials, state.materialId);
        if (draftMaterial) {
          let transactionIndex = getMaterialTransactionsIndex(draftMaterial.transactions, materialTransactionID);
          if (transactionIndex !== null) {
            draftMaterial.transactions.splice(transactionIndex, 1);
          }
        }
      }
    }),
    immerOn(FromProjectsActions.deleteMaterialTransactionFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.setSelectedMaterialTransaction, (state, {materialTransaction}) => {
      state.materialTransactionId = materialTransaction?.id ?? null;
      state.isInTransactionCreateMode = false;
    }),
    immerOn(FromProjectsActions.setNewMaterialTransaction, (state, {isInTransactionCreateMode}) => {
      state.isInTransactionCreateMode = isInTransactionCreateMode;
      state.materialTransactionId = null;
    }),

    immerOn(FromProjectsActions.getMaterialSiteStart, (state, {materialSiteID}) => {
      state.loading = true;
    }),
    immerOn(FromProjectsActions.getMaterialSiteSuccess, (state, {materialSite}) => {
      state.materialSites = [materialSite];
      state.materialSiteId = materialSite.id;
      state.loading = false;
    }),
    immerOn(FromProjectsActions.getMaterialSiteFail, (state, {}) => {
      state.loading = false;
    }),

    immerOn(FromProjectsActions.getAutocompletionStart, (state, {}) => {
      state.autosuggestLoading = true;
    }),
    immerOn(FromProjectsActions.getAutocompletionSuccess, (state, {autosuggestions}) => {
      state.autosuggestLoading = false;
      state.autosuggestions = autosuggestions;
    }),
    immerOn(FromProjectsActions.getAutocompletionFail, (state, {}) => {
      state.autosuggestLoading = false;
    }),
    immerOn(FromProjectsActions.getLookUpStart, (state, {}) => {
      state.autosuggestLoading = true;
    }),
    immerOn(FromProjectsActions.getLookUpSuccess, (state, {autosuggestion}) => {
      state.autosuggestLoading = false;
      state.locationLookupResult = autosuggestion;
    }),
    immerOn(FromProjectsActions.getLookUpFail, (state, {}) => {
      state.autosuggestLoading = false;
    }),
    immerOn(FromProjectsActions.resetAutoCompleteAndLookupResults, (state) => {
      state.locationLookupResult = null;
      state.autosuggestions = null;
    }),
    immerOn(FromProjectsActions.searchMaterialSitesToBookFromStart, (state, { newRequest, request }) => {
      state.loading = newRequest;
    }),
    immerOn(FromProjectsActions.searchMaterialSitesToBookFromSuccess, (state, { newRequest, response }) => {
      if (!newRequest && (state?.materialSitesToBookFrom?.length ?? 0) > 0) {
        state.materialSitesToBookFrom = [...state!.materialSitesToBookFrom!,  ...response];
      } else {
        state.materialSitesToBookFrom = response;
      }
      state.materialSitesToBookFrom = response;
      state.loading = false;
    }),
    immerOn(FromProjectsActions.getAddressFromGeoStart, (state, {geolocation}) => {
    }),
    immerOn(FromProjectsActions.getAddressFromGeoSuccess, (state, {addressOptions}) => {
      state.addressOptions = addressOptions;
    }),
    immerOn(FromProjectsActions.getAddressFromGeoFail, (state, {}) => {
      state.addressOptions = null;
    }),
    immerOn(FromProjectsActions.resetAddressFromGeoStart, (state, {}) => {
      state.addressOptions = null;
    }),
  ),
});

export const {
  name,
  reducer,
  selectLoading,
  selectSearch,
  selectMaterialSites,
  selectViewMode,
  selectIsInTransactionCreateMode,
  selectMaterialId,
  selectMaterialSiteId,
  selectMaterialTransactionId,
  selectMaterialSitesFilter,
  selectAutosuggestions,
  selectAutosuggestLoading,
  selectLocationLookupResult,
  selectMaterialSitesToBookFrom,
  selectAddressOptions
} = projectsFeature;

export const selectMaterialSiteWithId = createSelector(
  selectMaterialSites,
  selectMaterialSiteId,
  (materialSites, selectMaterialSiteId) => {
    if (selectMaterialSiteId === null) {
      return null;
    }
    return materialSites?.find(materialSite => materialSite.id === selectMaterialSiteId) ?? null;
  },
);

export const selectMaterialWithId = createSelector(
  selectMaterialSites,
  selectMaterialSiteId,
  selectMaterialId,
  (materialSites, selectMaterialSiteId, selectedMaterialId) => {
    if (selectMaterialSiteId === null || selectedMaterialId === null) {
      return null;
    }
    return materialSites?.find(materialSite => materialSite.id === selectMaterialSiteId)?.materials?.find(material => material.id === selectedMaterialId) ?? null;
  },
);

export const selectMaterialTransactionWithId = createSelector(
  selectMaterialSites,
  selectMaterialSiteId,
  selectMaterialId,
  selectMaterialTransactionId,
  (materialSites, selectMaterialSiteId, selectedMaterialId, selectMaterialTransactionId) => {
    if (selectMaterialSiteId === null || selectedMaterialId === null || selectMaterialTransactionId) {
      return null;
    }
    return materialSites?.find(materialSite => materialSite.id === selectMaterialSiteId)?.materials?.find(material => material.id === selectedMaterialId)?.transactions?.find(materialTransaction => selectMaterialTransactionId === materialTransaction.id) ?? null;
  },
);


// Utility functions

const getMaterialSiteIndex = (materialSites: MaterialSite[] | null, materialSiteId: string): number | null => {
  return materialSites?.findIndex(materialSite => materialSiteId === materialSite.id) ?? null;
};
const getMaterialIndex = (material: Material[] | null, materialId: string): number | null => {
  return material?.findIndex(material => materialId === material.id) ?? null;
};
const getMaterialTransactionsIndex = (materialTransactions: MaterialTransaction[] | null, materialTransactionId: string | null): number | null => {
  return materialTransactions?.findIndex(materialTransaction => materialTransactionId === materialTransaction.id) ?? null;
};
const getAttachmentsIndex = (attachments: Attachment[] | null | undefined, attachmentId: string | null): number | null => {
  return attachments?.findIndex(attachment => attachmentId === attachment.id) ?? null;
};

const getMaterialSite = (materialSites: MaterialSite[] | null, materialSiteId: String | null): MaterialSite | null => {
  if (materialSiteId === null)
    return null;
  return materialSites?.find(materialSite => materialSite.id === materialSiteId) ?? null;
};

const getMaterial = (materials: Material[] | null, materialId: String | null): Material | null => {
  if (materialId === null)
    return null;
  return materials?.find(material => material.id === materialId) ?? null;
};
