import {NumberFormatter} from "./number-formatter";

export class DensityCalculator {
  // Calculates the mass or the volume from given density
  // V = m/ϱ
  // m = ϱ * V
  // ϱ = m/V

  static calculateVolumeOrMass(selectedValue: string, density: number, givenValue: number): any {
    let calculatedValue = 0;
    if (selectedValue === 'Masse'){
      calculatedValue =  givenValue / density;
    } else if (selectedValue === 'Volumen'){
      calculatedValue = density * givenValue
    }
      return NumberFormatter.FromDoubleToNumeric(Math.round(calculatedValue * 1e2) / 1e2);
  }
}
