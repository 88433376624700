import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { Const } from "../../helper/const";
import * as AuthActions from '../../core/store/core.actions';
import { CoreStateInterface } from '../../core/interface/core-state.interface';
import { selectError, selectLoading, selectTeam } from '../../core/store/core.reducer';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ErrorStateMatcher } from '@angular/material/core';
import { TeamBasicView } from "../../model/team-basic-view";
import { getNames, registerLocale } from 'i18n-iso-countries';
import deLang from 'i18n-iso-countries/langs/de.json';
import {CustomValidator} from "../../helper/customValidator";
import {User} from "../../model/user";

export class ComparisonErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.getError('notSame') && control.parent.dirty);

    return (invalidCtrl || invalidParent) && (control?.touched ?? false);
  }
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnDestroy{
  readonly LOGIN = Const.ROUTES.LOGIN;
  readonly GENERAL_INFORMATION = '../../../assets/icons/general_information.svg';
  readonly ADDRESS_ICON = '../../../assets/icons/address_icon.svg';
  readonly KEY_ICON = '../../../assets/icons/key_icon.svg';

  error$: Observable<string | null> = this.store.select(selectError);
  loginLoading$: Observable<boolean> = this.store.select(selectLoading);
  team$: Observable<TeamBasicView | null> = this.store.select(selectTeam);

  registerForm: FormGroup;
  matcher = new ComparisonErrorStateMatcher();
  countries: any;

  constructor(private store: Store<CoreStateInterface>, private formBuilder: FormBuilder) {
    this.registerForm = this.formBuilder.group({
      companyname: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneMobile: '',
      phoneOffice: '',
      street: ['', Validators.required],
      plz: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), CustomValidator.numeric]],
      country: ['DE', Validators.required],
      housenumber: ['', Validators.required],
      city: ['', Validators.required],
      email: ['',[Validators.email, Validators.required]],

      password: ['', [Validators.minLength(6), Validators.required]],
      confirmPassword: ['', [Validators.minLength(6), Validators.required]]
    }, { validators: this.validatePasswords } as AbstractControlOptions);
  }

  onSubmit() {
    if (!this.registerForm.valid) {
      return;
    }
    const firstName = this.registerForm.controls['firstName'].value;
    const lastName = this.registerForm.controls['lastName'].value;
    const email = this.registerForm.controls['email'].value;
    const password = this.registerForm.controls['password'].value;
    const phoneMobile = this.registerForm.controls['phoneMobile'].value;
    const phoneOffice = this.registerForm.controls['phoneOffice'].value;

    const name = this.registerForm.controls['companyname'].value;
    const street = this.registerForm.controls['street'].value + ' ' + this.registerForm.controls['housenumber'].value;
    const zip = this.registerForm.controls['plz'].value;
    const country = this.registerForm.controls['country'].value;
    const city = this.registerForm.controls['city'].value;

    const team: Partial<TeamBasicView> = {
      name,
      address: {
        street,
        zip,
        city,
        country
      }
    };

    const user: Partial<User> = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      phoneMobile: phoneMobile,
      phoneOffice: phoneOffice,
    }

    const REDIRECT_ROUTE = Const.ROUTES.MATERIAL_FLOW.BASE;

    this.store.dispatch(AuthActions.registerNewFirebaseUserStart({user, password, team, route: REDIRECT_ROUTE}));
  }

  validatePasswords(group: FormGroup){
    let pass = group.controls['password'].value;
    let confirmPass = group.controls['confirmPassword'].value;

    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit() {
    registerLocale(deLang);
    this.countries = getNames('de');
  }

  ngOnDestroy() {
    this.store.dispatch(AuthActions.clearError());
  }
}
