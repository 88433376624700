import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import * as AuthActions from './core.actions';
import {catchError, concatMap, of, tap} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../service/auth.service';
import {UserService} from '../../service/user.service';
import {TeamService} from '../../service/team.service';
import {map} from 'rxjs/operators';
import {Const} from '../../helper/const';
import firebase from 'firebase/compat/app';
import {Store} from '@ngrx/store';
import {LocalStorageService} from "../../service/local-storage.service";
import {selectUser} from "./core.reducer";
import {searchFail} from "../../feature/material-sites/store/materialSites.actions";
import {User} from "../../model/user";
import {MatSnackBar} from "@angular/material/snack-bar";

const handleError = (errorRes: firebase.FirebaseError, type?: string) => {
  // TODO: add httpErrorResponse to type declaration and check if errorRes is of type httpErrorResponse (it is the case if requests to backend server fail)
  // TODO: improve "type" implementation

  let errorMessage = 'Ein unbekannter Fehler ist aufgetreten!';
  if (type === 'user') {
    return of(AuthActions.registerNewUserFail({error: errorMessage}));
  }
  if (type === 'team') {
    return of(AuthActions.createTeamFail());
  }
  if (type === 'reset') {
    return of(AuthActions.forgottenPasswordFail({resetError: errorMessage}));
  }

  if (!errorRes) {
    return of(AuthActions.authenticateFail({payload: errorMessage}));
  }
  switch (errorRes.code) {
    case 'auth/email-already-exists':
    case 'auth/email-already-in-use':
      errorMessage = 'Diese E-Mail-Adresse existiert bereits!';
      break;
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/invalid-password':
      errorMessage =
        'Das Passwort ist ungültig oder für diesen Benutzer wurde aktuell noch kein Benutzerkonto angelegt!';
      break;
    case 'auth/invalid-email':
      errorMessage = 'Die verwendete E-Mail-Adresse ist ungültig oder unvollständig!';
  }

  if (type === 'register') {
    return of(AuthActions.registerNewFirebaseUserFail({error: errorMessage}));
  }
  if (type === 'logout') {
    return of(AuthActions.logoutFail({payload: errorMessage}));
  }
  return of(AuthActions.authenticateFail({payload: errorMessage}));
};

@Injectable()
export class CoreEffects {
  authLogin = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateStart),
      concatMap(({email, password, route}) => {
        return this.authService.login$(email, password).pipe(
          map(() => {
            return AuthActions.getOwnUser({route});
          }),
          catchError((error: firebase.FirebaseError) => {
            return handleError(error);
          })
        );
      })
    );
  });

  authLogout = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutStart),
      concatMap(() => {
        return this.authService.logout().pipe(
          map(() => {
            return AuthActions.logoutSuccess();
          }),
          tap(() => {
            this.router.navigate(['/' + Const.ROUTES.LOGIN]);
          }),
          catchError((error: firebase.FirebaseError) => {
            return handleError(error, 'logout');
          })
        );
      })
    );
  });

  getOwnUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getOwnUser),
      concatMap(({route}) => {
        return this.userService.getOwnUser().pipe(
          map(user => {
            if (user.teams?.length === 0) {
              route = Const.ROUTES.PROFILE.BASE;
            }
            return AuthActions.authenticateSuccess({user});
          }),
          tap(() => {
            if (route) {
              this.router.navigate([route]);
            }
          }),
          catchError((error: firebase.FirebaseError) => {
            this.store.dispatch(AuthActions.logoutStart());
            return handleError(error);
          })
        );
      })
    );
  });

  firebaseCreateUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerNewFirebaseUserStart),
      concatMap(({user, password, team, route}) => {
        return this.authService.createNewFirebaseUser(user.email!, password).pipe(
          map(() => {
            return AuthActions.registerNewFirebaseUserSuccess({user, password, team, route});
          }),
          catchError((error: firebase.FirebaseError) => {
            return handleError(error, 'register');
          })
        );
      })
    );
  });

  createUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerNewFirebaseUserSuccess),
      concatMap(({user, team, route}) => {
        return this.userService.createOwnUser(user.email!, user.firstname!, user.lastname!).pipe(
          map((user) => {
            return AuthActions.registerNewUserSuccess({user, team});
          }),
          tap(() => {
            if (route) {
              this.router.navigate([route]);
            }
          }),
          catchError((error: firebase.FirebaseError) => {
            return handleError(error, 'user');
          })
        );
      })
    );
  });

  createTeamForUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerNewUserSuccess),
      concatMap(({user, team}) => {
        return of(AuthActions.createTeamStart({team}));
      })
    );
  });

  createTeam = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.createTeamStart),
      concatMap(({team}) => {
        return this.teamService.createTeam$(team).pipe(
          map((createdTeam) => {
            return AuthActions.createTeamSuccess({team: createdTeam});
          }),
          catchError(err => {
            return of(AuthActions.createTeamFail());
          })
        );
      })
    );
  });

  updateUserRecentTeam = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.createTeamSuccess),
      concatLatestFrom(() => this.store.select(selectUser)),
      concatMap(([{team}, user]) => {
        if (!user) {
          return of(searchFail());
        }
        const updatedUser: User = {
          ...user,
          recentTeamId: team.id,
        }
        return of(AuthActions.updateUserStart({user: updatedUser}));
      })
    );
  });

  updateUser = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateUserStart),
      concatMap(({user}) => {
        return this.userService.updateUser(user).pipe(
          map(updatedUser => {
            return AuthActions.updateUserSuccess({
              user: updatedUser,
            });
          }),
          catchError(err => {
            console.log(err);
            return of(AuthActions.updateUserFail());
          })
        );
      })
    );
  });


  passwordReset = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgottenPasswordStart),
      concatMap(({email}) => {
        return this.userService.resetPassword(email).pipe(
          map(() => {
            return AuthActions.forgottenPasswordSuccess({email});
          }),
          catchError((resetError: firebase.FirebaseError) => {
            return handleError(resetError, 'reset');
          })
        );
      })
    );
  });

  setNewPasswordAfterReset = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPasswordStart),
      concatMap(({code, newPassword}) => {
        return this.authService.verifyAndConfirmPassword$(code, newPassword).pipe(
          map(()=> {
            const message = 'Password erfolgreich geändert! Sie können sich jetzt einloggen.';
            this.showSnackbar(message, 'base');
            return AuthActions.resetPasswordSuccess();
          }),
          tap(() => {
            this.router.navigate(['/' + Const.ROUTES.LOGIN]);
          }),
          catchError(err => {
            const message = 'Fehler beim Ändern des Passworts! Bitte versuchen Sie es später erneut.';
            this.showSnackbar(message, 'warn', err);
            return of(AuthActions.resetPasswordFail());
          })
        );

      })
    )
  });


  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private snackbar: MatSnackBar,
  ) {}

  private showSnackbar(message: string, type: string, error?: string) {
    if (error) {
      console.error(error);
    }
    if (type === 'warn') {
      this.snackbar.open(message, 'Schließen', { panelClass: [Const.SNACK.BASE, Const.SNACK.WARN] });
    } else if (type === 'base') {
      this.snackbar.open(message, undefined, { panelClass: [Const.SNACK.BASE], duration: 3000 });
    } else if (type === 'address-warn') {
      this.snackbar.open(message, undefined, { panelClass: [Const.SNACK.BASE, Const.SNACK.WARN], duration: 3000 });
    }
  }

}
