<div class="bg-gray padding-16 borders">
  <form [formGroup]="filterForm">
    <div class="filter-container align-items-center">
      <div class="d-flex align-items-center filter-icon-container">
        <div class="icon-container d-flex justify-content-center align-items-center">
          <img [src]="FILTER_ICON">
        </div>
        <span >Filter: </span>
      </div>
      <app-filter-button [value]="filterForm.controls['material'].value?.name" [isActive]="filterForm.controls['material'].value" filter_name="Material">
        <mat-radio-group class="d-flex flex-column" formControlName="material">
          <ng-container *ngFor="let material of MATERIAL_TYPES">
            <mat-radio-button [value]="material"> {{ material.name }} </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </app-filter-button>
      <ng-container *ngIf="!isStaticSource; else staticSource">
        <app-filter-button [value]="filterForm.controls['projectType'].value?.name" [isActive]="filterForm.controls['projectType'].value" filter_name="Bauvorhaben">
          <mat-radio-group class="d-flex flex-column" formControlName="projectType">
            <ng-container *ngFor="let projectType of PROJECT_TYPES">
              <mat-radio-button [value]="projectType"> {{ projectType.name }} </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </app-filter-button>
      </ng-container>
      <ng-template #staticSource>
        <app-filter-button [value]="filterForm.controls['staticSourceType'].value?.name" [isActive]="filterForm.controls['staticSourceType'].value" filter_name="Quellen/Senken">
          <mat-radio-group class="d-flex flex-column" formControlName="staticSourceType">
            <ng-container *ngFor="let staticSourceType of STATIC_SOURCE_TYPES">
              <mat-radio-button [value]="staticSourceType"> {{ staticSourceType.name }} </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </app-filter-button>
      </ng-template>
      <app-filter-button [value]="filterForm.controls['flowDirection'].value?.name" [isActive]="filterForm.controls['flowDirection'].value" filter_name="Stoffstrom">
        <mat-radio-group class="d-flex flex-column" formControlName="flowDirection">
          <ng-container *ngFor="let materialFlowDirection of MATERIAL_FLOW_DIRECTION">
            <mat-radio-button [value]="materialFlowDirection"> {{ materialFlowDirection.name }} </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </app-filter-button>
      <button class="reset-btn" mat-button *ngIf='filterForm?.dirty' (click)="clearFilter()">Zurücksetzen</button>
    </div>
  </form>
  <hr>
</div>

