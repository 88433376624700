import { Component, EventEmitter, Input, Output, Pipe, ViewChild } from '@angular/core';
import { DisplayEnumObject } from '../../../model/display-enum-object';
import { MatMenuTrigger } from '@angular/material/menu';
import { FilterObject } from '../../../model/filter-object';

@Component({
  selector: 'app-flat-button-single',
  templateUrl: './flat-button-single.component.html',
  styleUrls: ['./flat-button-single.component.scss'],
})
export class FlatButtonSingleComponent {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | null = null;

  @Input() filter_name = 'filter_name';
  @Input() disabled = false;

  @Input() valuesToShow: DisplayEnumObject[] = [];
  @Input() selectedValue: FilterObject = { value: null }

  tmpSelectedValue: FilterObject = { value: null };

  @Input() wordingPipe: Pipe | null = null;
  @Output() changedSelection: EventEmitter<boolean> = new EventEmitter();

  // Needs to handle default behavior when a user clicks outside - is handled similar to okClicked
  menuClosedHandled:Boolean = false

  constructor() { }

  private commitValues() {
    this.selectedValue.value = this.tmpSelectedValue.value
  }

  menuOpened() {
    this.tmpSelectedValue.value =  this.selectedValue.value
  }

  okClicked() {
    this.commitValues();
    this.close();
  }

  xClicked() {
    this.selectedValue.value = null
    this.tmpSelectedValue.value = null
    this.close()
  }

  private close(){
    this.menuClosedHandled = true;
    this.menuTrigger?.closeMenu();
  }

  menuClosed() {
    if(!this.menuClosedHandled) {
      this.commitValues()
    }
    this.changedSelection.emit(true)
  }
}
