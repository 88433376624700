<form class='w-100'>
  <div class='row d-flex justify-content-between justify-content-center dialog-title' mat-dialog-title>
    <div class='col-11'>
      <h2>Buchung zum Projekt zuordnen</h2>
    </div>
    <div class='col-1'>
      <button mat-icon-button class='close-button' mat-dialog-close='true'>
        <img src='../assets/icons/close_Icon.svg'>
      </button>
    </div>
  </div>
  <div class='container w-100'>
    <mat-dialog-content class='mat-typography'>
      <app-here-map
        [loading]='(isLoading$ | async) === true'
        [materialSites]='(materialSitesToBookFrom$ | async) ?? []'
        [height]="'600px'"
        [initialMaterialSiteSearchRequest]="materialSiteSearchRequest"
        [isInMaterialFlowMode]="false"
        (selectedMaterialSiteEvent)='newSelection($event)'
        (emitSearch)="materialSiteSearchRequestChanged($event)"
      ></app-here-map>

    </mat-dialog-content>
    <hr>
    <mat-dialog-actions align='start'>
      <div class='col-md-6 col-12 button-col'>
        <button mat-raised-button class='w-100 h-56 mb-2' color='primary' (click)='materialSiteSelected()'
                [disabled]='newMaterialSite === null'>Projekt auswählen
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
