export const ENUM_CONSTS = {
  BODENART: 'BODENART',
  DEPONIEKLASSEN: 'DEPONIEKLASSEN',
  MATERIAL_TYPE: 'MATERIAL_TYPE',
  MATERIAL_SITE_TYPE: 'MATERIAL_SITE_TYPE',
  PROJECT_PHASE: 'PROJECT_PHASE',
  PROJECT_TYPE: 'PROJECT_TYPE',
  STATIC_SOURCE_TYPE: 'STATIC_SOURCE_TYPE',
  MATERIAL_FLOW_DIRECTION: 'MATERIAL_FLOW_DIRECTION',
  MATERIAL_FLOW_DIRECTION_USER_NEED: 'MATERIAL_FLOW_DIRECTION_USER_NEED',
  ZUORDNUNGSKLASSE: 'ZUORDNUNGSKLASSE',
  ERSATZBAUSTOFFE: 'ERSATZBAUSTOFFE',
  TEAM_USER_ROLE: 'TEAM_USER_ROLE',
  RADIUS: 'RADIUS',
} as const;

export type EnumIdentifier = keyof typeof ENUM_CONSTS;
