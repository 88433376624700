import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {environment} from "../../../environments/environment";
import H from "@here/maps-api-for-javascript";
import onResize from "simple-element-resize-detector";
import {GeoLocation} from "../../model/geo-location";
import {Store} from "@ngrx/store";
import {resetAddressFromGeoStart} from "../../feature/material-sites/store/materialSites.actions";

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.scss']
})
export class StaticMapComponent implements AfterViewInit, OnChanges, OnDestroy {

  private readonly ICON = new H.map.Icon('assets/icons/pin.svg');

  @ViewChild("map", {static: true})
  public mapElement: ElementRef | undefined;

  @Input() width = '100%';
  @Input() height = '500px';
  @Input() public lat: number | undefined;
  @Input() public lng: number | undefined;
  @Input() public zoom: number | undefined;
  @Input() isMapSelector: boolean | undefined = true;

  @Output() geoCoordinates: EventEmitter<GeoLocation> = new EventEmitter;

  private _apikey = environment.hereAppKey;

  private ui: any;
  private platform: any;
  private layers: any;
  private map!: H.Map;
  private marker: any = undefined;
  private behavior: H.mapevents.Behavior | undefined;

  readonly DEFAULT_LOCATION = {
    lat: 51.158627,
    lng: 10.445921,
  };
  readonly DEFAULT_ZOOM = 6;

  constructor(private store: Store) {
  }

  ngAfterViewInit(): void {
    this.createNewMap();
    this.clickAndDragMarker();

    if (this.lng && this.lat) {
      this.setNewCenterAndMarker();
      this.map.setZoom(this.zoom ?? this.DEFAULT_ZOOM);
      this.marker.draggable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map && !this.isMapSelector) {
      if (changes['lat'] || changes['lng']) {
        this.setNewCenterAndMarker();
      }
    }
  }

  private setNewCenterAndMarker() {
    this.removeObjects();
    this.setMapCenter();
    this.setCenterMarker();
  }

  private setNewMarker() {
    this.removeObjects();
    this.setCenterMarker();
  }

  private setMapCenter(): void {
    if (this.lat && this.lng) {
      this.map.setCenter({lat: this.lat, lng: this.lng});
    }
  }

  private setCenterMarker(): void {
    if (this.lat && this.lng) {
      this.marker = new H.map.Marker({lat: this.lat, lng: this.lng}, {
        volatility: true,
        "icon": this.ICON,
        data: undefined
      });
      this.marker.draggable = true;
      this.map.addObject(this.marker);
    } else {
      this.marker = undefined;
    }
  }

  private removeObjects(): void {
    if (this.marker) {
      // close and remove prior search Objects/Marker
      this.map.removeObject(this.marker);
      this.marker = undefined;
    }
  }

  createNewMap(): void {
    if (!this.map && this.mapElement) {

      // Instantiate a platform, default layers and a map
      this.platform = new H.service.Platform({
        apikey: this._apikey
      });

      this.layers = this.platform.createDefaultLayers();

      // Initialize a map - this map is centered over the defined latitude and longitude and zoomed in with a certain value
      this.map = new H.Map(
        this.mapElement.nativeElement,
        this.layers.vector.normal.map,
        {
          pixelRatio: window.devicePixelRatio,
          center: {lat: this.lat ?? this.DEFAULT_LOCATION.lat, lng: this.lng ?? this.DEFAULT_LOCATION.lng},
          zoom: this.zoom ?? this.DEFAULT_ZOOM,
        },
      );

      // Make the map interactive - Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      this.behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

      // Create the default UI components
      this.ui = H.ui.UI.createDefault(this.map, this.layers, 'de-DE');
      const mapSettings = this.ui.getControl('mapsettings');
      const zoom = this.ui.getControl('zoom');
      const scalebar = this.ui.getControl('scalebar');

      mapSettings.setAlignment('top-right');
      scalebar.setAlignment('top-right');
      zoom.setAlignment('right-top');

      // Add a resize listener to make sure that the map spans over the whole container
      onResize(this.mapElement.nativeElement, () => {
        this.map.getViewPort().resize();
      });

      // Add EventListener to show cursor as pointer when pointing on a marker
      this.map.addEventListener('pointermove', (event: { target: any; }) => {
        if (!this.isMapSelector) {
          return
        }
        if (event.target instanceof H.map.Marker) {
          this.map.getViewPort().element.style.cursor = 'pointer';
        } else {
          this.map.getViewPort().element.style.cursor = 'auto';
        }
      }, false);

    }
  }

  clickAndDragMarker() {
    this.map.addEventListener('tap', (event: { target: H.map.Marker, currentPointer: any }) => {
      if (!this.isMapSelector) {
        return
      }
      let coordinates = this.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
      this.lat = coordinates?.lat;
      this.lng = coordinates?.lng;
      this.geoCoordinates.emit({latitude: this.lat, longitude: this.lng});
      this.setNewMarker();
    });

    this.map.addEventListener('dragstart', (event: { target: H.map.Marker, currentPointer: any }) => {
      if (!this.isMapSelector) {
        return
      }
      let target = event.target;
      let pointer = event.currentPointer;
      if (target instanceof H.map.Marker) {
          let targetPosition = this.map.geoToScreen(target.getGeometry() as H.geo.Point);
        if (targetPosition) {
          // @ts-ignore
          target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        }
        this.behavior?.disable();
      }
    }, false);

    this.map.addEventListener('dragend', (event: { target: H.map.Marker, currentPointer: any }) => {
      if (!this.isMapSelector) {
        return
      }
      let target = event.target;
      let pointer = event.currentPointer;
      if (target instanceof H.map.Marker) {
        let coordinates = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
        this.lat = coordinates?.lat;
        this.lng = coordinates?.lng;
        this.geoCoordinates.emit({latitude: this.lat, longitude: this.lng});
        this.behavior?.enable();
      }
    }, false);

    this.map.addEventListener('drag', (event: { target: H.map.Marker, currentPointer: any }) => {
      if (!this.isMapSelector) {
        return
      }
      let target = event.target;
      let pointer = event.currentPointer;
      if (target instanceof H.map.Marker) {
        // @ts-ignore
        let coordinates = this.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y)
        if (coordinates) {
          target.setGeometry(coordinates);
        }
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(resetAddressFromGeoStart());
  }

}
