<div [class.bg-gray]="!isBookingState" [class.borders]="!isBookingState" *ngLet="materialSitesLoading as isLoading">
  <div *ngIf="dataSource?.data" class="container flex-column">
    <ng-container *ngIf="isLoading">
      <div class="d-flex loading-container align-items-center justify-content-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-container>
    <table class="p-2" [class.loading]="isLoading" matSort mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Projektname</div></th>
        <td mat-cell *matCellDef="let project">{{project.name}}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Projekttyp</div></th>
        <td mat-cell *matCellDef="let project">{{enumHelper?.getDisplayEnumObject(project.type, MATERIAL_SITE_TYPE)?.name}}</td>
      </ng-container>
      <ng-container matColumnDef="projectType">
        <th mat-sort-header mat-header-cell *matHeaderCellDef><div class="col-head-label">Bauvorhaben</div></th>
        <td mat-cell *matCellDef="let project">{{enumHelper?.getDisplayEnumObject(project.projectType, PROJECT_TYPE)?.name}}</td>
      </ng-container>

        <ng-container matColumnDef="staticSourceTypes">
          <th mat-sort-header mat-header-cell *matHeaderCellDef><div class="col-head-label">Quellen/Senken</div></th>
          <td mat-cell *matCellDef="let project">
            <ng-container *ngFor="let staticSourceType of project.staticSourceTypes">
              {{staticSourceTypeMap?.get("" + staticSourceType)}}<br>
            </ng-container>
          </td>
        </ng-container>

      <ng-container matColumnDef="internalId">
        <th mat-sort-header mat-header-cell *matHeaderCellDef><div class="col-head-label">Projekt. Nr.</div></th>
        <td mat-cell *matCellDef="let project">{{project.internalId}}</td>
      </ng-container>
      <ng-container matColumnDef="location" >
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label">Standort</div></th>
        <td mat-cell *matCellDef="let project">{{project.address.street}},  {{project.address.zip}} {{project.address.city}}</td>
      </ng-container>
      <ng-container matColumnDef="material-number" >
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label">Materialien</div></th>
        <td mat-cell *matCellDef="let project">{{project.materials?.length}}</td>
      </ng-container>
      <ng-container matColumnDef="time-period">
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label">Zeitraum</div></th>
        <td class="px-2" mat-cell *matCellDef="let project">{{project.projectStart | date:'dd.MM.yyyy'}} -{{project.projectEnd | date:'dd.MM.yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="detail-view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let project"><img class='msDetailIcon' [src]="detail_view_icon" [routerLink]= "[selectedRouterLink + '/' + ROUTES.PROJECTS.DETAIL + '/' + project.id]" (click)="setSelectedProject(project)"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
      <ng-container *ngIf="!isBookingState; else inBookingState">
        <tr mat-row class="cursor-hand" *matRowDef="let row; columns: displayedColumns;" [routerLink]="[selectedRouterLink + '/' + ROUTES.PROJECTS.DETAIL + '/' + row.id]" (click)="setSelectedProject(row)"></tr>
      </ng-container>
      <ng-template #inBookingState>
        <tr mat-row class="cursor-hand" *matRowDef="let row; columns: displayedColumns;" (click)="setSelectedProject(row)" [class.selected-row]="selectedMaterialSiteId === row.id"></tr>
      </ng-template>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <p class="text-center no-results">
            Es konnten keine Ergebnisse für die aktuelle Suche gefunden werden.
          </p>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20, 50, 100]"
                   [pageSize]="20"
                   [class.loading]="isLoading"
                   showFirstLastButtons
                   class="paginator"
                   aria-label="Seite von Projekten">
    </mat-paginator>
  </div>
</div>
