<div class='map-container'>
    <div class='search-bar-container position-absolute d-flex' *ngIf='showSearchBox'>
        <form class='h-100  flex-fill d-flex' [formGroup]='searchForm'>
            <div class='d-flex h-100 align-items-center flex-fill search-side '>
                <app-map-search [placeholder]="'Suche'" (clearButtonClicked)='resetSearchMarker()' class='w-100'
                                [inputPrefixIcon]="'search'" [searchResults]='autocompleteResults'
                                [loadingState]='autocompleteLoading' formControlName='address'></app-map-search>
            </div>
            <div class='h-100 radius-side d-flex align-items-center ps-1 cursor-pointer'
                 [matMenuTriggerFor]='activeSearch ? menu : null' #menuTrigger='matMenuTrigger'>
                <app-icon [class.selectable]='activeSearch' name='pin-radius'></app-icon>
                <ng-container *ngIf='selectedRadiusValue !== 0; else noSelected'>
                    <div [class.selectable]='activeSearch'>{{selectedRadiusValue}} km</div>
                </ng-container>
                <ng-template #noSelected>
                    <div [class.selectable]='activeSearch'>Radius anzeigen</div>
                </ng-template>
                <app-icon [class.selectable]='activeSearch' name='arrow_down'></app-icon>
            </div>
        </form>
        <mat-menu #menu='matMenu'>
            <div class='flat-filter-button-menu m-2'>
                <mat-radio-group [(ngModel)]='selectedRadiusValue'>
                    <ng-container *ngFor='let value of valuesToShow'>
                        <div>
                            <mat-radio-button [value]=value>
                                <ng-container *ngIf='value !== 0; else nothing'>
                                    {{ value }} km Radius anzeigen
                                </ng-container>
                                <ng-template #nothing>
                                    kein Radius anzeigen
                                </ng-template>
                            </mat-radio-button>
                        </div>
                    </ng-container>
                </mat-radio-group>
            </div>
        </mat-menu>

    </div>
    <div class='noMatch position-absolute position-absolute top-50 start-50 translate-middle'
         *ngIf='!loading && (materialSites?.length ?? 0) === 0'>
        <div class=' justify-content-center align-items-center ' role='button' (click)='resetFilter()'>
            Deine Suche ergab keinen Treffer. <u>Filter zurücksetzen</u>
        </div>
    </div>
    <ng-container *ngIf='!isGanttChartMode'>
        <app-filter-sheet class='filter-sheet position-absolute'
                          (emitSearch)='emitSearch.emit($event)'
                          [materialSiteSearchRequest]='initialMaterialSiteSearchRequest'
                          [isInMaterialFlowMode]='isInMaterialFlowMode'
                          (resetSearch)='this.resetSearchEvent.emit()'
        ></app-filter-sheet>
    </ng-container>
    <ng-container *ngIf='isInMaterialFlowMode'>
        <ng-container *ngIf='selectedMaterialSite !== null'>
            <app-material-sites-detail-box [selectedMaterialSite]='selectedMaterialSite'
                                           (ganttToggle)='setGanttToggleState($event)'
                                           (closedEmitter)='closeDetailBox()'></app-material-sites-detail-box>
        </ng-container>
    </ng-container>

    <app-default-map
            [width]='width'
            [height]='height'
            [activeSearch]='activeSearch'
            [materialSites]='materialSites'
            [selectedMaterialSite]='selectedMaterialSite'
            [radiusValue]='selectedRadiusValue'
            (emitSelectedMaterialSiteEvent)='this.selectedMaterialSiteEvent.emit($event)'></app-default-map>

    <div class='loader' *ngIf='loading'>
        <app-loading-spinner></app-loading-spinner>
    </div>
    <div class='searchResult' *ngIf='this.materialSites'>Es wurden {{this.materialSites.length}} Ergebnisse gefunden
    </div>
</div>

