import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {
  @Input() infoText = '';
  @Input() show = true;
  @Output() showInfoEvent = new EventEmitter<boolean>();

  readonly INFO_ICON = '/assets/icons/alert-circle.svg';
  readonly CLOSE_INFO_ICON = '/assets/icons/close_info_icon.svg';

  constructor() { }

  ngOnInit(): void {
  }

  closeInfo() {
    this.showInfoEvent.emit(false);
  }
}
