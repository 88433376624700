import { DisplayEnumObject } from '../model/display-enum-object';
import { AddressOption } from '../interface/address-option';
import { MaterialSite } from '../model/material-site';

export class Utils {
  static convertStrEnumToArray(enumToConvert: any): DisplayEnumObject[] {
    const entries = Object.entries(enumToConvert);
    const arrayFromEnum: DisplayEnumObject[] = [];
    entries.forEach(entry => {
      const obj = {
        name: entry[1] as string,
        value: entry[0] as string,
      };
      arrayFromEnum.push(obj);
    });
    return arrayFromEnum;
  }

  static convertRadiusStrEnumToArray(enumToConvert: any): DisplayEnumObject[] {
    const entries = Object.entries(enumToConvert);
    const arrayFromEnum: DisplayEnumObject[] = [];
    entries.forEach(entry => {
      const obj = {
        name: entry[0] as string,
        value: entry[1] as string,
      };
      arrayFromEnum.push(obj);
    });
    return arrayFromEnum;
  }

  // Type Guards
  static isMaterialSite<T>(result: T | MaterialSite): result is MaterialSite {
    return (
      (result as MaterialSite)?.name !== undefined &&
      (result as MaterialSite)?.id !== undefined &&
      (result as MaterialSite)?.type !== undefined
    );
  }

  static isAddressOption<T>(result: T | AddressOption): result is AddressOption {
    return (
      (result as AddressOption)?.label !== undefined &&
      (result as AddressOption)?.lat !== undefined &&
      (result as AddressOption)?.lon !== undefined
    );
  }

  static getMaterialColor(checkValue: string): string {
    var colorValue = '';
    switch (checkValue) {
      case 'Ton':
        colorValue = '#D8BC9B';
        break;
      case 'Lehm':
        colorValue = '#DBB090';
        break;
      case 'Ersatzbaustoff':
        colorValue = '#E8CAE3';
        break;
      case 'Sand':
        colorValue = '#EBCF87';
        break;
      case 'Kies':
        colorValue = '#D6D4D3';
        break;
      case 'Boden':
        colorValue = '#D8D1BB';
        break;
      case 'Schotter':
        colorValue = '#B5B5B5';
        break;
      default:
        colorValue = 'transparent';
        break;
    }
    return colorValue;
  }
}
