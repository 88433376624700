<div class="container-fluid mt-5">
  <footer class="py-1">
      <div class='container'>
        <div class="d-flex col-12 col-md-12  p-3">
          <h4 class='p-2'><u>Impressum</u></h4>
          <h4 class='p-2'><u>Datenschutz</u></h4>
          <h4 class='ms-auto p-2'>© ITsquare 2023</h4>
        </div>
      </div>
  </footer>
</div>
