import { MaterialSite } from '../../../model/material-site';
import H from '@here/maps-api-for-javascript';
import { StaticSourceType } from '../../../enum/static-source-type';
import { Material } from '../../../model/material';
import { ENUM_CONSTS } from '../../../types/enum-identifier';
import { Utils } from '../../../helper/utils';
import { EnumHelper } from '../../../model/enum-helper';
import { DecimalPipe } from '@angular/common';
import IPoint = H.geo.IPoint;
import MultiPoint = H.geo.MultiPoint;

const deponieIcon = new H.map.Icon('assets/icons/pin_deponie.svg');
const sandIcon = new H.map.Icon('assets/icons/pin_sandgrube.svg');
const steinIcon = new H.map.Icon('assets/icons/pin_steinbruch.svg');
const kiesIcon = new H.map.Icon('assets/icons/pin_kiesgrube.svg');
const tonIcon = new H.map.Icon('assets/icons/pin_tongrube.svg');
const aufIcon = new H.map.Icon('assets/icons/pin_aufbereitungsanlage.svg');
const projectIcon = new H.map.Icon('assets/icons/pin_internal_project.svg');

const deponieIconSelect = new H.map.Icon('assets/icons/pin-deponie-selected.svg');
const sandIconSelect = new H.map.Icon('assets/icons/pin_sand_select.svg');
const steinIconSelect = new H.map.Icon('assets/icons/pin-stein-selected.svg');
const kiesIconSelect = new H.map.Icon('assets/icons/pin-kies-selected.svg');
const tonIconSelect = new H.map.Icon('assets/icons/pin-ton-selected.svg');
const aufIconSelect = new H.map.Icon('assets/icons/pin-auf-selected.svg');
const projectIconSelect = new H.map.Icon('assets/icons/pin_project_select.svg');

const SEARCH_PLACEHOLDER_IMG = new H.map.Icon('assets/icons/pin_my_search.svg');
const ICON_MARKER_SELECTED = new H.map.Icon('assets/icons/poi_selected.svg');

export class MapUtils {
  static getMaterialSiteSelectIcon(materialSite:MaterialSite): H.map.Icon {
    // @ts-ignore
    if (materialSite.type === "staticSource") {
      if (materialSite.staticSourceTypes) {
        switch (materialSite.staticSourceTypes![0]) {
          case StaticSourceType.Deponie:
            return deponieIconSelect;
          case StaticSourceType.Sandgrube:
            return sandIconSelect;
          case StaticSourceType.Steinbruch:
            return steinIconSelect;
          case StaticSourceType.Kiesgrube:
            return kiesIconSelect;
          case StaticSourceType.Tongrube:
            return tonIconSelect;
          case StaticSourceType.Aufbereitungsanlage:
            return aufIconSelect;
        }
      }
    }
    return projectIconSelect;
  }

  static getMaterialSiteIcon(materialSite:MaterialSite): H.map.Icon {
    // @ts-ignore
    if (materialSite.type === "staticSource") {
      if (materialSite.staticSourceTypes) {
        switch (materialSite.staticSourceTypes![0]) {
          case StaticSourceType.Deponie:
            return deponieIcon;
          case StaticSourceType.Sandgrube:
            return sandIcon;
          case StaticSourceType.Steinbruch:
            return steinIcon;
          case StaticSourceType.Kiesgrube:
            return kiesIcon;
          case StaticSourceType.Tongrube:
            return tonIcon;
          case StaticSourceType.Aufbereitungsanlage:
            return aufIcon;
        }
      }
    }
    return projectIcon;
  }

  static createBubbleHTML(hoverMaterialSite: MaterialSite, enumHelper?: EnumHelper, decimalPipe?: DecimalPipe): string {
    let myHTML =
      '<div class="col-12 pb-2">' +
      '<div class="row align-content-center justify-content-between gx-1">' +
      '<div class="col-12 title">' +
      hoverMaterialSite.name +
      '</div>' +
      '</div>' +
      '<div class="row align-content-center gx-1 materials">' +
      '<div class=\'col-12 details-paragraphs-bubble\' *ngIf=\'selectedMaterialSite?.materials\'>' +
      '<div class=\'header5\'>';

    if (hoverMaterialSite?.materials?.length > 1) {
      myHTML += 'Materialien' + '</div>';
    } else {
      myHTML += 'Material' + '</div>';
    }
    if (hoverMaterialSite?.materials?.length === 0) {
      myHTML += '<div class=\'header5\'>' + 'keine Materialien vorhanden' + '</div>';
    }

    for (
      var i = 0, len = hoverMaterialSite?.materials.length > 2 ? 2 : hoverMaterialSite?.materials.length;
      i < len;
      i++
    ) {
      const material: Material = hoverMaterialSite?.materials[i];
      // const icon = material?.flowDirection === MaterialFlowDirection.input; else outputCase
      const type = enumHelper?.getDisplayEnumObject(material.type, ENUM_CONSTS.MATERIAL_TYPE)?.name ?? '';
      var quantity = '(Unendlich)';
      if (!(material?.quantity?.isUnlimited ?? false)) {
        quantity = decimalPipe?.transform(material?.quantity?.massInTon, '1.0-2', 'de') ?? '';
        quantity += 't / ' + (decimalPipe?.transform(material?.quantity?.volumeInM3, '1.0-2', 'de') ?? '') + 'm³';
      }

      myHTML +=
        ' <div class="description-box" >' +
        '<div class=\'d-flex flex-row justify-content-between material-type-row\'>' +
        '<div class=\'d-flex flex-row gap-2 align-items-center\'>' +
        '<div style=\'padding:2px; font-size:11px;background-color:' +
        Utils.getMaterialColor(material.type) +
        '\'>' +
        type +
        '</div>' +
        '</div>' +
        '</div>' +
        '<div class=\'d-flex flex-row gap-2 align-items-center flowText\'>' +
        quantity +
        '</div>' +
        '</div>';
    }
    if (hoverMaterialSite?.materials.length > 2) {
      myHTML +=
        '<div class=\'header5\'>' + '(+' + (hoverMaterialSite?.materials.length - 2) + ' weitere Materialien)</div>';
    }

    myHTML += '</div>' + '</div>';
    return myHTML;
  }

  static correctBubblePosition(targetGeo: IPoint | null, map : H.Map) {
    let targetGeoPosition = targetGeo;
    if (targetGeoPosition) {
      const mapRect = map.getElement().getBoundingClientRect();
      let markerScreenPosition = map.geoToScreen(targetGeoPosition);
      let bubbleXcoordinate = markerScreenPosition?.x ?? 0;
      let bubbleYcoordinate = markerScreenPosition?.y ?? 0;
      const bubbleHeight = 250;
      const bubbleWidth = 225;
      const bubbleOffset = 10;
      const markerHeight = 50;
      const markerWidth = 30;
      if (markerScreenPosition) {
        if (markerScreenPosition.y <= (0.5 * mapRect.height)) {
          bubbleYcoordinate = markerScreenPosition.y + bubbleHeight + (3 * bubbleOffset) + (0.5 * markerHeight);
        }
        if (markerScreenPosition.x <= (0.3 * mapRect.width)) {
          bubbleXcoordinate = markerScreenPosition.x + (0.6 * bubbleWidth) + (0.5 * markerWidth);
        }
        if (markerScreenPosition.x > (0.3 * mapRect.width) && markerScreenPosition.x <= (0.7 * mapRect.width)) {
          bubbleXcoordinate = markerScreenPosition?.x;
        }
        if (markerScreenPosition.x > (0.7 * mapRect.width)) {
          bubbleXcoordinate = markerScreenPosition.x - ((0.4 * bubbleWidth) + (0.5 * markerWidth));
        }
        targetGeoPosition = map.screenToGeo(bubbleXcoordinate, bubbleYcoordinate);
      }

    }
    return targetGeoPosition;
  }

  static hideSmallIconMarkerForSelected(selectedMaterialSite: MaterialSite, objects: H.map.Object[]): H.map.Object | undefined {
    for (let object of objects) {
      const isCluster = (object?.getData()).isCluster();
      if (isCluster) {
        let dataPointMaterialSite: MaterialSite | undefined;
        (object.getData() as H.clustering.ICluster).forEachDataPoint(dataPoint => {
          dataPointMaterialSite = dataPoint.getData();
        });

        if (dataPointMaterialSite?.geoLocation.latitude === selectedMaterialSite.geoLocation.latitude && dataPointMaterialSite?.geoLocation.longitude === selectedMaterialSite.geoLocation.longitude) {
          object.setVisibility(false);
          return object;
        }
      } else {
        // second getData is undefined if object is cluster representation
        const dataMaterialSite = (object?.getData()?.getData() as MaterialSite);
        if (dataMaterialSite?.id === selectedMaterialSite.id) {
          object.setVisibility(false);
          return object;
        }
      }
    }
    return undefined;
  }

  static getZoomLevel(radius: number): number {
      switch (radius) {
        case 5000:
          return 12;
        case 10000:
         return  11;
        case 25000:
         return  10;
        case 50000:
         return  9;
        case 100000:
         return  8;
        default:
         return  8;
      }
  }

}
