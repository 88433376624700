import {Component, EventEmitter, Input, OnInit, Output, Pipe, ViewChild} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {AbstractControlOptions, FormBuilder, FormGroup} from '@angular/forms';
import { FilterObject } from '../../../model/filter-object';
import {Subject} from "rxjs";

@Component({
  selector: 'app-flat-button-input',
  templateUrl: './flat-button-input.component.html',
  styleUrls: ['./flat-button-input.component.scss'],
})
export class FlatButtonInputComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | null = null;

  @Input() filter_name = 'filter_name';

  @Input() selectedValue: FilterObject = {value:null};
  @Input() disabled = false;

  @Input() wordingPipe: Pipe | null = null;
  @Output() changedSelection:EventEmitter<boolean> = new EventEmitter();

  readonly cubicmeter_icon = 'cubicmeter_icon.svg';

  cleanupSubject = new Subject<void>();

  // Needs to handle default behavior when a user clicks outside - is handled similar to okClicked
  menuClosedHandled:Boolean = false

  txtForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.txtForm = this.formBuilder.group({
      input: null
      }, {} as AbstractControlOptions
    );
  }

  ngOnInit(): void {
    this.txtForm.controls['input'].patchValue(this.selectedValue.value);
  }

  okClicked() {
    this.commitValues();
    this.close();
  }

  xClicked() {
    this.txtForm.controls['input'].setValue(null);
    this.commitValues();
    this.close()
  }

  private close() {
    this.menuClosedHandled = true;
    this.menuTrigger?.closeMenu();
  }

  private commitValues() {
    this.selectedValue.value = this.txtForm.controls['input'].value;
    this.changedSelection.emit(true);
  }
}
