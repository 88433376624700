import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AddressOption } from '../interface/address-option';
import { HereAutosuggestion } from '../model/here-autosuggestion';
import { map } from 'rxjs/operators';
import { GeoAutocomplete } from '../interface/geo-autocomplete';
import { GeoSearchAccuracy } from '../types/geo-search-accuracy';
import {GeoLocation} from "../model/geo-location";

@Injectable({ providedIn: 'root' })
export class HereService implements GeoAutocomplete {
  private HERE_AUTOCOMPLETE_URL = `https://autosuggest.search.hereapi.com/v1/autosuggest?in=countryCode:DEU&at=51.158627,10.445921&apiKey=${environment.hereAppKey}&lang=de&limit=5`;
  private HERE_LOOKUP_BY_ID_URL = `https://lookup.search.hereapi.com/v1/lookup?apiKey=${environment.hereAppKey}&lang=de&show=streetInfo`;
  private HERE_REV_GEOCODE = `https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=${environment.hereAppKey}&lang=de&show=streetInfo`;

  RESULT_TYPE_STREET = 'street';
  private RESULT_TYPE_HOUSE_NUMBER = 'houseNumber';
  RESULT_TYPE_HOUSE_CITY = 'locality';
  private LOCALITY_TYPE_CITY = 'city';

  constructor(private httpClient: HttpClient) {}

  private filterAddressesForAccuracy(result: HereAutosuggestion, geoAccuracy: GeoSearchAccuracy): boolean {
    switch (geoAccuracy) {
      case 'GEO_SEARCH_ACCURACY_CITY': {
        return (
          result.resultType === this.RESULT_TYPE_STREET ||
          result.resultType === this.RESULT_TYPE_HOUSE_NUMBER ||
          (result.resultType === this.RESULT_TYPE_HOUSE_CITY && result.localityType === this.LOCALITY_TYPE_CITY)
        );
      }
      case 'GEO_SEARCH_ACCURACY_STREET': {
        return result.resultType === this.RESULT_TYPE_STREET || result.resultType === this.RESULT_TYPE_HOUSE_NUMBER;
      }
      default: {
        return false;
      }
    }
  }

  private static convertResultToAddressOption(result: HereAutosuggestion): AddressOption {
    return {
      label: result.title,
      city: result.address?.city,
      zip: result.address?.postalCode,
      street: result.address?.street + (result.address?.houseNumber ? ' ' + result.address?.houseNumber : ''),
      lat: result.position.lat,
      lon: result.position.lng,
      country: result.address?.countryName,
      placeId: result?.id,
    };
  }

  getSuggestions$(query: string, accuracy: GeoSearchAccuracy): Observable<AddressOption[]> {
    const url = `${this.HERE_AUTOCOMPLETE_URL}&q=${query}`;
    return this.httpClient.get<{ items: HereAutosuggestion[] }>(url).pipe(
      map(res => {
        return (
          res.items
            ?.filter(result => this.filterAddressesForAccuracy(result, accuracy))
            ?.map(item => HereService.convertResultToAddressOption(item)) ?? []
        );
      })
    );
  }

  getDetails$(id: string | undefined): Observable<AddressOption> {
    const url = `${this.HERE_LOOKUP_BY_ID_URL}&id=${id}`;
    return this.httpClient
      .get<HereAutosuggestion>(url)
      .pipe(map(result => HereService.convertResultToAddressOption(result)));
  }

  getAddress$(geolocation: GeoLocation, accuracy: GeoSearchAccuracy): Observable<AddressOption[]> {
    const url = `${this.HERE_REV_GEOCODE}&at=${geolocation.latitude},${geolocation.longitude}`;
    return this.httpClient.get<{ items: HereAutosuggestion[] }>(url).pipe(
      map(res => {
        return (
          res.items
            ?.filter(result => this.filterAddressesForAccuracy(result, accuracy))
            ?.map(item => HereService.convertResultToAddressOption(item)) ?? []
        );
      })
    );
  }
}
