import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ParentComponent } from './inherit/parent.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CoreEffects } from './store/core.effects';
import { coreFeature } from './store/core.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LottieComponent } from 'ngx-lottie';
import { RegisterPageComponent } from './registration/register-page.component';
import { MatInputModule } from '@angular/material/input';
import { AuthComponent } from './auth/auth.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {preferencesFeature} from "./preferences-store/preferences-store.reducer";
import {PreferencesStoreEffects} from "./preferences-store/preferences-store.effects";

@NgModule({
  declarations: [
    ParentComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    LoginComponent,
    RegisterPageComponent,
    AuthComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    FormsModule,
    SharedModule,
    StoreModule.forFeature(coreFeature),
    StoreModule.forFeature(preferencesFeature),
    EffectsModule.forFeature([CoreEffects,PreferencesStoreEffects]),
    LottieComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    // StoreModule.forFeature(coreFeature),
    // EffectsModule.forFeature([HomeEffects]),
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class CoreModule {}

