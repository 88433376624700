<form [formGroup]='bookingForm'>
  <div class='row'>
    <div class='col-12 gray booking-form'>
      <div class='source-row gx-1'>
        <div class='d-flex flex-row align-items-start gap-1'>
          <ng-container *ngIf="bookingForm.controls['materialSiteRef'].value;else noRefSet">
            <img class='size-24' [src]='generalInformationIcon'>
            <h3 class='flex-fill w-0 align-self-center'>{{bookingForm.controls['materialSiteName'].value}}</h3>
            <img [src]='CLOSE_ICON'
                 (click)='bookingForm.controls["materialSiteName"].setValue(null); bookingForm.controls["materialSiteRef"].setValue(null)'
            *ngIf='!isMaterialFlowState'>
          </ng-container>
          <ng-template #noRefSet>
            <app-text-input label='Projektname' formControlName='materialSiteName' class='flex-fill w-0'
                            ></app-text-input>
            <img class="magnifier-button" [src]='MAGNIFIER_ICON' (click)='openSearchMaterialSite()' *ngIf="!isMaterialFlowState">
          </ng-template>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4 class="h4-header">Materialinformationen</h4>
        </div>
      </div>

      <div class='row text-row'>
        <div class='col-12'>
          <app-date-input label='Startdatum' formControlName='date'></app-date-input>
        </div>
      </div>

      <div class='row text-row'>
        <div class='col-12'>
          <app-text-input label='Dichte' formControlName='density'></app-text-input>
        </div>
      </div>

      <div class='row text-row'>
        <div class='col-12'>
          <mat-form-field>
            <mat-label>Angabe von Volumen oder Masse</mat-label>
            <mat-select formControlName='volumeOrMass'>
              <mat-option *ngFor='let value of values' [value]='value'>
                {{value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class='row text-row' *ngIf="bookingForm.controls['volumeOrMass'].value === 'Masse'">
        <div class='col-12'>
          <app-text-input label='Masse erhalten' [hint]='calcCurrentVolume'
                          formControlName='currentMass'></app-text-input>
        </div>
      </div>

      <div class='row text-row' *ngIf="bookingForm.controls['volumeOrMass'].value === 'Masse'">
        <div class='col-12'>
          <app-text-input label='Masse gesamt' [hint]='calcTargetVolume'
                          formControlName='targetMass'></app-text-input>
        </div>
      </div>

      <div class='row text-row' *ngIf="bookingForm.controls['volumeOrMass'].value === 'Volumen'">
        <div class='col-12'>
          <app-text-input label='Volumen erhalten' [hint]='calcCurrentMass'
                          formControlName='currentVolume'></app-text-input>
        </div>
      </div>

      <div class='row text-row' *ngIf="bookingForm.controls['volumeOrMass'].value === 'Volumen'">
        <div class='col-12'>
          <app-text-input label='Volumen gesamt' [hint]='calcTargetMass'
                          formControlName='targetVolume'></app-text-input>
        </div>
      </div>
    </div>
  </div>
</form>
