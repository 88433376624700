import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {MaterialSite} from "../../../model/material-site";
import {Material} from "../../../model/material";
import {BookingWizardComponent} from "../../../feature/material-flow/booking-wizard/booking-wizard.component";
import {takeUntil} from "rxjs/operators";
import {Const} from "../../../helper/const";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable, Subject, take} from "rxjs";
import {MaterialSiteType} from "../../../enum/material-site-type";
import {MaterialFlowDirection} from "../../../enum/material-flow-direction";
import {MaterialType} from "../../../enum/material-type";
import {ProjectType} from "../../../enum/project-type";
import {ProjectPhase} from "../../../enum/project-phase";
import {AbstractControlOptions, FormBuilder, FormGroup} from "@angular/forms";
import {
  ganttSearchStart,
  setSelectedMaterialSite
} from "../../../feature/material-flow/store/material-flow.actions";
import {Store} from "@ngrx/store";
import {
  selectGanttChartSelection,
  selectLastGanttSearchRequest
} from "../../../feature/material-flow/store/material-flow.reducer";
import {MaterialSiteGanttSearchRequest} from "../../../model/material-site-gantt-search-request";

@Component({
  selector: 'app-material-sites-detail-box',
  templateUrl: './material-sites-detail-box.component.html',
  styleUrls: ['./material-sites-detail-box.component.scss']
})
export class MaterialSitesDetailBoxComponent implements OnInit, OnDestroy {
  @Input() selectedMaterialSite: MaterialSite | null | undefined;

  @Output() closedEmitter = new EventEmitter();
  @Output() ganttToggle: EventEmitter<boolean> = new EventEmitter();

  readonly CLOSE_ICON = 'assets/icons/close_Icon.svg';
  readonly INPUT_ICON = 'assets/icons/input_icon.svg';
  readonly OUTPUT_ICON = 'assets/icons/output_icon.svg';
  readonly ROUTES = Const.ROUTES;

  private cleanupSubject = new Subject<void>();
  isGanttChart$: Observable<boolean> = this.store.select(selectGanttChartSelection);
  lastGanttSearchRequest$: Observable<MaterialSiteGanttSearchRequest | null> = this.store.select(selectLastGanttSearchRequest);

  materialSiteType = MaterialSiteType;
  materialFlowDirectionEnum = MaterialFlowDirection;
  materialTypeEnum = MaterialType;
  projectType = ProjectType;
  projectPhase = ProjectPhase;

  materialSelectionForm: FormGroup;
  selectedMaterialIDsArray: string[] = [];
  allMaterialsSelected: boolean = true;
  viewModeIsMap: boolean = true;


  constructor(public dialog: MatDialog, private snackbar: MatSnackBar, private formBuilder: FormBuilder, private store: Store) {

    this.materialSelectionForm = this.formBuilder.group({
      selectedMaterials: [true],
    }, {} as AbstractControlOptions);
  }

  ngOnInit() {
    this.selectedMaterialSite?.materials.forEach(material => {
      this.selectedMaterialIDsArray.push(material.id);
    });

    this.isGanttChart$.pipe(takeUntil(this.cleanupSubject)).subscribe((isGanttChartSelected) => {
      this.viewModeIsMap = !isGanttChartSelected;
    });

    this.materialSelectionForm.controls['selectedMaterials'].valueChanges.pipe(takeUntil(this.cleanupSubject))
      .subscribe(selectedValue => {
        if (selectedValue === true) {
          this.allMaterialsSelected = true;
          this.selectedMaterialSite?.materials.forEach(material => {
            if (!this.selectedMaterialIDsArray.includes(material.id)) {
              this.selectedMaterialIDsArray.push(material.id);
            }
          });
          this.startSearchRequests(this.selectedMaterialIDsArray);
        } else {
          this.allMaterialsSelected = false;
          this.selectedMaterialIDsArray = [];
          this.startSearchRequests(this.selectedMaterialIDsArray);
        }
      });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  closeDetails() {
    this.store.dispatch(setSelectedMaterialSite({materialSite: null}))
    this.closedEmitter.emit();
  }

  openBookingWizard(material: Material) {
    const bookingWizard = this.dialog.open(BookingWizardComponent, {
      height: 'fit-content',
      width: '1110px',
      autoFocus: false,
      panelClass: 'booking-wizard-dialog',
      data: {
        material: material,
        materialSite: this.selectedMaterialSite
      },
    });

    bookingWizard.afterClosed().pipe(takeUntil(this.cleanupSubject)).subscribe(result => {
      if (result === true) {
        this.snackbar.open('Buchung(en) erfolgreich hinzugefügt!', undefined, {
          panelClass: [Const.SNACK.BASE],
          duration: 3000
        });
      }
    });
  }

  getSelectedMaterials(selectedMaterialIDs: string[]) {
    if (selectedMaterialIDs.length === 0) {
      this.materialSelectionForm.controls['selectedMaterials'].patchValue(false, {emitEvent: false});
    } else {
      this.materialSelectionForm.controls['selectedMaterials'].patchValue(true, {emitEvent: false});
    }
    this.startSearchRequests(selectedMaterialIDs);
  }

  startSearchRequests(selectedMaterialIDs: string[]) {
    this.lastGanttSearchRequest$.pipe(take(1)).subscribe((lastGanttSearchRequest) => {
      if (lastGanttSearchRequest) {
        let ganttRequestFilter: MaterialSiteGanttSearchRequest = {
          ...lastGanttSearchRequest,
          includeMaterialIds: [...selectedMaterialIDs],
        }
        this.store.dispatch(ganttSearchStart({request: ganttRequestFilter, newRequest: true}));
      }
    });
  }

  onMapView() {
    this.ganttToggle.emit(false);
  }

  onGanttView() {
    this.ganttToggle.emit(true);
  }
}
