import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { HeaderService } from './service/header.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { getNames, registerLocale } from 'i18n-iso-countries';

import deLang from 'i18n-iso-countries/langs/de.json';


enum Direction {
  Up = 'Up',
  Down = 'Down',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'digimin-frontend-web';

  private scroll$: Observable<any> | undefined;
  private scrollUp$: Observable<any> | undefined;
  private scrollDown$: Observable<any> | undefined;
  private scrollUpSub: Subscription | undefined;
  private scrollDownSub: Subscription | undefined;

  constructor(private ngZone: NgZone, private headerService: HeaderService, public auth: AngularFireAuth) {}

  ngOnInit() {
    registerLocale(deLang);
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        // @ts-ignore
        map(([y1, y2]): Direction => {
          if (y1 > 80 && y2 > 80) {
            if (y2 < y1) {
              return Direction.Up;
            } else {
              return Direction.Down;
            }
          }
        }),
        distinctUntilChanged(),
        share()
      );
      this.scrollUp$ = this.scroll$.pipe(filter(direction => direction === Direction.Up));
      this.scrollDown$ = this.scroll$.pipe(filter(direction => direction === Direction.Down));
    });
    this.scrollDownSub = this.scrollDown$?.subscribe(() => {
      this.headerService.setVisibility(false);
    });
    this.scrollUpSub = this.scrollUp$?.subscribe(() => {
      this.headerService.setVisibility(true);
    });
  }
}
