import {Injectable} from "@angular/core";
import * as CryptoJS from 'crypto-js';
import {InfoBoxPreferences} from "../model/info-box-preferences";
import {Observable, of} from "rxjs";
import {SectionType} from "../model/section-type";
import { MaterialSiteSearchRequest, MaterialSiteSearchRequestHelper } from '../model/material-site-search-request';
import {Zuordnungsklasse} from "../enum/zuordnungsklasse";

@Injectable({providedIn: 'root'})
export class LocalStorageService {

  encryptionDecryptionKey = "123";

  constructor() {}


  public saveInfoBoxPreferences(sectionType: SectionType, show: boolean) {
    let data = localStorage.getItem("infoBoxPreferences")|| "";
    if (data === '') {
      let newInfoBoxPrefs = new InfoBoxPreferences();
      switch (sectionType) {
        case SectionType.MATERIAL_SEARCH:
          newInfoBoxPrefs.showMaterialFlowInfoBox = show;
          break;
        case SectionType.MATERIAL_SITE:
          newInfoBoxPrefs.showMaterialSitesInfoBox = show;
          break;
        case SectionType.PROFILE:
          newInfoBoxPrefs.showProfilesInfoBox = show;
          break;
        case SectionType.STATIC_MATERIAL_SITE:
          newInfoBoxPrefs.showStaticMaterialSitesInfoBox = show;
          break;
        case SectionType.MATERIAL_SITE_DETAIL:
          newInfoBoxPrefs.showMaterialSitesDetailInfoBox = show;
          break;
        case SectionType.LOGIN:
          newInfoBoxPrefs.showLoginInfoBox = show;
          break;
        case SectionType.REGISTER:
          newInfoBoxPrefs.showRegisterInfoBox = show;
          break;
      }
      localStorage.setItem('infoBoxPreferences', JSON.stringify(newInfoBoxPrefs));
    }
    else {
      let newInfoBoxPrefs: InfoBoxPreferences = JSON.parse(data);
      switch (sectionType) {
        case SectionType.MATERIAL_SEARCH:
          newInfoBoxPrefs.showMaterialFlowInfoBox = show;
          break;
        case SectionType.MATERIAL_SITE:
          newInfoBoxPrefs.showMaterialSitesInfoBox = show;
          break;
        case SectionType.PROFILE:
          newInfoBoxPrefs.showProfilesInfoBox = show;
          break;
        case SectionType.STATIC_MATERIAL_SITE:
          newInfoBoxPrefs.showStaticMaterialSitesInfoBox = show;
          break;
        case SectionType.MATERIAL_SITE_DETAIL:
          newInfoBoxPrefs.showMaterialSitesDetailInfoBox = show;
          break;
        case SectionType.LOGIN:
          newInfoBoxPrefs.showLoginInfoBox = show;
          break;
        case SectionType.REGISTER:
          newInfoBoxPrefs.showRegisterInfoBox = show;
          break;
      }
      localStorage.setItem('infoBoxPreferences', JSON.stringify(newInfoBoxPrefs));
    }
  }


  public getInfoBoxPreferences() : Observable<InfoBoxPreferences> {
    let data = localStorage.getItem('infoBoxPreferences')|| "";

    if (data === '') {
      let newUserPref = new InfoBoxPreferences();
      return of(newUserPref);
    }
    else {
      return of(JSON.parse(data));
    }
  }

  public getViewModeMaterialsPreferences() {
    let data = localStorage.getItem('materialsViewModeIsTable') || '';
    if (data === '') {
      let viewModeMaterials = true;
      return of(viewModeMaterials)
    }
    else {
      return of(JSON.parse(data));
    }
  }

  public setViewModeMaterialsPreferences(materialsViewModeIsTable: boolean) {
    localStorage.setItem('materialsViewModeIsTable', JSON.stringify(materialsViewModeIsTable));
  }

  public getFilterPreferences(): Observable<MaterialSiteSearchRequest> {
    let data = localStorage.getItem('filterPreferences') || '';
    if (data ==='') {
      let materialSiteSearchRequest : MaterialSiteSearchRequest = MaterialSiteSearchRequestHelper.getDefaultMaterialSiteRequest()
      return of(materialSiteSearchRequest)
    }
    else {
      return of(JSON.parse(data));
    }
  }

  setFilterPreferences(materialSiteFilter: MaterialSiteSearchRequest | null) {
    localStorage.setItem('filterPreferences', JSON.stringify(materialSiteFilter));
  }


  public removeData(key: string) {
    localStorage.removeItem(key);
  }


  public clearData() {
    localStorage.clear();
  }


  private encrypt(text: string): string {
    return CryptoJS.AES.encrypt(text, this.encryptionDecryptionKey).toString();
  }


  private decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.encryptionDecryptionKey).toString(CryptoJS.enc.Utf8);

  }
}
