import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";

@Directive({
  selector: '[appCaseSelector]'
})
export class CaseSelectorDirective implements OnInit{
  @Input() set appCaseSelector(checkValue: string | undefined) {
    switch (checkValue){
      case 'Ersatzbaustoff':
      case 'Sand':
      case 'Kies':
      case 'Boden':
      case 'Schotter':
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        break;
      default:
        this.viewContainerRef.clear();
        break;
    }

  }

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {
  }

  ngOnInit() {

  }
}
