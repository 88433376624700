<ng-container *ngIf="data?.dataKey; else textOnly">
  <div class="container">
    <div class="row">
      <div class="col-12">
        Sind Sie sicher, dass Sie {{data?.dataKey ?? 'dieses Objekt'}} löschen möchten?
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mat-dialog-actions">
          <button mat-raised-button type="button" [mat-dialog-close]="false" cdkFocusInitial>Nein</button>
          <button mat-raised-button color="warn" type="button" [mat-dialog-close]="true">Ja, löschen</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #textOnly>
  <div class='row d-flex justify-content-between align-items-center dialog-title' mat-dialog-title>
    <div class='col-11'>
      <h2>Konto löschen</h2>
    </div>
    <div class='col-1 p-0 close-button'>
      <button mat-icon-button mat-dialog-close='true'>
        <img src='../assets/icons/close_Icon.svg'>
      </button>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        {{data?.textOnly}}
      </div>
    </div>
  </div>
</ng-template>
