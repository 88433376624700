import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.component';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent extends BaseInputComponent implements OnInit, OnDestroy {
  readonly CLEAR_ICON = 'close_Icon.svg';
  errorTypes = ['invalidNumber', 'minlength', 'maxlength', 'required', 'email']

  @Input() isMultiLine = false;

  @Input() inputHintIcon: string | undefined;

  @Input() confirmPassword = false;

  @Output() clearButtonClicked = new EventEmitter<void>();

  @Input() inputType: 'password' | 'email' | 'text' | 'number' = 'text';
  @Input() additionalErrorTypes: Array<string> = [];
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() floatLabel: FloatLabelType = 'always';

  constructor(private changeDetectorRef: ChangeDetectorRef, private renderer2: Renderer2, injector: Injector) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.errorTypes.push(...this.additionalErrorTypes);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  onClearButtonClick(): void {
    this.control.patchValue('');
    this.clearButtonClicked.emit();
  }
}
