import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MaterialSite } from '../../model/material-site';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Const } from '../../helper/const';
import { EnumHelper } from '../../model/enum-helper';
import { selectEnumHelper } from '../../core/store/core.reducer';
import { ENUM_CONSTS } from '../../types/enum-identifier';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from './MatPaginatorIntlDigmin';
import {ProjectsStateInterface} from "../../feature/material-sites/interface/projects-state.interface";

@Component({
  selector: 'app-material-sites-table',
  templateUrl: './material-sites-table.component.html',
  styleUrls: ['./material-sites-table.component.scss'],
  providers: [[{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}]]
})
export class MaterialSitesTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isStaticSource = false;
  @Input() isBookingState = false;
  @Input() projects$: Observable<MaterialSite[] | null> | undefined;
  @Input() materialSitesLoading: boolean | null | undefined;
  @Input() selectedRouterLink: string | undefined;
  @Output() selectedMaterialSite = new EventEmitter<MaterialSite>();

  readonly detail_view_icon = 'assets/icons/arrow_right.svg';
  readonly ROUTES = Const.ROUTES;
  readonly MATERIAL_SITE_TYPE = ENUM_CONSTS.MATERIAL_SITE_TYPE;
  readonly PROJECT_TYPE = ENUM_CONSTS.PROJECT_TYPE;

  private cleaningSubject = new Subject<void>();

  displayedColumns: string[] = [];
  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);
  enumHelper: EnumHelper | null = null;

  dataSource = new MatTableDataSource<MaterialSite>();
  staticSourceTypeMap: Map<string, string> | undefined;
  selectedMaterialSiteId: string | undefined;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(private store: Store<ProjectsStateInterface>) {}

  ngOnInit(): void {
    if (this.isStaticSource){
      this.displayedColumns = ['name', 'staticSourceTypes', 'location', 'time-period', 'material-number', 'detail-view'];
    } else if (this.isBookingState){
      this.displayedColumns = ['name', 'type', 'projectType', 'internalId', 'location', 'time-period', 'material-number'];
    } else {
      this.displayedColumns = ['name', 'type', 'projectType', 'internalId', 'location', 'time-period', 'material-number', 'detail-view'];
    }

    this.fillDataFromStore();
  }

  ngAfterViewInit() {
    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  private fillDataFromStore() {
    if (this.enumHelper$ && this.projects$){
      combineLatest([this.enumHelper$, this.projects$])
        .pipe(takeUntil(this.cleaningSubject))
        .subscribe(([enumHelper, materialSites]) => {
          if (materialSites && enumHelper) {
            this.dataSource.data = materialSites;
            this.enumHelper = enumHelper;

            const tmpStaticSourceTypeMap = new Map<string, string>()
            enumHelper.staticSourceType.forEach(enumObject => {
              tmpStaticSourceTypeMap.set(enumObject.value, enumObject.name)
            });
            this.staticSourceTypeMap = tmpStaticSourceTypeMap;
          }
        });
    }
  }

  setSelectedProject(element: MaterialSite) {
    this.selectedMaterialSite.emit(element);
    this.selectedMaterialSiteId = element.id;
  }

  ngOnDestroy(): void {
    this.cleaningSubject.next();
    this.cleaningSubject.complete();
  }
}
