export enum Zuordnungsklasse{
  Z0_Stern ='Z 0*',
  Z0 = 'Z 0',
  Z1_1 = 'Z 1.1',
  Z1_2 = 'Z 1.2',
  Z2 = 'Z 2',
  Ueber_Z2 = '> Z 2',
  GeologischeBarriere = 'Geologische Barriere',
  DK_0 = 'DK-0',
  DK_I = 'DK-I',
  DK_II = 'DK-II',
  DK_III = 'DK-III',
  Rekultivierungsschicht = 'Rekultivierungsschicht',

  MEB = 'MEB',

  HOS_1 = 'HOS-1',
  HOS_2 = 'HOS-2',

  HS = 'HS',

  SWS_1 = 'SWS-1',
  SWS_2 = 'SWS-2',

  CUM_1 = 'CUM-1',
  CUM_2 = 'CUM-2',

  GKOS = 'GKOS',
  GRS = 'GRS',
  SKG = 'SKG',
  SKA = 'SKA',
  SFA = 'SFA',
  BFA = 'BFA',

  HMVA_1 = 'HMVA-1',
  HMVA_2 = 'HMVA-2',

  RC_1 = 'RC-1',
  RC_2 = 'RC-2',
  RC_3 = 'RC-3',

  BM_0 = 'BM-0',
  BM_0_Stern = 'BM-0*',
  BM_F0_Stern = 'BM-F0*',
  BM_F1 = 'BM-F1',
  BM_F2 = 'BM-F2',
  BM_F3 = 'BM-F3',

  BG_0 = 'BG-0',
  BG_0_Stern = 'BG-0*',
  BG_F0_Stern = 'BG-F0*',
  BG_F1 = 'BG-F1',
  BG_F2 = 'BG-F2',
  BG_3 = 'BG-3',

  GS_0 = 'GS-0',
  GS_1 = 'GS-1',
  GS_2 = 'GS-2',
  GS_3 = 'GS-3',

  ZM = 'ZM',
}
