import * as FromMaterialFlowActions from './material-flow.actions';
import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { MaterialFlowStateInterface } from '../interface/material-flow-state.interface';
import {immerOn} from "ngrx-immer/store";

export const initialMaterialFlowState: MaterialFlowStateInterface = {
  loading: false,
  errorMessage: null,
  additionalLoader: false,
  lastPageRequest: null,
  lastGanttSearchRequest: null,
  materialSites: null,
  ganttMaterialSites: null,
  search: null,
  filterRadius: 1000,
  selectedLocation: null,
  selectedMaterialSite: null,
  ganttChartSelection: false,
  includedMaterialIDs: null,
};

export const materialFlowFeature = createFeature({
  name: 'materialFlow',
  reducer: createReducer(
    initialMaterialFlowState,
    immerOn(FromMaterialFlowActions.searchStart, (state, { newRequest, request }) => {
      state.lastPageRequest = request;
      state.loading = newRequest;
      state.additionalLoader = !newRequest;
    }),
    immerOn(FromMaterialFlowActions.searchSuccess, (state, { newRequest, response }) => {
      state.errorMessage = null;
      if (!newRequest && (state?.materialSites?.length ?? 0) > 0) {
        state.materialSites = [...state!.materialSites!,  ...response];
      } else {
        state.materialSites = response;
      }
      state.materialSites = response;
      state.loading = false;
    }),
    immerOn(FromMaterialFlowActions.searchFail, (state) => {
      state.materialSites = null;
      state.loading = false;
    }),
    immerOn(FromMaterialFlowActions.setSelectedMaterialSite, (state, { materialSite }) => {
      state.selectedMaterialSite = materialSite;
    }),
    immerOn(FromMaterialFlowActions.ganttSearchStart, (state, { newRequest, request }) => {
      state.lastGanttSearchRequest = request;
      if (request.includeMaterialIds){
        state.includedMaterialIDs = request.includeMaterialIds;
      }
      state.loading = newRequest;
      state.additionalLoader = !newRequest;
      state.ganttMaterialSites = null;
    }),
    immerOn(FromMaterialFlowActions.ganttSearchSuccess, (state, { newRequest, response }) => {
      if (!newRequest && (state?.ganttMaterialSites?.length ?? 0) > 0) {
        state.ganttMaterialSites = [...state!.ganttMaterialSites!,  ...response];
      } else {
        state.ganttMaterialSites = response;
      }
      state.ganttMaterialSites = response;
      state.loading = false;
    }),
    immerOn(FromMaterialFlowActions.ganttSearchFail, (state) => {
      state.ganttMaterialSites = null;
      state.loading = false;
    }),
    immerOn(FromMaterialFlowActions.setSelectedMaterialFlowState, (state, { ganttChartSelection }) => {
      state.ganttChartSelection = ganttChartSelection;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectMaterialFlowState,
  selectLastPageRequest,
  selectLastGanttSearchRequest,
  selectMaterialSites,
  selectGanttMaterialSites,
  selectLoading,
  selectSearch,
  selectFilterRadius,
  selectSelectedMaterialSite,
  selectGanttChartSelection,
  selectIncludedMaterialIDs
} = materialFlowFeature;

// TODO: check selector memorization. seems not to work with current implementation
export const selectSearchLocation = createSelector(
  selectSearch,
  (search) => search?.location
);
