import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InfoBoxPreferences} from "../../model/info-box-preferences";
import {Observable, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import {CoreStateInterface} from "../../core/interface/core-state.interface";

import {takeUntil} from "rxjs/operators";
import {SectionType} from "../../model/section-type";
import {User} from "../../model/user";
import {Page} from "../../helper/page";
import {selectInfoBoxPreferences} from "../../core/preferences-store/preferences-store.reducer";
import {selectUser} from "../../core/store/core.reducer";
import {PreferencesStateInterface} from "../../core/interface/preferences-state.interface";
import {PreferencesActions} from "../../core/preferences-store/preferences-store.actions";

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})

export class SectionTitleComponent implements OnInit, OnDestroy {

  readonly INFO_ICON_ORANGE = '/assets/icons/alert-circle-orange.svg'
  readonly INFO_ICON_GRAY = '/assets/icons/alert-circle.svg'

  @Input() sectionType?: SectionType = undefined;
  @Input() infoText = '';
  @Input() currentPage: Page = new Page('', '');
  @Input() previousPages: Page[] = [];

  title = '';
  generalInformationIcon = '';

  infoBoxPreferences$ = this.preferencesStore.select(selectInfoBoxPreferences);
  showInfo: boolean | undefined = undefined;
  infoBoxPreferences: InfoBoxPreferences | undefined;

  cleanupSubject = new Subject<void>();
  SectionType = SectionType;
  user$: Observable<User | null> = this.coreStore.select(selectUser);
  currentUser: User | null = null;

  constructor(private coreStore: Store<CoreStateInterface>, private preferencesStore: Store<PreferencesStateInterface>) {
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.cleanupSubject)).subscribe(user => {
      this.currentUser = user;
    })

    this.preferencesStore.dispatch(PreferencesActions.getInfoBoxPreferencesStart());

    this.infoBoxPreferences$.pipe(takeUntil(this.cleanupSubject)).subscribe(infoBoxPrefs => {
      if (infoBoxPrefs) {
        this.infoBoxPreferences = infoBoxPrefs;
        switch (this.sectionType) {
          case SectionType.MATERIAL_SEARCH:
            this.showInfo = infoBoxPrefs?.showMaterialFlowInfoBox;
            break;
          case SectionType.MATERIAL_SITE:
            this.showInfo = infoBoxPrefs?.showMaterialSitesInfoBox;
            break;
          case SectionType.PROFILE:
            this.showInfo = infoBoxPrefs?.showProfilesInfoBox;
            break;
          case SectionType.STATIC_MATERIAL_SITE:
            this.showInfo = infoBoxPrefs?.showStaticMaterialSitesInfoBox;
            break;
          case SectionType.MATERIAL_SITE_DETAIL:
            this.showInfo = infoBoxPrefs?.showMaterialSitesDetailInfoBox;
            break;
          case SectionType.LOGIN:
            this.showInfo = infoBoxPrefs?.showLoginInfoBox;
            break;
          case SectionType.REGISTER:
            this.showInfo = infoBoxPrefs?.showRegisterInfoBox;
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  closeInfo(show: boolean) {
    if (this.sectionType != null) {
      this.preferencesStore.dispatch(PreferencesActions.setInfoBoxPreferencesStart({sectionType: this.sectionType, show: show}));
    }
  }


  toggleInfo() {
    switch (this.sectionType) {
      case SectionType.MATERIAL_SEARCH:
        this.closeInfo(!this.infoBoxPreferences?.showMaterialFlowInfoBox);
        break;
      case SectionType.MATERIAL_SITE:
        this.closeInfo(!this.infoBoxPreferences?.showMaterialSitesInfoBox);
        break;
      case SectionType.PROFILE:
        this.closeInfo(!this.infoBoxPreferences?.showProfilesInfoBox);
        break;
      case SectionType.STATIC_MATERIAL_SITE:
        this.closeInfo(!this.infoBoxPreferences?.showStaticMaterialSitesInfoBox);
        break;
      case SectionType.MATERIAL_SITE_DETAIL:
        this.closeInfo(!this.infoBoxPreferences?.showMaterialSitesDetailInfoBox);
        break;
      case SectionType.LOGIN:
        this.closeInfo(!this.infoBoxPreferences?.showLoginInfoBox);
        break;
      case SectionType.REGISTER:
        this.closeInfo(!this.infoBoxPreferences?.showRegisterInfoBox);
        break;
    }
  }
}
