import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject, from, catchError, throwError, switchMap, tap} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {User} from '../model/user';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Const} from "../helper/const";

@Injectable({providedIn: 'root'})
export class AuthService {
  user = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private router: Router, public auth: AngularFireAuth) {
  }

  login$(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.auth.signInWithEmailAndPassword(email, password)
    );
  }

  logout() {
    return from(this.auth.signOut());
  }

  createNewFirebaseUser(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.auth.createUserWithEmailAndPassword(email, password));
  }

  reauthenticateWithCredential$(firebaseUser: firebase.User, email: string, oldPassword: string): Observable<firebase.auth.UserCredential> {
    const userCredentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
    return from(firebaseUser.reauthenticateWithCredential(userCredentials));
  }

  updatePassword$(firebaseUser: firebase.User, newPassword: string): Observable<void> {
    return from(firebaseUser.updatePassword(newPassword));
  }


  verifyAndConfirmPassword$(code: string, newPassword: string): Observable<void> {
    // Checks the password reset code sent to the user by email or other out-of-band mechanism.
    // Returns the user's email address if valid.
    return from(this.auth.verifyPasswordResetCode(code))
      .pipe(
        switchMap((email) => {
          // Completes the password reset process, given a confirmation code and new password.
          return from(this.auth.confirmPasswordReset(code, newPassword))
        })
      );
  }

  verifyEmail$(code: string): Observable<void> {
    let actionCode: firebase.auth.ActionCodeInfo;
    return from(this.auth.checkActionCode(code))
      .pipe(
        switchMap((actionCodeInfo) => {
          actionCode = actionCodeInfo;
          return from(this.auth.applyActionCode(code));
        }),
        tap(() => {
          if (actionCode?.data?.email) {
            this.checkUserEmailVerificationStatus(actionCode.data.email);
          }
        }),
        catchError((error) => {
          const errorMessage = this.handleError(error.code);
          return throwError(() => {
            return errorMessage;
          })
        }));
  }

  checkUserEmailVerificationStatus(email: string): Observable<User> {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.USER + environment.API.VERIFY;
    return this.http.post<User>(url, JSON.stringify(email), httpOptions);
  }

  handleError(error: string): string {
    let message: string;

    switch (error) {
      case 'auth/invalid-action-code':
      case 'auth/expired-action-code':
        message = 'Der verwendete Link ist ungültig oder wurde bereits verwendet, bitte starten Sie den Prozess erneut.';
        break;
      case 'auth/user-disabled':
      case 'auth/user-not-found':
        message =
          'Ihr Account wurde deaktiviert oder entfernt. Bitte kontaktieren Sie den Systemadministrator.';
        break;
      case 'auth/too-many-requests':
        message =
          'Es gab zu viele Anfragen von Ihrem Gerät, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
        break;
      case 'auth/network-request-failed':
        message = 'Bitte überprüfen Sie Ihre Internetverbindung.';
        break;
      default:
        message = 'Etwas ist schiefgegangen! Bitte versuchen Sie es später erneut.';
        break;
    }
    return message;
  }
}
