import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {HereMapComponent} from './here-map/here-map.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FilterButtonComponent} from './filter-button/filter-button.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {RouterModule} from '@angular/router';
import {RegisterFormComponent} from './register-form/register-form.component';
import {MatSelectModule} from '@angular/material/select';
import {OverlayModule} from '@angular/cdk/overlay';
import {PasswordForgottenComponent} from './password-forgotten/password-forgotten.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TextInputComponent} from './inputs/text-input/text-input.component';
import {SelectInputComponent} from './inputs/select-input/select-input.component';
import {BaseInputComponent} from './inputs/base-input.component';
import {DisplayPropertyPipe} from './pipes/display-property.pipe';
import {DateInputComponent} from './inputs/date-input/date-input.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DigiminImgDirective} from './directives/digimin-img.directive';
import {AttachmentsComponent} from './attachments/attachments.component';
import {StaticMapComponent} from './static-map/static-map.component';
import {DndDirective} from './directives/dnd.directive';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {DeleteDialogComponent} from "./delete-dialog/delete-dialog.component";
import {CaseSelectorDirective} from "./directives/case-selector.directive";
import {BodenartSelectorDirective} from "./directives/bodenart-selector.directive";
import {ColorPickerDirective} from "./directives/color-picker.directive";
import {NgLetModule} from 'ng-let';
import {FileTypeDirective} from './directives/file-type.directive';
import {EnumStringValuePipe} from './pipes/enum-string-value.pipe';
import {EnumValuePipe} from './pipes/enum-value.pipe';
import {MaterialFlowSearchWordingPipe} from './pipes/material-flow-search-wording.pipe';
import {AddressInputComponent} from "./inputs/address-input/address-input.component";
import {InfoBoxComponent} from './info-box/info-box.component';
import {SectionTitleComponent} from './section-title/section-title.component';
import {ChangePasswordDialogComponent} from "./change-password-dialog/change-password-dialog.component";
import {IconComponent} from './icon/icon.component';
import {SwitchComponent} from './inputs/switch/switch.component';
import {SliderComponent} from './inputs/slider/slider.component';
import {MatSliderModule} from '@angular/material/slider';
import {MapSearchComponent} from './here-map/map-search/map-search.component';
import {FeatureBarComponent} from "./feature-bar/feature-bar.component";
import {FilterComponent} from "./material-sites-filter/filter.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatListModule} from "@angular/material/list";
import {MaterialsTableComponent} from './materials-table/materials-table.component';
import {MaterialSitesTableComponent} from "./material-sites-table/material-sites-table.component";
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DisplayEnumJoinPipe } from './pipes/display-enum-join.pipe';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { FlatButtonSingleComponent } from './flat-button/flat-button-single/flat-button-single.component';
import { FlatButtonInputComponent } from './flat-button/flat-button-input/flat-button-input.component';
import { FlatButtonDateComponent } from './flat-button/flat-button-date/flat-button-date.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterSheetComponent } from './here-map/filter-sheet/filter-sheet.component';
import { FlatButtonComponent } from './flat-button/flat-button/flat-button.component';
import { FlatButtonActionsComponent } from './flat-button/flat-button-actions/flat-button-actions.component';
import { FlatButtonMultiComponent } from './flat-button/flat-button-multi/flat-button-multi.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {BookingInputTileComponent} from "./booking-input-tile/booking-input-tile.component";
import {MaterialSitesDetailBoxComponent} from "./here-map/material-sites-detail-box/material-sites-detail-box.component";
import { MapKeyDialogComponent } from './here-map/map-key-dialog/map-key-dialog.component';
import {DateMonthFromNumberPipe} from "./pipes/date-month-from-number.pipe";
import {DateYearCounterFromMonthsNumberPipe} from "./pipes/date-year-counter-from-months-number.pipe";
import {
  MaterialFilterSwitchComponent
} from "./here-map/material-sites-detail-box/material-filter-switch/material-filter-switch.component";
import {GanttFilterSheetComponent} from "./here-map/gantt-filter-sheet/gantt-filter-sheet.component";
import { DefaultMapComponent } from './here-map/default-map/default-map.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {OverallProgressPipe} from "./pipes/overall-progress.pipe";
import {DoubleToNumericPipe} from "./pipes/double-to-numeric.pipe";


@NgModule({
  declarations: [
    LoadingSpinnerComponent,
    HereMapComponent,
    FilterButtonComponent,
    BreadcrumbsComponent,
    RegisterFormComponent,
    PasswordForgottenComponent,
    TextInputComponent,
    SelectInputComponent,
    AddressInputComponent,
    BaseInputComponent,
    DisplayPropertyPipe,
    DateInputComponent,
    DigiminImgDirective,
    AttachmentsComponent,
    StaticMapComponent,
    DndDirective,
    DeleteDialogComponent,
    CaseSelectorDirective,
    BodenartSelectorDirective,
    ColorPickerDirective,
    FileTypeDirective,
    EnumStringValuePipe,
    EnumValuePipe,
    MaterialFlowSearchWordingPipe,
    InfoBoxComponent,
    SectionTitleComponent,
    ChangePasswordDialogComponent,
    IconComponent,
    SwitchComponent,
    SliderComponent,
    MapSearchComponent,
    FeatureBarComponent,
    FilterComponent,
    MaterialsTableComponent,
    MaterialSitesTableComponent,
    FilterSheetComponent,
    FlatButtonMultiComponent,
    DisplayEnumJoinPipe,
    DynamicPipe,
    FlatButtonSingleComponent,
    FlatButtonInputComponent,
    FlatButtonDateComponent,
    FlatButtonComponent,
    FlatButtonActionsComponent,
    BookingInputTileComponent,
    MaterialSitesDetailBoxComponent,
    MapKeyDialogComponent,
    DateMonthFromNumberPipe,
    DateYearCounterFromMonthsNumberPipe,
    MaterialFilterSwitchComponent,
    GanttFilterSheetComponent,
    DefaultMapComponent,
    OverallProgressPipe,
    DoubleToNumericPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    OverlayModule,
    MatDialogModule,
    RouterModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatIconModule,
    NgLetModule,
    MatSliderModule,
    MatRadioModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    LoadingSpinnerComponent,
    HereMapComponent,
    FilterButtonComponent,
    BreadcrumbsComponent,
    RegisterFormComponent,
    PasswordForgottenComponent,
    TextInputComponent,
    BaseInputComponent,
    SelectInputComponent,
    AddressInputComponent,
    DisplayPropertyPipe,
    DateInputComponent,
    DigiminImgDirective,
    AttachmentsComponent,
    StaticMapComponent,
    DndDirective,
    DeleteDialogComponent,
    CaseSelectorDirective,
    BodenartSelectorDirective,
    ColorPickerDirective,
    FileTypeDirective,
    EnumStringValuePipe,
    EnumValuePipe,
    MaterialFlowSearchWordingPipe,
    InfoBoxComponent,
    SectionTitleComponent,
    ChangePasswordDialogComponent,
    DisplayEnumJoinPipe,
    FeatureBarComponent,
    FilterComponent,
    IconComponent,
    MaterialsTableComponent,
    MaterialSitesTableComponent,
    FilterSheetComponent,
    DynamicPipe,
    FlatButtonSingleComponent,
    FlatButtonMultiComponent,
    FlatButtonInputComponent,
    FlatButtonDateComponent,
    FlatButtonComponent,
    BookingInputTileComponent,
    MaterialSitesDetailBoxComponent,
    DateMonthFromNumberPipe,
    DateYearCounterFromMonthsNumberPipe,
    MaterialFilterSwitchComponent,
    GanttFilterSheetComponent,
    OverallProgressPipe,
    DoubleToNumericPipe,
    SliderComponent,
    SwitchComponent,
  ],
})
export class SharedModule {}
