import {createAction, props} from "@ngrx/store";
import {TeamAdminView} from "../../../model/team-admin-view";
import {User} from "../../../model/user";
import {TeamUserRole} from "../../../enum/team-user-role";

export const GET_TEAM_VIEW_START = '[Profile] Get team view start';
export const GET_TEAM_VIEW_SUCCESS = '[Profile] Get team view success';
export const GET_TEAM_VIEW_FAIL = '[Profile] Get team view fail';

export const CREATE_TEAM_USER_START = '[Profile] Create team user start';
export const CREATE_TEAM_USER_SUCCESS = '[Profile] Create team user success';
export const CREATE_TEAM_USER_FAIL = '[Profile] Create team user fail';

export const DELETE_TEAM_USER_START = '[Profile] Delete team user start';
export const DELETE_TEAM_USER_SUCCESS = '[Profile] Delete team user success';
export const DELETE_TEAM_USER_FAIL = '[Profile] Delete team user fail';

export const DELETE_USER_START = '[Profile] Delete user start';
export const DELETE_USER_SUCCESS = '[Profile] Delete user success';
export const DELETE_USER_FAIL = '[Profile] Delete user fail';

export const DELETE_TEAM_START = '[Profile] Delete team start';
export const DELETE_TEAM_SUCCESS = '[Profile] Delete team success';
export const DELETE_TEAM_FAIL = '[Profile] Delete team fail';

export const CHANGE_PASSWORD_START = '[Profile] Change password start';
export const CHANGE_PASSWORD_SUCCESS = '[Profile] Change password success';
export const CHANGE_PASSWORD_FAIL = '[Profile] Change password fail';

export const RESET_SUCCESS_MESSAGE_START = '[Profile] Reset Success Message Start';

export const getTeamViewStart = createAction(GET_TEAM_VIEW_START);
export const getTeamViewSuccess = createAction(GET_TEAM_VIEW_SUCCESS, props<{ teamView: TeamAdminView }>());
export const getTeamViewFail = createAction(GET_TEAM_VIEW_FAIL);

export const createTeamUserStart = createAction(CREATE_TEAM_USER_START , props<{ user: User, teamUserRole: TeamUserRole}>());
export const createTeamUserSuccess = createAction(CREATE_TEAM_USER_SUCCESS, props<{ teamView: TeamAdminView }>());
export const createTeamUserFail = createAction(CREATE_TEAM_USER_FAIL);

export const deleteTeamUserStart = createAction(DELETE_TEAM_USER_START, props<{ userID: string}>());
export const deleteTeamUserSuccess = createAction(DELETE_TEAM_USER_SUCCESS, props<{ userID: string}>());
export const deleteTeamUserFail = createAction(DELETE_TEAM_USER_FAIL);

export const deleteUserStart = createAction(DELETE_USER_START);
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS);
export const deleteUserFail = createAction(DELETE_USER_FAIL);

export const deleteTeamStart = createAction(DELETE_TEAM_START);
export const deleteTeamSuccess = createAction(DELETE_TEAM_SUCCESS);
export const deleteTeamFail = createAction(DELETE_TEAM_FAIL);

export const changePasswordStart = createAction(CHANGE_PASSWORD_START, props<{ oldPassword: string, newPassword: string }>());
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordFail = createAction(CHANGE_PASSWORD_FAIL);

export const resetSuccessMessageStart = createAction(RESET_SUCCESS_MESSAGE_START);
