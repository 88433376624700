import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ProjectsStateInterface} from '../../feature/material-sites/interface/projects-state.interface';
import {Store} from '@ngrx/store';
import {selectMaterialSitesFilter} from '../../feature/material-sites/store/materialSites.reducer';
import {combineLatest, Subject, take, takeUntil} from 'rxjs';
import {searchStart} from '../../feature/material-sites/store/materialSites.actions';
import {FormBuilder, FormGroup} from '@angular/forms';
import {selectEnumHelper} from '../../core/store/core.reducer';
import {DisplayEnumObject} from '../../model/display-enum-object';
import {MaterialType} from '../../enum/material-type';
import {MaterialFlowDirection} from '../../enum/material-flow-direction';
import {ProjectType} from '../../enum/project-type';
import {ProjectFilter} from '../../model/project-filter';
import {ENUM_CONSTS} from '../../types/enum-identifier';
import {StaticSourceType} from "../../enum/static-source-type";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() isStaticSource = false;

  readonly FILTER_ICON = '/assets/icons/filter.svg'

  materialSitesFilter$ = this.store.select(selectMaterialSitesFilter);
  enumHelper$ = this.store.select(selectEnumHelper);
  private cleaningSubject = new Subject<void>();
  filterForm: FormGroup;

  MATERIAL_TYPES: DisplayEnumObject[] | undefined;
  PROJECT_TYPES: DisplayEnumObject[] | undefined;
  MATERIAL_FLOW_DIRECTION: DisplayEnumObject[] | undefined;
  STATIC_SOURCE_TYPES: DisplayEnumObject[] | undefined;

  filter: ProjectFilter | undefined;

  constructor(private store: Store<ProjectsStateInterface>, private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      material: [null],
      projectType: [null],
      flowDirection: [null],
      staticSourceType: [null],
    });
  }

  ngOnInit(): void {
    combineLatest([this.enumHelper$, this.materialSitesFilter$])
      .pipe(take(1))
      .subscribe(([enumHelper, filter]) => {
        this.PROJECT_TYPES = enumHelper.projectType;
        this.MATERIAL_TYPES = enumHelper.materialType;
        this.MATERIAL_FLOW_DIRECTION = enumHelper.materialFlowDirection;
        this.STATIC_SOURCE_TYPES = enumHelper.staticSourceType;

        let material: DisplayEnumObject | null = null;
        let projectType: DisplayEnumObject | null = null;
        let flowDirection: DisplayEnumObject | null = null;
        let staticSourceType: DisplayEnumObject | null = null;

        if (filter) {
          material = enumHelper.getDisplayEnumObject(filter.materialType, ENUM_CONSTS.MATERIAL_TYPE) ?? null;
          projectType = enumHelper.getDisplayEnumObject(filter.projectType, ENUM_CONSTS.PROJECT_TYPE) ?? null;
          flowDirection = enumHelper.getDisplayEnumObject(filter.flowDirection, ENUM_CONSTS.MATERIAL_FLOW_DIRECTION) ?? null;
          staticSourceType = enumHelper.getDisplayEnumObject(filter.staticSourceType, ENUM_CONSTS.STATIC_SOURCE_TYPE) ?? null;
        }

        this.filterForm.patchValue({
          material,
          projectType,
          flowDirection,
          staticSourceType,
        });
      });

    this.filterForm?.valueChanges.pipe(takeUntil(this.cleaningSubject)).subscribe(changes => {
      this.filterProjects();
    });

    this.filter = {
      materialType: undefined,
      projectType: undefined,
      flowDirection: undefined,
    };
    this.setDefaultFilterValues(this.isStaticSource);
  }


  ngOnDestroy(): void {
    this.cleaningSubject.next();
    this.cleaningSubject.complete();
  }

  filterProjects(): void {
    const materialType: MaterialType | undefined = this.filterForm?.controls?.['material']?.value?.value;
    const flowDirection: MaterialFlowDirection | undefined = this.filterForm?.controls?.['flowDirection']?.value?.value;
    const projectType: ProjectType | undefined = this.filterForm?.controls?.['projectType']?.value?.value;
    const staticSourceType: StaticSourceType | undefined = this.filterForm?.controls?.['staticSourceType']?.value?.value;

    this.filter = {
      materialType,
      projectType,
      flowDirection,
      staticSourceType,
    };
    this.setDefaultFilterValues(this.isStaticSource);

    this.store.dispatch(searchStart({filter: this.filter, newRequest: true}));
  }

  clearFilter() {
    this.filterForm?.reset();
  }

  setDefaultFilterValues(isStatic: Boolean) {
    if (isStatic) {
      this.filter = {
        ...this.filter,
        types: ['staticSource']
      };
    } else {
      this.filter = {
        ...this.filter,
        types: ['externalProject', 'internalProject']
      };
    }
  }
}
