import {CoreStateInterface} from '../interface/core-state.interface';
import {createFeature, createReducer, on} from '@ngrx/store';
import * as FromCoreActions from './core.actions';
import {EnumHelper} from '../../model/enum-helper';
import {immerOn} from "ngrx-immer/store";

export const initialCoreStateState: CoreStateInterface = {
  user: null,
  team: null,
  authError: null,
  loading: false,
  logoutError: null,
  isAuthenticated: false,
  error: null,
  resetError: null,
  email: null,
  enumHelper: new EnumHelper(),
};

export const coreFeature = createFeature({
  name: 'core',
  reducer: createReducer(
    initialCoreStateState,
    immerOn(FromCoreActions.authenticateStart, (state) => {
      state.authError = null;
      state.loading = true;
    }),
    immerOn(FromCoreActions.authenticateSuccess, (state, { user }) => {
      state.user = user;
      state.team = user?.teams?.[0]?.team ?? null;
      state.authError = null;
      state.loading = false;
      state.isAuthenticated = true;
    }),
    immerOn(FromCoreActions.authenticateFail, (state, { payload }) => {
      state.user = null;
      state.authError = payload;
      state.loading = false;
      state.isAuthenticated = false;
    }),
    immerOn(FromCoreActions.logoutStart, (state) => {
      state.logoutError = null;
      state.loading = true;
    }),
    immerOn(FromCoreActions.logoutSuccess, (state) => {
      state.user = null;
      state.team = null;
      state.logoutError = null;
      state.loading = false;
      state.isAuthenticated = false;
    }),
    immerOn(FromCoreActions.logoutFail, (state, { payload }) => {
      state.logoutError = payload;
      state.loading = false;
      state.isAuthenticated = true;
    }),
    immerOn(FromCoreActions.clearError, (state) => {
      state.authError = null;
      state.error = null;
      state.logoutError = null;
      state.resetError = null;
    }),
    immerOn(FromCoreActions.getOwnUser, (state) => {
      state.authError = null;
      state.error = null;
      state.loading = true;
    }),
    immerOn(FromCoreActions.registerNewUserSuccess, (state, { user }) => {
      state.user = user;
      state.error = null;
      state.loading = false;
      state.isAuthenticated = true;
    }),
    immerOn(FromCoreActions.registerNewUserFail, (state, { error }) => {
      state.user = null;
      state.error = error;
      state.loading = false;
      state.isAuthenticated = false;
    }),

    immerOn(FromCoreActions.registerNewFirebaseUserStart, (state) => {
      state.error = null;
      state.loading = true;
    }),
    immerOn(FromCoreActions.registerNewFirebaseUserSuccess, (state) => {
    }),
    immerOn(FromCoreActions.registerNewFirebaseUserFail, (state, { error }) => {
      state.user = null;
      state.error = error;
      state.loading = false;
      state.isAuthenticated = false;
    }),

    immerOn(FromCoreActions.forgottenPasswordStart, (state) => {
      state.resetError = null;
      state.loading = true;
    }),
    immerOn(FromCoreActions.forgottenPasswordSuccess, (state, { email }) => {
      state.resetError = null;
      state.email = email;
      state.loading = false;
    }),
    immerOn(FromCoreActions.forgottenPasswordFail, (state, { resetError }) => {
      state.resetError = resetError;
      state.loading = false;
    }),

    immerOn(FromCoreActions.createTeamStart, (state) => {
      state.loading = true;
    }),
    immerOn(FromCoreActions.createTeamSuccess, (state, {team}) => {
      state.loading = false;
      state.team = team;
    }),
    immerOn(FromCoreActions.createTeamFail, (state) => {
      state.loading = false;
    }),

    immerOn(FromCoreActions.updateUserStart, (state) => {
      state.loading = true;
    }),
    immerOn(FromCoreActions.updateUserSuccess, (state, {user}) => {
      state.loading = false;
      state.user = user;
    }),
    immerOn(FromCoreActions.updateUserFail, (state) => {
      state.loading = false;
    }),
    immerOn(FromCoreActions.resetPasswordStart, (state) => {
      state.loading = true;
    }),
    immerOn(FromCoreActions.resetPasswordSuccess, (state) => {
      state.loading = false;
    }),
    immerOn(FromCoreActions.resetPasswordFail, (state) => {
      state.loading = false;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectCoreState,
  selectLoading,
  selectAuthError,
  selectLogoutError,
  selectUser,
  selectIsAuthenticated,
  selectError,
  selectTeam,
  selectEnumHelper,
} = coreFeature;
