import { ProjectType } from '../enum/project-type';
import { StaticSourceType } from '../enum/static-source-type';
import { MaterialType } from '../enum/material-type';
import { MaterialFlowDirection } from '../enum/material-flow-direction';
import { Zuordnungsklasse } from '../enum/zuordnungsklasse';
import { Ersatzbaustoff } from '../enum/ersatzbaustoff';

export interface MaterialSiteSearchRequest {
  types?: String[];
  // types?: MaterialSiteType[];
  projectTypes?: ProjectType[];
  staticSourceTypes?: StaticSourceType[];
  includeDigiminStaticSources?: boolean;

  // // material filter
  materialTypes?: MaterialType[];
  materialFlowDirection?:MaterialFlowDirection
  materialMinVolumeInM3?:Number
  materialZuordnungsklassen?: Zuordnungsklasse[]
  materialOccurrencePeriodStart?: string;
  materialOccurrencePeriodEnd?: string;
  materialErsatzbaustoffe?:Ersatzbaustoff[]
}

export class MaterialSiteSearchRequestHelper{
  static getDefaultMaterialSiteRequest(): MaterialSiteSearchRequest {
    let materialSiteSearchRequest : MaterialSiteSearchRequest =
      {
        materialTypes: [],
        materialFlowDirection: undefined,
        materialZuordnungsklassen: [],
        materialMinVolumeInM3: undefined,
        projectTypes: [],
        includeDigiminStaticSources: true,

        types: [
          "internalProject",
          "externalProject",
          "staticSource"
        ],
        materialOccurrencePeriodStart: undefined
      }
    return materialSiteSearchRequest;
  }
}


