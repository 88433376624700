import { Observable, of } from 'rxjs';
import { GeoAutocomplete } from '../interface/geo-autocomplete';
import { AddressOption } from '../interface/address-option';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { GeoapifyAutocompleteResponse } from '../interface/geoapify-autocomplete-response';
import { map } from 'rxjs/operators';
import { GeoapifyAutocompleteResult } from '../interface/geoapify-autocomplete-result';
import { GeoSearchAccuracy } from '../types/geo-search-accuracy';
import {GeoLocation} from "../model/geo-location";

@Injectable({ providedIn: 'root' })
export class GeoapifyService implements GeoAutocomplete {
  private GEOAPIFY_AUTOCOMPLETE_URL = `https://api.geoapify.com/v1/geocode/autocomplete?format=json&lang=de&apiKey=${environment.geoapifyApiKey}`;
  private GEOAPIFY_REV_GEOCODE_URL = `https://api.geoapify.com/v1/geocode/reverse?format=json&lang=de&apiKey=${environment.geoapifyApiKey}`;
  private addressOptions: Array<AddressOption> = [];

  RESULT_TYPE_STREET = 'street';
  private RESULT_TYPE_HOUSE_NUMBER = 'building';
  RESULT_TYPE_HOUSE_CITY = 'city';

  constructor(private httpClient: HttpClient) {}

  private static convertResultToAddressOption(result: GeoapifyAutocompleteResult): AddressOption {
    return {
      label: result.formatted,
      city: result.city,
      zip: result.postcode,
      street: result.street,
      lat: result.lat,
      lon: result.lon,
      country: result.country,
      placeId: result.place_id,
    };
  }

  private filterAddressesForAccuracy(result: GeoapifyAutocompleteResult, geoAccuracy: GeoSearchAccuracy): boolean {
    switch (geoAccuracy) {
      case 'GEO_SEARCH_ACCURACY_CITY': {
        return (
          result.result_type === this.RESULT_TYPE_STREET ||
          result.result_type === this.RESULT_TYPE_HOUSE_NUMBER ||
          result.result_type === this.RESULT_TYPE_HOUSE_CITY
        );
      }
      case 'GEO_SEARCH_ACCURACY_STREET': {
        return result.result_type === this.RESULT_TYPE_STREET || result.result_type === this.RESULT_TYPE_HOUSE_NUMBER;
      }
      default: {
        return false;
      }
    }
  }

  getDetails$(id: string | undefined): Observable<AddressOption> {
    const addressOption = structuredClone(this.addressOptions.find(option => option.placeId === id)!);
    this.resetSuggestions();
    return of(addressOption);
  }

  getSuggestions$(query: string, accuracy: GeoSearchAccuracy): Observable<Array<AddressOption>> {
    const url = `${this.GEOAPIFY_AUTOCOMPLETE_URL}&text=${query}`;
    return this.httpClient.get<GeoapifyAutocompleteResponse>(url).pipe(
      map(res => {
        const convertedRes =
          res.results
            ?.filter(result => this.filterAddressesForAccuracy(result, accuracy))
            ?.map(result => GeoapifyService.convertResultToAddressOption(result)) ?? [];
        this.addressOptions = convertedRes;
        return convertedRes;
      })
    );
  }

  resetSuggestions(): void {
    this.addressOptions = [];
  }

  getAddress$(geolocation: GeoLocation, accuracy: GeoSearchAccuracy): Observable<AddressOption[]> {
    const url = `${this.GEOAPIFY_REV_GEOCODE_URL}&lat=${geolocation.latitude}&lon=${geolocation.longitude}`;
    return this.httpClient.get<GeoapifyAutocompleteResponse>(url).pipe(
      map(res => {
        const convertedRes =
          res.results
            ?.filter(result => this.filterAddressesForAccuracy(result, accuracy))
            ?.map(result => GeoapifyService.convertResultToAddressOption(result)) ?? [];
        this.addressOptions = convertedRes;
        return convertedRes;
      })
    );
  }
}
