import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumStringValue',
})
export class EnumStringValuePipe implements PipeTransform {
  transform(value: string | undefined, enumValue: any): string {
    var result = value ?? ''
    if (value) {
      const entries = Object.entries(enumValue);
      // @ts-ignore
      for (var i = 0, len = entries.length; i < len; i++) {
        const entry = entries[i];
        const enumValue = entry[0] as string;
        if (value == enumValue){
          result =  entry[1] as string;
          return result
        }
      }
    }
    return result;
  }
}
