import {Material} from "../model/material";

export class OverallProgress {
  static calculate(selectedMaterial: Material): number {
    let overallCurrentMass = 0;
    let overallTargetMass = selectedMaterial?.quantity.massInTon ?? 0;
    selectedMaterial?.transactions?.forEach((item) =>{
      overallCurrentMass = (overallCurrentMass ?? 0) + (item?.currentQuantity?.massInTon ?? 0);
    });
    if (overallCurrentMass && overallTargetMass){
      return Math.round((overallCurrentMass / overallTargetMass) * 100);
    } else {
      return 0;
    }
  }
}
