import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import * as FromProjectsActions from "../../feature/material-sites/store/materialSites.actions";
import {Store} from "@ngrx/store";
import {ProjectsStateInterface} from "../../feature/material-sites/interface/projects-state.interface";
import {Observable, Subject, take} from 'rxjs';
import {Attachment} from "../../model/attachment";
import {Material} from "../../model/material";
import {MaterialSite} from "../../model/material-site";
import {
  selectLoading,
  selectMaterialSiteWithId,
  selectMaterialWithId
} from "../../feature/material-sites/store/materialSites.reducer";
import {AttachmentService} from "../../service/attachment.service";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnDestroy {

  @Input() public attachmentEdit: boolean | undefined;
  @Input() public useCase: string | undefined;
  readonly deleteAttachmentIcon = '../../assets/icons/delete_icon.svg';
  readonly downloadAttachmentIcon = '../../assets/icons/download_icon.svg';

  readonly CUSTOM_DIALOG_MESSAGE = 'diesen Anhang';

  public fileNames: string[] = [];
  public isImage: boolean = false;
  public isDocument: boolean = false;
  public files: File[] | undefined;

  cleanupSubject = new Subject<void>();
  parentObjectList$: Observable<Material | MaterialSite | null> | undefined;
  loading$: Observable<boolean> = this.store.select(selectLoading);

  allowedMimeTypes: string [] = [
    'image/',
    'application/'
  ]

  public downloadMap = new Map<string, string>();

  constructor(private store: Store<ProjectsStateInterface>, public deleteDialog: MatDialog, private attachmentService: AttachmentService) {
  }

  ngOnInit() {
    if (this.useCase === "materialSiteAttachment") {
      this.parentObjectList$ = this.store.select(selectMaterialSiteWithId);
    } else if (this.useCase === "materialAttachment") {
      this.parentObjectList$ = this.store.select(selectMaterialWithId);
    }
    this.parentObjectList$?.pipe(takeUntil(this.cleanupSubject)).subscribe((objectList: Material | MaterialSite | null) => {
      this.downloadMap.clear()
      objectList?.attachments?.forEach((attachment) => {
        this.attachmentService.getAttachmentUrl$(attachment.id, attachment.token).pipe(take(1)).subscribe((url) => {
          this.downloadMap.set(attachment.id ?? "", url ?? "");
        })
      })
    })
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  // @ts-ignore
  onFileSelected(event) {
    this.onFilesDropped(event.target.files)
  }

  onFilesDropped(files: any) {
    this.files = Array.from(files);

    if (this.files.length > 0) {
      // for (let file of this.files){
      //   TODO: ValidateMimeTypes if (validateMimeType(mimeType) === false) {
      //     remove File of files (z.B. splice)
      //     }
      //   let imageFileName = file.name
      //   this.fileNames.push(imageFileName);
      // }
      if (this.useCase === "materialSiteAttachment") {
        this.store.dispatch(FromProjectsActions.createMaterialSiteAttachmentStart({files: this.files}));
      } else if (this.useCase === "materialAttachment") {
        this.store.dispatch(FromProjectsActions.createMaterialAttachmentStart({files: this.files}));
      }
    }
  }

  validateMimeType(mimeType: string): boolean {
    for (const mime of this.allowedMimeTypes) {
      if (mimeType.includes(mime)) {
        return true;
      }
    }
    return false;
  }

  deleteAttachment(attachment: Attachment) {
    const deleteDialogRef = this.deleteDialog.open(DeleteDialogComponent, {
      data: {
        dataKey: this.CUSTOM_DIALOG_MESSAGE
      }
    });
    deleteDialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        if (attachment.id) {
          if (this.useCase === "materialSiteAttachment") {
            this.store.dispatch(FromProjectsActions.deleteMaterialSiteAttachmentStart({attachmentID: attachment?.id}));
          } else if (this.useCase === "materialAttachment") {
            this.store.dispatch(FromProjectsActions.deleteMaterialAttachmentStart({attachmentID: attachment.id}));
          }
        }

      }
    });
  }

}
