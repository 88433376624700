import {NativeDateAdapter} from "@angular/material/core";
import {Platform} from "@angular/cdk/platform";
import dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

export class AppDateAdapter extends NativeDateAdapter {

  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform)

    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(localizedFormat)
  }

  override parse(value: any): Date | null {
    return dayjs(value, 'DD.MM.YYYY').toDate()
  }

  override format(date: Date, displayFormat: any): string {
    return dayjs(date).format(displayFormat)
  }

}
