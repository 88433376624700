export enum ProjectType{
  Deponiebau = 'Deponiebau',
  Regenrueckhaltebecken = 'Regenrückhaltebecken',
  Strassenbau = 'Strassenbau',
  Gleisbau = 'Gleisbau',
  Brueckenbau = "Brückenbau",
  Tunnelbau = "Tunnelbau",
  OekologischeVerbesserung = "Ökologische Verbesserung",
  Flaechenreycling = "Flächenreycling",
  Sanierung = "Sanierung",
  Deichbau = "Deichbau",
  Wasserstrassenbau = "Wasserstrassenbau",
  Tongrubenverfuellung = "Tongrubenverfüllung",
  Sonstiges = "Sonstiges"
}
