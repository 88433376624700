import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";

@Directive({
  selector: '[appBodenartSelector]'
})
export class BodenartSelectorDirective implements OnInit{
  @Input() set appBodenartSelector(checkValue: string | undefined) {
    switch (checkValue){
      case 'Ton':
      case 'Lehm':
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        break;
      default:
        this.viewContainerRef.clear();
        break;
    }

  }

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {
  }

  ngOnInit() {

  }
}
