<ng-container *ngIf="sectionType !== SectionType.PROFILE; else profile">
  <div class="topic-container col-12">
    <app-breadcrumbs [currentPage]="currentPage" [previousPages]="previousPages"></app-breadcrumbs>

    <ng-container *ngIf="showInfo; else infoClosed">
      <img class="info-icon" [src]="INFO_ICON_ORANGE" (click)="toggleInfo()">
    </ng-container>
    <ng-template #infoClosed>
      <img class="info-icon" [src]="INFO_ICON_GRAY" (click)="toggleInfo()">
    </ng-template>
  </div>
</ng-container>
<ng-template #profile>
  <div class="d-flex flex-row justify-content-between">
    <div class="d-flex flex-row align-items-center" style="gap: 12px">
      <app-breadcrumbs [currentPage]="currentPage" [previousPages]="previousPages"></app-breadcrumbs>

      <ng-container *ngIf="showInfo; else infoClosed">
        <img class="info-icon" [src]="INFO_ICON_ORANGE" (click)="toggleInfo()">
      </ng-container>
      <ng-template #infoClosed>
        <img class="info-icon" [src]="INFO_ICON_GRAY" (click)="toggleInfo()">
      </ng-template>
    </div>

    <div class="d-flex flex-row align-items-center">
      <div *ngIf="currentUser?.teams?.length !== 0" style="color: #848585; font-weight: 400; font-size: 14px">
        Aktives Team:
      </div>
      <div>
        <ng-container *ngIf="currentUser?.teams?.length === 1; else multipleTeams">
          <div style="color: #848585; font-weight: 400; font-size: 14px; padding-left: 4px">
            {{ currentUser?.teams![0].team?.name ?? '?'}}
          </div>
        </ng-container>
        <ng-template #multipleTeams>
        </ng-template>
      </div>
    </div>

  </div>
</ng-template>

<app-info-box [show]="showInfo === true" (showInfoEvent)="closeInfo($event)" infoText="{{infoText}}"></app-info-box>
