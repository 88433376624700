import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-flat-button',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss']
})
export class FlatButtonComponent {
  @Input() filter_name = 'filter_name';
  @Input() value_default = 'alle';
  @Input() filter_values: String | null = null;

  @Input() disabled = false;


  constructor() {
  }

}
