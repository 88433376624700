export abstract class Const {
  static readonly ROUTES = {
    HOME_SCREEN: {
      BASE: 'home'
    },
    MATERIAL_FLOW: {
      BASE: 'material-flow'
    },
    PROJECTS: {
      BASE: 'projects',
      STATIC: 'static',
      MYPROJECTS: 'my-projects',
      DETAIL: 'detail'
    },
    LOGIN: 'login',
    REGISTER: 'registration',
    AUTH: 'profil-aktion',
    PROFILE: {
      BASE: 'profile'
    }
  };

  static readonly SNACK = {
    BASE: 'digiminSnack',
    WARN: 'snackWarn'
  }

  static readonly MIN_REQUEST_TIME = 300;
  static readonly CLOSE_ICON = './assets/icons/close_icon.svg'
  static readonly MATERIAL_PLACEHOLDER_IMAGE = './assets/images/placeholder_material_card.png';
}
