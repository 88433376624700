<div class="container">
  <div class="section-title">
    <app-section-title [currentPage]="currentPage" [previousPages]="previousPages" [sectionType]="sectionType"
                       infoText="In diesem Bereich können Sie sich für die Nutzung der Stoffstromnavigation registrieren, es wird automatisch ein Team für Ihre Projekte erstellt, zu dem Sie Mitglieder Ihres Unternehmens einladen können. Möchten Sie an einem bestehenden Teamprojekt mitarbeiten, lassen Sie sich vom Administrator dieses Teams einladen, eine Registration ist in diesem Fall nicht nötig.">
    </app-section-title>
  </div>
  <div class="row">
    <div class="col-12">
      <img class="img" [src]="test1">
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-register-form></app-register-form>
    </div>
  </div>
</div>
