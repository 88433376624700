import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreStateInterface } from '../interface/core-state.interface';
import * as AuthActions from '../store/core.actions';
import { selectAuthError, selectLoading } from '../store/core.reducer';
import { Observable } from 'rxjs';
import { Const } from '../../helper/const';
import { MatDialog } from '@angular/material/dialog';
import {PasswordForgottenComponent} from "../../shared/password-forgotten/password-forgotten.component";
import {Page} from "../../helper/page";
import {SectionType} from "../../model/section-type";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  readonly loginImage = 'assets/images/login_image.png';
  readonly REGISTRATION = Const.ROUTES.REGISTER;

  sectionType = SectionType.LOGIN;
  currentPage = new Page('Einloggen', '/' + Const.ROUTES.LOGIN);
  previousPages = [new Page('Startseite', '')]

  authError$: Observable<string | null> = this.store.select(selectAuthError);
  loginLoading$: Observable<boolean> = this.store.select(selectLoading);

  loginForm: FormGroup;

  constructor(private authService: AuthService, private router: Router, private store: Store<CoreStateInterface>, public dialog: MatDialog, private formBuilder: FormBuilder,) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    })
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.store.dispatch(AuthActions.clearError());
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    const email = this.loginForm.controls['email'].value.trimEnd();
    const password = this.loginForm.controls['password'].value;
    const REDIRECT_ROUTE = Const.ROUTES.HOME_SCREEN.BASE;

    this.store.dispatch(AuthActions.authenticateStart({email, password, route: REDIRECT_ROUTE}));
  }

  openDialog() {
    const dialogRef = this.dialog.open(PasswordForgottenComponent);
  }
}
