import {Component, OnDestroy} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as AuthActions from '../../core/store/core.actions';
import { Store } from '@ngrx/store';
import { CoreStateInterface } from '../../core/interface/core-state.interface';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})

export class PasswordForgottenComponent implements OnDestroy{
  constructor(private store: Store<CoreStateInterface>) {
  }

  public passwordResetted: boolean = false;

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    this.store.dispatch(AuthActions.forgottenPasswordStart({email}));
    form.reset();
    this.passwordResetted = true;
  }

  ngOnDestroy() {
    this.store.dispatch(AuthActions.clearError());
  }
}
