<app-flat-button [matMenuTriggerFor]='disabled ? null : menu' #menuTrigger='matMenuTrigger' (menuOpened)='menuOpened()'
                 (menuClosed)='menuClosed()' [disabled]='disabled' [filter_name]='filter_name'
                 [filter_values]='selectedValue.value?.name'></app-flat-button>
<mat-menu #menu='matMenu'>
    <div class='flat-filter-button-menu m-2' (click)="$event.stopPropagation()">
        <mat-radio-group [(ngModel)]='tmpSelectedValue.value'>
            <ng-container *ngFor='let displayEnum of valuesToShow'>
                <div>
                    <mat-radio-button [value]=displayEnum (click)='$event.stopPropagation()'>
                        <ng-container *ngIf='wordingPipe; else withWordingOutPipe'>
                            {{displayEnum.name}}
                            <!--              {{displayEnum.value | dynamicPipe: wordingPipe:[]}}-->
                        </ng-container>
                        <ng-template #withWordingOutPipe>
                            {{displayEnum.name}}
                        </ng-template>
                    </mat-radio-button>
                </div>
            </ng-container>
        </mat-radio-group>
    </div>
    <app-flat-button-actions (submitValue)='okClicked()' (resetValue)='xClicked()'></app-flat-button-actions>
</mat-menu>
