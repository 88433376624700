import { Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName, FormGroup,
  FormGroupDirective,
  NgControl, NgForm,
  NgModel,
} from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-base-input',
  template: ``,
})
export class BaseInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  readonly ICON_BASE = 'assets/icons/';

  @Input() errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (ctrl: FormControl) => {
      return ctrl && ctrl.touched && ctrl.invalid;
    },
  };

  @Input() label: string | undefined;
  @Input() hint: string | undefined;

  control!: FormControl;

  public destroy = new Subject<void>();

  constructor(@Inject(Injector) private injector: Injector) {}

  ngOnInit(): void {
    this.setComponentControl();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel;

        this.control = control;

        this.control.valueChanges
          .pipe(
            tap((value: any) => update.emit(value)),
            takeUntil(this.destroy)
          )
          .subscribe();
        break;
      }
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as FormControl;
        break;
      }
    }
  }

  onChange: any = () => {
    // nothing to do
  };

  onTouched: any = () => {
    // nothing to do
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(val: any): void {}
}
