<div class='container main-container'>
  <div class="section-title">
    <app-section-title [currentPage]="currentPage" [previousPages]="previousPages" [sectionType]="sectionType"
                       infoText="In diesem Bereich können Sie sich mit Ihren registrierten Nutzerdaten einloggen, überprüfen Sie gegebenenfalls Ihre Mails, ob Sie bereits zu einem Team eingeladen wurden und eine E-Mail mit Zugangsdaten erhalten haben.">
    </app-section-title>
  </div>
  <div class='row'>
    <div class='col-12'>
      <img class='img-fluid' [src]='loginImage'>
    </div>
  </div>

  <div class='row header-row'>
    <div class='col-12 col-md-12'>
      <p class='header1'>EINLOGGEN</p>
    </div>
    <div  class='col-12 col-md-8 paragraph' >Bitte geben Sie ihre E-Mail-Adresse und Ihr persönliches Passwort ein und klicken Sie anschließend auf den Button “Einloggen”. Anschließend gelangen Sie direkt zur Stoffstromsuche.</div>
  </div>

  <div class='col-12 col-md-8'>
    <div class='alert alert-danger' *ngIf='(authError$ | async)'>
      <p>{{ authError$| async }}</p>
    </div>
    <div *ngIf='(loginLoading$ | async)' style='text-align: center;'>
      <app-loading-spinner></app-loading-spinner>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)='onSubmit()' *ngIf='(loginLoading$ | async) === false'>
      <div class='row m-0 form-container'>
        <div class='col-md-6 col-12'>
          <app-text-input label="E-Mail-Adresse" formControlName="email" [inputType]="'email'"></app-text-input>
        </div>
        <div class='col-md-6 col-12'>
          <app-text-input minlength='6' label="Passwort" formControlName="password" [inputType]="'password'"></app-text-input>
        </div>
      </div>

      <div class='row button-row'>
        <div class='col-md-6 col-12'>
          <button mat-raised-button type='submit' class='login-button w-100' color='primary' [disabled]='!this.loginForm.valid'>Einloggen
          </button>
          <div class='paragraph'>
            <a class='forgotten-password' (click)='openDialog()'>Passwort vergessen?</a>
          </div>
          <div class='paragraph'>Noch kein Account?
            <a [routerLink]="['/' + REGISTRATION]">Jetzt registrieren</a>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

