<mat-form-field class="text-input w-100" [appearance]="appearance" [attr.multiple]="multiple ? true : undefined">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [multiple]="multiple" [value]="control.value" (selectionChange)="onOptionChange($event)" [formControl]="control">
    <form *ngIf="hasFilter" class="sticky-top">
      <mat-form-field>
        <mat-label>Schnellsuche</mat-label>
        <input matInput type="text" [formControl]="filter">
        <button matSuffix mat-icon-button aria-label="Clear" (click)="filter.setValue('')"><app-icon class="close-icon" name="close"></app-icon></button>
      </mat-form-field>
    </form>
    <mat-option *ngIf="nonOption">
      -
    </mat-option>
    <ng-container *ngFor="let option of options">
      <mat-option [value]="option" [class.invisible]="filter.value !== '' && !(option | displayProperty:displayProperty).toLowerCase().includes(filter.value?.toLowerCase())">
        {{ option | displayProperty:displayProperty }}
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="(hint?.length ?? 0) > 0" >{{ hint }}</mat-hint>
  <mat-error *ngIf="control?.hasError('required')">Das ist ein Pflichtfeld</mat-error>
  <mat-error *ngIf="control?.hasError('email')">Bitte gültige E-Mail eingeben</mat-error>
</mat-form-field>
