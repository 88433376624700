import {createFeature, createReducer, on} from '@ngrx/store';
import {immerOn} from "ngrx-immer/store";
import {PreferencesStateInterface} from "../interface/preferences-state.interface";
import {PreferencesActions} from "./preferences-store.actions";

export const initialPreferencesState: PreferencesStateInterface = {
  loading: false,
  infoBoxPreferences: null,
  materialsViewModeIsTable: true,
  materialSiteSearchRequest: {},
};

export const preferencesFeature = createFeature({
  name: 'preferences',
  reducer: createReducer(
    initialPreferencesState,
    immerOn(PreferencesActions.setMaterialSiteSearchRequestForMaterialFlowComponent, (state, {materialSiteSearchRequest}) => {
      state.materialSiteSearchRequest = materialSiteSearchRequest;
    }),


    immerOn(PreferencesActions.getInfoBoxPreferencesStart, (state) => {
      state.loading = true;
    }),
    immerOn(PreferencesActions.getInfoBoxPreferencesSuccess, (state, {infoBoxPreferences}) => {
      if (infoBoxPreferences !== null) {
        state.infoBoxPreferences = infoBoxPreferences;
        state.loading = false;
      }
    }),


    immerOn(PreferencesActions.setInfoBoxPreferencesStart, (state) => {
      state.loading = true;
    }),
    immerOn(PreferencesActions.setInfoBoxPreferencesSuccess, (state) => {
      state.loading = false;
    }),


    immerOn(PreferencesActions.getViewModeMaterialsPreferencesStart, (state) => {
      state.loading = true;
    }),
    immerOn(PreferencesActions.getViewModeMaterialsPreferencesSuccess, (state, {materialsViewModeIsTable}) => {
        state.materialsViewModeIsTable = materialsViewModeIsTable;
        state.loading = false;
    }),


    immerOn(PreferencesActions.setViewModeMaterialsPreferencesStart, (state) => {
      state.loading = true;
    }),
    immerOn(PreferencesActions.setViewModeMaterialsPreferencesSuccess, (state) => {
      state.loading = false;
    }),


    immerOn(PreferencesActions.getFilterPreferencesStart, (state) => {
      state.loading = true;
    }),
    immerOn(PreferencesActions.getFilterPreferencesSuccess, (state, {materialSiteSearchRequest}) => {
      state.materialSiteSearchRequest = materialSiteSearchRequest;
      state.loading = false;
    }),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectInfoBoxPreferences,
  selectMaterialsViewModeIsTable,
  selectMaterialSiteSearchRequest,
} = preferencesFeature;
