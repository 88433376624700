import { Injectable } from '@angular/core';
import { MaterialFlow } from '../model/material-flow.model';
import { ApiService } from './api.service';
import { MaterialSiteSearchRequest } from '../model/material-site-search-request';
import { Observable } from 'rxjs';
import { MaterialSite } from '../model/material-site';

@Injectable()
export class MaterialFlowService {
  constructor(private apiService: ApiService) {
  }

  searchMaterialSites$(request: MaterialSiteSearchRequest, teamId: string): Observable<MaterialSite[]> {
    return this.apiService.searchMaterialSites$(request, teamId);
  }

}
