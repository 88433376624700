import {Component} from "@angular/core";
import {Page} from "../../helper/page";
import {Const} from "../../helper/const";
import {SectionType} from "../../model/section-type";

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent{
  readonly test1 = 'assets/images/login_image.png';

  sectionType = SectionType.LOGIN;
  currentPage = new Page('Registrieren', '/' + Const.ROUTES.REGISTER);
  previousPages = [new Page('Startseite', '')]
}
