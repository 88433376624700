import {Pipe, PipeTransform} from "@angular/core";
import {Material} from "../../model/material";
import {OverallProgress} from "../../helper/overallProgress";

@Pipe({
  name: 'overallProgressPipe'
})
export class OverallProgressPipe implements PipeTransform {
  transform(value: Material): number {
    return OverallProgress.calculate(value);
  }
}
