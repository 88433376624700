import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumValue',
})
export class EnumValuePipe implements PipeTransform {
  transform(value: string | undefined, enumValue: any): string {
    return value ?? '';
  }
}
