import { Pipe, PipeTransform } from '@angular/core';
import { MaterialFlowDirection } from '../../enum/material-flow-direction';
import { DisplayEnumObject } from '../../model/display-enum-object';

@Pipe({
  name: 'displayEnumJoin',
  pure: false
})
export class DisplayEnumJoinPipe implements PipeTransform {
  transform(input:Array<DisplayEnumObject>, sep = ','): string {
    return input.map(displayEnum => displayEnum.name).join(sep);
  }
}
