import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-flat-button-actions',
  templateUrl: './flat-button-actions.component.html',
  styleUrls: ['./flat-button-actions.component.scss'],
})
export class FlatButtonActionsComponent{
  @Output() submitValue: EventEmitter<boolean> = new EventEmitter;
  @Output() resetValue: EventEmitter<boolean> = new EventEmitter;

  constructor() {
  }

  resetClicked($event: MouseEvent) {
    $event.stopPropagation()
    this.resetValue.emit(true)
  }

  submitClicked($event: MouseEvent) {
    $event.stopPropagation()
    this.submitValue.emit(true)
  }
}
