import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Store } from '@ngrx/store';
import { getOwnUser } from './core/store/core.actions';
import { firstValueFrom, Subject } from 'rxjs';
import { selectCoreState } from './core/store/core.reducer';
import { takeUntil } from 'rxjs/operators';

export function initializeApplication(auth: AngularFireAuth, store: Store) {
  return () =>
    new Promise(resolve => {
      firstValueFrom(auth.idToken).then(idToken => {
        if (idToken) {
          store.dispatch(getOwnUser({}));
          const cleanupSubject = new Subject<void>();
          store
            .select(selectCoreState)
            .pipe(takeUntil(cleanupSubject))
            .subscribe(state => {
              if ((state.authError && !state.loading) || (state.user && !state.loading)) {
                resolve(true);
                cleanupSubject.next();
                cleanupSubject.complete();
              }
            });
        } else {
          resolve(true);
        }
      });
    });
}
