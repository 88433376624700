<div class='row d-flex justify-content-between align-items-center dialog-title' mat-dialog-title>
  <div class='col-11'>
    <h2>Passwort ändern</h2>
  </div>
  <div class='col-1 p-0 close-button'>
    <button mat-icon-button mat-dialog-close='true'>
      <img src='../assets/icons/close_Icon.svg'>
    </button>
  </div>
</div>

<form [formGroup]='changePasswordForm' (ngSubmit)='onSubmit()'>
  <div class="container w-100 container-gray">
    <div class="row">
      <div class='col-12'>
        <app-text-input type="password"
                        label="Altes Passwort"
                        formControlName="oldPassword"
                        [inputType]="'password'">
        </app-text-input>
      </div>
      <div class='col-12'>
        <app-text-input label="Neues Passwort"
                        formControlName="newPassword"
                        [inputType]="'password'">
        </app-text-input>
      </div>
      <div class='col-12'>
        <app-text-input label="Passwort wiederholen"
                        formControlName="confirmNewPassword"
                        [additionalErrorTypes]="['notSame']"
                        [errorStateMatcher]="matcher"
                        [inputType]="'password'">
        </app-text-input>
      </div>
    </div>

    <hr>

    <mat-dialog-actions align='start'>
      <div class='col-12 button-col'>
        <button mat-raised-button class='w-100 h-56 mb-2' color='primary' type='submit' mat-dialog-close='true'
                [disabled]='!changePasswordForm.valid'>
          <span>Passwort ändern</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
