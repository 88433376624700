import {Injectable} from "@angular/core";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "../../environments/environment";
import {Observable, of, switchMap, take} from "rxjs";

@Injectable({providedIn: 'root'})
export class AttachmentService {
  readonly BASE_URL = environment.API.BASE + environment.API.ATTACHMENT;

  constructor(private auth: AngularFireAuth) {
  }

  getAttachmentUrl$(attachmentId: string | undefined, attachmentToken: string | undefined): Observable<string | undefined>{
    return this.auth.user.pipe(
        take(1),
        switchMap(
          (user) => {
            if (user && attachmentToken && attachmentId) {
              return of(`${this.BASE_URL}/${attachmentId}/file?firebaseUid=${user.uid}&token=${attachmentToken}`);
            } else {
              return of(undefined);
            }
          }
        )
      )
  }
}


