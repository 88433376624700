import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { TeamBasicView } from "../model/team-basic-view";
import {TeamUser} from "../model/team-user";
import {TeamAdminView} from "../model/team-admin-view";

@Injectable({providedIn: 'root'})
export class TeamService {
  constructor(private httpClient: HttpClient) {
  }
  getOwnTeam$(): Observable<TeamBasicView> {
    //  TODO: replace dummy request

    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.TEAM;
    return this.httpClient.get<TeamBasicView>(url, httpOptions);
  }

  getTeamAsAdmin$(teamID: string): Observable<TeamAdminView> {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.TEAM + '/' + teamID + environment.API.ADMIN;
    return this.httpClient.get<TeamAdminView>(url, httpOptions);
  }

  createTeamUser$(teamID: string, teamUser: TeamUser): Observable<TeamAdminView> {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.TEAM + '/' + teamID + environment.API.TEAM_USER;
    return this.httpClient.post<TeamAdminView>(url, JSON.stringify(teamUser), httpOptions);
  }

  deleteTeamUser$(teamID: string, userID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.TEAM + '/' + teamID + environment.API.TEAM_USER + '/' + userID;
    return this.httpClient.delete<TeamUser>(url, httpOptions);
  }

  deleteTeam$(teamID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.TEAM + '/' + teamID;
    return this.httpClient.delete(url, httpOptions);
  }

  createTeam$(team: Partial<TeamBasicView>): Observable<TeamAdminView> {
    const httpOptions = ApiService.getHeader();
    const url = environment.API.BASE + environment.API.TEAM;
    return this.httpClient.post<TeamAdminView>(url, JSON.stringify(team), httpOptions);
  }

  // getTeamWithRole$(teamID: string, role: string): Observable<TeamAdminView | TeamOwnerView> {
  //   const httpOptions = ApiService.getHeader(teamID);
  //   const url = environment.API.BASE + environment.API.TEAM + '/' + role;
  //   return this.httpClient.get<TeamAdminView | TeamOwnerView>(url, httpOptions);
  // }

}
