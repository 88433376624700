<div class="small-container w-100 align-items-center" matAutocompleteOrigin #origin="matAutocompleteOrigin">
  <app-icon *ngIf="(inputPrefixIcon?.length ?? 0) > 0" [name]="inputPrefixIcon"></app-icon>
  <input type="text"
         class="w-100 pt-1"
         matInput
         [placeholder]="placeholder"
         [formControl]="control"
         [matAutocompleteConnectedTo]="origin"
         [matAutocomplete]="auto"
         #elementInput>
  <button *ngIf="control.value" [disabled]="control.disabled" matSuffix mat-icon-button aria-label="Clear" (click)="onClearButtonClick()">
    <app-icon name="close"></app-icon>
  </button>
  <mat-autocomplete class="map-search" autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getResultLabel">
    <ng-container *ngIf="loadingState; else notLoading">
      <mat-option class="autosuggestLoading">
        <app-loading-spinner></app-loading-spinner>
      </mat-option>
    </ng-container>
    <ng-template #notLoading>
      <ng-container *ngIf="(searchResults?.length ?? 0) > 0; else noResults">
        <mat-option [title]="isMaterialSite(result) ? result.name : result?.label" *ngFor="let result of searchResults" [value]="result">
          <ng-container *ngIf="isMaterialSite(result); else addressOption">
            <app-icon name="project"></app-icon>
            {{result?.name}}
          </ng-container>
          <ng-template #addressOption>
            <!--    Second if statement is necessary because the compiler otherwise doesnt know that the type is correct     -->
            <ng-container *ngIf="isAddressOption(result)">
              <app-icon name="map-pin"></app-icon>
              {{result?.label}}
            </ng-container>
          </ng-template>
        </mat-option>
      </ng-container>
      <ng-template #noResults>
        <mat-option *ngIf="!initialState">
          Keine Ergebnisse
        </mat-option>
      </ng-template>
    </ng-template>
  </mat-autocomplete>
</div>

