import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AddressOption } from '../../../interface/address-option';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../../inputs/base-input.component';
import { MaterialSite } from '../../../model/material-site';
import { Utils } from '../../../helper/utils';

@Component({
  selector: 'app-map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MapSearchComponent),
      multi: true,
    },
  ],
})
export class MapSearchComponent extends BaseInputComponent implements OnInit, OnDestroy, OnChanges {

  @Input() inputHintIcon: string | undefined;
  @Input() inputPrefixIcon: string | undefined;
  @Input() placeholder: string = '';
  @Input() searchResults: Array<AddressOption | MaterialSite> = [];
  @Input() loadingState: boolean | undefined | null;
  @Output() clearButtonClicked = new EventEmitter<void>();

  initialState = true;

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['addressOptions'] && !changes['addressOptions']?.firstChange) {
      this.initialState = false;
    }
  }

  onClearButtonClick(): void {
    this.control.patchValue('');
    this.clearButtonClicked.emit();
    this.initialState = true;
  }

  getResultLabel(result: AddressOption | MaterialSite) {
    if (Utils.isMaterialSite(result)) {
      return result?.name ?? '';
    }
    return result?.label ?? '';
  }

  // Type Guards
  isMaterialSite = Utils.isMaterialSite;
  isAddressOption = Utils.isAddressOption;

}
