import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Observable, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import {ProjectsStateInterface} from "../../material-sites/interface/projects-state.interface";
import {takeUntil} from 'rxjs/operators';
import {combineLatest} from "rxjs";
import {ProjectFilter} from "../../../model/project-filter";
import {MatStepper} from "@angular/material/stepper";
import {BookingWizardStore} from "../store/component-store/booking-wizard.store";
import {MaterialSite} from "../../../model/material-site";
import {MaterialTransaction} from "../../../model/material-transaction";
import {Material} from "../../../model/material";
import {AbstractControlOptions, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidator} from "../../../helper/customValidator";
import {MaterialQuantity} from "../../../model/material-quantity";
import {OverallProgress} from "../../../helper/overallProgress";
import {EnumHelper} from "../../../model/enum-helper";
import {selectEnumHelper} from "../../../core/store/core.reducer";
import {ENUM_CONSTS} from "../../../types/enum-identifier";
import {NumberFormatter} from "../../../helper/number-formatter";
import {DensityCalculator} from "../../../helper/densityCalculator";

@Component({
  selector: 'app-booking-wizard',
  templateUrl: './booking-wizard.component.html',
  styleUrls: ['./booking-wizard.component.scss'],
  providers: [BookingWizardStore]
})

export class BookingWizardComponent implements OnInit, OnDestroy {
  readonly CLOSE_ICON = 'assets/icons/close_Icon.svg';
  readonly INPUT_ICON = 'assets/icons/input_icon.svg';
  readonly OUTPUT_ICON = 'assets/icons/output_icon.svg';
  readonly CONE_ICON = 'assets/icons/cone_icon.svg';

  projects$: Observable<MaterialSite[] | null> = this.componentStore.materialSites$;
  readonly materialSitesLoading$: Observable<boolean> = this.componentStore.loading$;

  referencedMaterialSite: MaterialSite | null = null;
  selectedProject: MaterialSite | null = null;

  filteredMaterials$: Observable<Material[] | null> = this.componentStore.filteredMaterials$;
  selectedMaterialSite$: Observable<MaterialSite | null> = this.componentStore.selectMaterialSiteWithId$;
  selectedMaterial$: Observable<Material | null> = this.componentStore.selectMaterialWithId$;
  materialTransactions$: Observable<MaterialTransaction[] | null> = this.componentStore.selectMaterialTransactions$;
  isLoading$ = this.componentStore.loading$;
  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);

  cleanupSubject = new Subject<void>();
  bookingForm: FormGroup = this.formBuilder.group({
    materialSiteRef: null,
    materialSiteName: [{ value: '', disabled: true }],
    date: ['', [Validators.required]],
    density: ['', [Validators.required, CustomValidator.numericDE]],
    volumeOrMass: ['Masse', [Validators.required]],
    currentMass: ['', [Validators.required, CustomValidator.numericDE]],
    targetMass: [{ value: '', disabled: true }, [Validators.required, CustomValidator.numericDE]],
    currentVolume: [{ value: '', disabled: true }, [Validators.required, CustomValidator.numericDE]],
    targetVolume: [{ value: '', disabled: true }, [Validators.required, CustomValidator.numericDE]],
    isMassPrimaryInput: true,
  }, {} as AbstractControlOptions);

  currentMaterialTransactions: MaterialTransaction[] = [];
  public progressValue = 0;

  @ViewChild('stepper') stepper: MatStepper | undefined;

  constructor(private formBuilder: FormBuilder, private store: Store<ProjectsStateInterface>,
              @Inject(MAT_DIALOG_DATA) public data: any, private readonly componentStore: BookingWizardStore) {
    this.bookingForm.controls['materialSiteName'].patchValue(this.data.materialSite?.name);
    this.bookingForm.controls['materialSiteRef'].patchValue(this.data.materialSite?.id);
    combineLatest([this.enumHelper$]).pipe(takeUntil(this.cleanupSubject)).subscribe(([enumHelper]) => {
      let flowdirection;
      if (this.data.material.flowDirection === 'output') {
        flowdirection = enumHelper.getDisplayEnumObject('input', ENUM_CONSTS.MATERIAL_FLOW_DIRECTION);
      } else {
        flowdirection = enumHelper.getDisplayEnumObject('output', ENUM_CONSTS.MATERIAL_FLOW_DIRECTION);
      }
      const filter: ProjectFilter = {
        materialType: this.data.material.type,
        projectType: undefined,
        flowDirection: flowdirection?.value,
        types: ['internalProject', 'externalProject'],
      };
      this.componentStore.startMaterialSitesSearch({
        materialSitesFilter: filter,
        mapMaterialSiteID: this.data.materialSite?.id,
      });
    });
  }

  ngOnInit() {
    this.referencedMaterialSite = this.data.referencedMaterialSite
    if(this.referencedMaterialSite) {
      this.componentStore.setSelectedProject(this.referencedMaterialSite)
    }

    this.selectedMaterial$.pipe(takeUntil(this.cleanupSubject)).subscribe(
      selectedMaterial => {
        if (selectedMaterial) {
          this.progressValue = OverallProgress.calculate(selectedMaterial);
          this.bookingForm.controls['targetMass'].patchValue(NumberFormatter.FromDoubleToNumeric(selectedMaterial.quantity.massInTon));
          this.bookingForm.controls['targetVolume'].patchValue(NumberFormatter.FromDoubleToNumeric(selectedMaterial.quantity.volumeInM3));
        }
      });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  nextStep(stepper: MatStepper) {
    if (stepper.selected) {
      stepper.selected.completed = true;
    }
    stepper.next();
  }

  previousStep(stepper: MatStepper) {
    stepper.previous();
  }

  completeBooking(stepper: MatStepper) {
  }

  addBooking() {
    const density = NumberFormatter.ConvertFromNumericToDouble(this.bookingForm.controls['density'].value);
    let currentMass = NumberFormatter.ConvertFromNumericToDouble(this.bookingForm.controls['currentMass'].value?? '0');
    let currentVolume = NumberFormatter.ConvertFromNumericToDouble(this.bookingForm.controls['currentVolume'].value ?? '0');
    let targetMass = NumberFormatter.ConvertFromNumericToDouble(this.bookingForm.controls['targetMass'].value ?? '0');
    let targetVolume = NumberFormatter.ConvertFromNumericToDouble(this.bookingForm.controls['targetVolume'].value ?? '0');

    if (this.bookingForm.controls['volumeOrMass'].value === 'Volumen'){
      currentMass = NumberFormatter.ConvertFromNumericToDouble(DensityCalculator.calculateVolumeOrMass('Volumen', density, currentVolume));
      targetMass = NumberFormatter.ConvertFromNumericToDouble(DensityCalculator.calculateVolumeOrMass('Volumen', density, targetVolume));
    }

    if (this.bookingForm.controls['volumeOrMass'].value === 'Masse'){
      currentVolume = NumberFormatter.ConvertFromNumericToDouble(DensityCalculator.calculateVolumeOrMass('Masse', density, currentMass));
      targetVolume = NumberFormatter.ConvertFromNumericToDouble(DensityCalculator.calculateVolumeOrMass('Masse', density, targetMass));
    }

    const currentQuantity: MaterialQuantity = {
      isUnlimited: undefined,
      densityInTonPerM3: density,
      massInTon: currentMass,
      volumeInM3: currentVolume,
      isMassPrimaryInput: this.bookingForm.controls['isMassPrimaryInput'].value,
    }
    const targetQuantity: MaterialQuantity = {
      isUnlimited: undefined,
      densityInTonPerM3: density,
      massInTon: targetMass,
      volumeInM3: targetVolume,
      isMassPrimaryInput: this.bookingForm.controls['isMassPrimaryInput'].value,
    }

    const materialTransaction: MaterialTransaction = {
      id: null,
      fromMaterialSiteId: this.bookingForm.controls['materialSiteRef'].value,
      fromMaterialSiteName: this.bookingForm.controls['materialSiteName'].value,
      lastUpdate: this.bookingForm.controls['date'].value,
      currentQuantity: currentQuantity,
      aimedQuantity: targetQuantity,
    }

    this.componentStore.createMaterialTransaction({materialTransaction});
    this.currentMaterialTransactions.push(materialTransaction);
    this.bookingForm.reset();
    this.bookingForm.patchValue({
        materialSiteName: this.data.materialSite?.name,
        volumeOrMass: 'Masse',
      }
    )
  }

  setSelectedMaterialSite($event: MaterialSite) {
    this.selectedProject = $event
    this.componentStore.setSelectedProject($event);
  }

  handleSelectedMaterial($event: Material) {
    this.componentStore.setSelectedMaterial($event);
  }
}
