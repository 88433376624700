import {AbstractControl} from "@angular/forms";

export class CustomValidator{

  static numeric(control: AbstractControl) {
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };
    return null;
  }

  static numericDE(control: AbstractControl) {
    let regex2 = /^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])((\.[0-9][0-9][0-9])*|[0-9]*)(,[0-9]+)?$/;
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(regex2)) return { 'invalidNumber': true };
    return null;
  }

  static int(control: AbstractControl) {
    let regex = /^([0-9]*)$/;
    let val = control.value;
    if (val === null || val === '') return null;
    if (!val.toString().match(regex)) return { 'invalidNumber': true };
    return null;
  }
}
