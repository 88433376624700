<app-flat-button [matMenuTriggerFor]='disabled?null:menu' #menuTrigger='matMenuTrigger' (menuOpened)='menuOpened()' (menuClosed)='menuClosed()' [disabled]='disabled' [filter_name]='filter_name' [filter_values]='selectedValues | displayEnumJoin'></app-flat-button>
<mat-menu #menu='matMenu' >
  <form *ngIf='dropDownForm' [formGroup]='dropDownForm'>
    <div class='flat-filter-button-menu m-2' (click)='$event.stopPropagation()'>
      <ng-container *ngFor='let displayEnum of valuesToShow'>
        <div>
          <mat-checkbox [formControlName]='displayEnum.value' (click)='$event.stopPropagation()'>
            <ng-container *ngIf='wordingPipe; else withWordingOutPipe'>
              {{displayEnum.name | dynamicPipe: wordingPipe:[]}}
            </ng-container>
            <ng-template #withWordingOutPipe>
              {{displayEnum.name}}
            </ng-template>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </form>
  <app-flat-button-actions (submitValue)='okClicked()' (resetValue)='xClicked()'></app-flat-button-actions>
</mat-menu>


