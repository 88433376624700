import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../service/auth.service';
import {
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Const} from '../../helper/const';
import {ComparisonErrorStateMatcher} from "../../shared/register-form/register-form.component";
import {Store} from "@ngrx/store";
import {CoreStateInterface} from "../interface/core-state.interface";
import * as AuthActions from "../store/core.actions";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  readonly test1 = 'assets/images/platzhalter.png';

  auth$: Subscription | undefined;
  public authError = null;
  public email = null;

  public mode = this.route.snapshot.queryParams['mode'];
  public oobCode = this.route.snapshot.queryParams['oobCode'];

  frmSetNewPassword: FormGroup;
  matcher = new ComparisonErrorStateMatcher();

  constructor(private route: ActivatedRoute, private authService: AuthService, private fb: FormBuilder,
              private router: Router, private store: Store<CoreStateInterface>) {
    this.frmSetNewPassword = this.fb.group({
      password: ['', [Validators.minLength(6), Validators.required]],
      confirmPassword: ['', [Validators.minLength(6), Validators.required]]
    }, {validator: this.validatePasswords} as AbstractControlOptions);
  }

  onSubmit() {
    const password = this.frmSetNewPassword.controls['password'].value;

    if (this.mode === 'resetPassword' && password != null) {
      this.store.dispatch(AuthActions.resetPasswordStart({code: this.oobCode, newPassword: password}));
    }
  }

  redirectMatFlow() {
    this.router.navigate([Const.ROUTES.MATERIAL_FLOW.BASE]);
  }

  validatePasswords(group: FormGroup) {
    let password = group.controls['password'].value;
    let confirmPassword = group.controls['confirmPassword'].value;

    return password === confirmPassword ? null : {notSame: true}
  }

  ngOnInit() {
    if (this.mode === 'verifyEmail') {
      this.auth$ = this.authService.verifyEmail$(this.oobCode)
        .subscribe({
          next: () => {
          }, error: (error) => {
            this.authError = error;
          }
        });
    }
  }

  ngOnDestroy() {
    this.authError = null;
    this.auth$?.unsubscribe();
  }
}
