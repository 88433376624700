import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subject} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'app-base',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeInEvent', [

      // the "in" style determines the "resting" store of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600)
      ])],
    ),
    trigger('toogleDialog', [
      transition(':enter', [
        style({height: 0, top: '100vh'}),
        animate('300ms'), style({height: 'auto', top: '0'})]),
      transition(':leave', animate('300ms', style({height: 0,  top: '100vh'})))
    ])
  ]
})
export class ParentComponent implements OnDestroy, OnInit {

  public location: Location;
  public router: Router;
  public snackBar: MatSnackBar;


  public isMobile: boolean;

  cleanUpSubject = new Subject<void>();
  showDialog = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = event.target.innerWidth <= 768;
    this.cdr.detectChanges();
  }

  constructor(injector: Injector, public renderer: Renderer2, public cdr: ChangeDetectorRef) {
   this.location = injector.get(Location);
   this.router = injector.get(Router);
   this.snackBar = injector.get(MatSnackBar);
   const breakpointObserver = injector.get(BreakpointObserver);
   this.isMobile = breakpointObserver.isMatched('(max-width: 768px)');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.cleanUpSubject.next();
    this.cleanUpSubject.complete();
  }

  openSnackBar(message: string, durationNum: number): void {
    this.snackBar.open(message, undefined , {
      duration: durationNum,
      panelClass: 'digiminSnack',
      verticalPosition: 'bottom'
    });
  }

  addNoScrollClass(): void {
    this.renderer.addClass(document.body, 'noScroll');
  }

  removeClass(): void {
    this.renderer.removeClass(document.body, 'noScroll');
  }

  navBack() {
    this.location.back();
  }

}
