export enum Ersatzbaustoff{
  Abbruchmaterial = 'Abbruchmaterial',
  Gleisschotter = 'Gleisschotter',
  RCBaustoff = 'RC-Baustoff',
  Straßenaufbruch = 'Straßenaufbruch',
  Hausmuellverbrennungsasche = 'Hausmüllverbrennungsasche',
  GesiebtesAushubmaterial = 'Gesiebtes Aushubmaterial',
  Hochofenstueckschlacke = 'Hochofenstückschlacke',
  Stahlwerksschlacke = 'Stahlwerksschlacke',
  GiessereiKupolofenschlacke = 'Gießerei-Kupolofenschlacke',
  Huettensand = 'Hüttensand',
  Kupferhuettenmaterial = 'Kupferhüttenmaterial',
  SchmelzkammergranulatAusDerSchmelzfeuerungVonSteinkohle = 'Schmelzkammergranulat aus der Schmelzfeuerung von Steinkohle',
  Gießereirestsand = 'Gießereirestsand',
  Steinkohlenkesselasche = 'Steinkohlenkesselasche',
  Steinkohlenflugasche = 'Steinkohlenflugasche',
  Braunkohlenflugasche = 'Braunkohlenflugasche',
  Baggergut = 'Baggergut',
  Ziegelmaterial = 'Ziegelmaterial',
  UnbehandelterAushub = 'Unbehandelter Aushub',
  Eisensilikat = 'Eisensilikat',
  MineralikAusMechanischBiologischenBehandlungsanlagen = 'Mineralik aus Mechanisch-Biologischen Behandlungsanlagen'
}
