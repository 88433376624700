import { createAction, props } from '@ngrx/store';
import { User } from '../../model/user';
import { TeamBasicView } from '../../model/team-basic-view';

export const AUTHENTICATE_START = '[Core] Login Start';
export const AUTHENTICATE_SUCCESS = '[Core] Login Success';
export const AUTHENTICATE_FAIL = '[Core] Login Fail';

export const CLEAR_ERROR = '[Core] Clear Error';
export const GET_OWN_USER = '[Core] Get Own User';

export const REGISTER_NEW_USER_SUCCESS = '[Core] Register New User Success';
export const REGISTER_NEW_USER_FAIL = '[Core] Register New User Fail';

export const REGISTER_NEW_FIREBASE_USER_START = '[Core] Register New Firebase User Start';
export const REGISTER_NEW_FIREBASE_USER_SUCCESS = '[Core] Register New Firebase User Success';
export const REGISTER_NEW_FIREBASE_USER_FAIL = '[Core] Register New User Firebase Fail';

export const LOGOUT_START = '[Core] Logout Start';
export const LOGOUT_SUCCESS = '[Core] Logout Success';
export const LOGOUT_FAIL = '[Core] Logout Fail';

export const FORGOTTEN_PASSWORD_START = '[Core] Forgotten Password Start';
export const FORGOTTEN_PASSWORD_SUCCESS = '[Core] Forgotten Password Success';
export const FORGOTTEN_PASSWORD_FAIL = '[Core] Forgotten Password Fail';

export const CREATE_TEAM_START = '[Core] Create team start';
export const CREATE_TEAM_SUCCESS = '[Core] Create team success';
export const CREATE_TEAM_FAIL = '[Core] Create team fail';

export const UPDATE_USER_START = '[Core] Update user start';
export const UPDATE_USER_SUCCESS = '[Core] Update user success';
export const UPDATE_USER_FAIL = '[Core] Update user fail';

export const RESET_PASSWORD_START = '[Core] Reset Password start';
export const RESET_PASSWORD_SUCCESS = '[Core] Reset Password success';
export const RESET_PASSWORD_FAIL = '[Core] Reset Password fail';

export const authenticateStart = createAction(AUTHENTICATE_START, props<{
    email: string;
    password: string;
    route?: string;
  }>()
);
export const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS, props<{ user: User }>());
export const authenticateFail = createAction(AUTHENTICATE_FAIL, props<{ payload: string }>());

export const clearError = createAction(CLEAR_ERROR);

export const getOwnUser = createAction(GET_OWN_USER, props<{ route?: string }>());

export const registerNewUserSuccess = createAction(REGISTER_NEW_USER_SUCCESS, props<{ user: User, team: Partial<TeamBasicView> }>());
export const registerNewUserFail = createAction(REGISTER_NEW_USER_FAIL, props<{ error: string }>());

export const registerNewFirebaseUserStart = createAction(REGISTER_NEW_FIREBASE_USER_START,
  props<{ user: Partial<User>, password: string, team: Partial<TeamBasicView>, route?: string }>());
export const registerNewFirebaseUserSuccess = createAction(REGISTER_NEW_FIREBASE_USER_SUCCESS, props<{ user: Partial<User>, password: string, team: Partial<TeamBasicView>, route?: string }>());
export const registerNewFirebaseUserFail = createAction(REGISTER_NEW_FIREBASE_USER_FAIL, props<{ error: string }>());

export const logoutStart = createAction(LOGOUT_START);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);
export const logoutFail = createAction(LOGOUT_FAIL, props<{ payload: string }>());

export const forgottenPasswordStart = createAction(FORGOTTEN_PASSWORD_START, props<{ email: string }>());
export const forgottenPasswordSuccess = createAction(FORGOTTEN_PASSWORD_SUCCESS, props<{ email: string }>());
export const forgottenPasswordFail = createAction(FORGOTTEN_PASSWORD_FAIL, props<{ resetError: string }>());

export const createTeamStart = createAction(CREATE_TEAM_START, props<{ team: Partial<TeamBasicView> }>());
export const createTeamSuccess = createAction(CREATE_TEAM_SUCCESS, props<{ team: TeamBasicView }>());
export const createTeamFail = createAction(CREATE_TEAM_FAIL);

export const updateUserStart = createAction(UPDATE_USER_START, props<{ user: User }>());
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS, props<{ user: User }>());
export const updateUserFail = createAction(UPDATE_USER_FAIL);

export const resetPasswordStart = createAction(RESET_PASSWORD_START, props<{ code: string, newPassword: string }>());
export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);
export const resetPasswordFail = createAction(RESET_PASSWORD_FAIL);
