<div class='flat-filter-button d-flex justify-content-between align-items-center flex-nowrap'
     [attr.customDisabled]='disabled'>
    <span class='flex-grow-1 text-truncate'>{{filter_name}}
      <ng-container *ngIf='filter_values;else defaultValue'>:
          <b>
          {{filter_values}}
         </b>
      </ng-container>
      <ng-template #defaultValue>: {{value_default}}</ng-template>
    </span>
  <app-icon class='flex-shrink-0' name='arrow_down'></app-icon>
</div>
