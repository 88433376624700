<ng-container *ngIf='filterOverlayClosed; else filterOverlayOpen'>
  <div class='d-flex flex-row align-items-center justify-content-center clickable'
       (click)='filterOverlayClosed = false'>
    <div class='arrow-close-container d-flex align-items-center justify-content-center'>
      <app-icon class='ms-2' name='expand-more'></app-icon>
    </div>
    <div class='show-filter-button-container'>
      Filter anzeigen
    </div>
  </div>
</ng-container>
<ng-template #filterOverlayOpen>
  <div class='filter-overlay-container'>
    <div class='arrow-close-container d-flex align-items-center justify-content-center clickable'
         (click)='filterOverlayClosed = true'>
      <app-icon class='ms-2' name='expand-less'></app-icon>
    </div>
    <div class='filter-sheet'>
      <div class='material-filter'>

        <div class='topic'>
          Filter
        </div>
        <div>
          <app-flat-button-single
            filter_name='Umkreis'
            [valuesToShow]='RADIUS'
            [selectedValue]='selectedRadius'
            (changedSelection)='updateFilter()'>
          </app-flat-button-single>
        </div>

        <div class='material-filter'>
          <div class='topic mt-2'>
            Projektfilter
          </div>
          <app-switch [(ngModel)]="showOwnProjects" (ngModelChange)='updateFilter()' class='mt-2'>Zeige meine Projekte
          </app-switch>
          <div>
            <app-flat-button-multi [disabled]="!showOwnProjects"
                                   filter_name='Gefiltert nach'
                                   [valuesToShow]='PROJECT_TYPES'
                                   [selectedValues]='selectedProjectTypes' (changedSelection)='updateFilter()'>
            </app-flat-button-multi>
          </div>
          <app-switch [(ngModel)]="showStaticSource" (ngModelChange)='updateFilter()' class='mt-2'>Zeige Quellen &
            Senken
          </app-switch>
          <div>
            <app-flat-button-multi [disabled]="!showStaticSource"
                                   filter_name='Gefiltert nach'
                                   [valuesToShow]='TYPES'
                                   [selectedValues]='selectedTypes' (changedSelection)='updateFilter()'>
            </app-flat-button-multi>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


