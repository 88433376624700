import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import { BaseInputComponent } from '../base-input.component';
import { MatSelectChange } from '@angular/material/select';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {DisplayEnumObject} from "../../../model/display-enum-object";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent<T> extends BaseInputComponent implements OnInit, OnDestroy {
  @Input() options: T[] | undefined;
  @Input() displayProperty = '';
  @Input() multiple = false;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() nonOption: boolean = false;
  @Input() selectedValues: DisplayEnumObject[] = [];
  @Input() hasFilter = false;

  @Output() optionSelected = new EventEmitter<T>();

  filter = new FormControl('');


  constructor(injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.hasFilter) {
      this.filter.valueChanges.pipe(takeUntil(this.destroy)).subscribe( searchString => {

      })
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onOptionChange(event: MatSelectChange): void {
    this.optionSelected.next(event.value as T);
  }
}
