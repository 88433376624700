import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'dateMonthFromNumber',
})

export class DateMonthFromNumberPipe implements PipeTransform {
  transform(value: number): Date {
    let result = value % 12;
    if (result === 0) {
      result = 12;
    }
    const date = new Date();
    date.setMonth(result - 1);
    return date;
  }
}
