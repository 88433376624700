import {Component, Input, OnInit} from '@angular/core';
import {Page} from "../../helper/page";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() currentPage: Page = new Page('', '');
  @Input() previousPages: Page[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
