import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MaterialFlowService } from '../../service/material-flow.service';
import { MaterialSite } from '../../model/material-site';
import { Subject } from 'rxjs';
import { EnumHelper } from '../../model/enum-helper';
import { selectEnumHelper } from '../../core/store/core.reducer';
import { Store } from '@ngrx/store';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressOption } from '../../interface/address-option';
import { GeoService } from '../../service/geo.service';
import { Utils } from '../../helper/utils';
import { MaterialSiteSearchRequest } from '../../model/material-site-search-request';
import { MatDialog } from '@angular/material/dialog';
import { SearchPoi } from './default-map/model-map';

@Component({
  selector: 'app-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.scss'],
  providers: [MaterialFlowService, DecimalPipe],
})
export class HereMapComponent implements OnInit, OnDestroy {
  @Input() width: string = '100%';
  @Input() height: string = '500px';
  @Input() loading = false;
  @Input() showSearchBox = false;
  @Input() isGanttChartMode: boolean | null = false;
  @Input() materialSites: MaterialSite[] = [];
  @Input() selectedMaterialSite: MaterialSite | null | undefined;
  @Input() initialMaterialSiteSearchRequest: MaterialSiteSearchRequest | null = null;
  @Input() isInMaterialFlowMode: boolean = true;

  @Output() selectedMaterialSiteEvent: EventEmitter<MaterialSite | null> = new EventEmitter();
  @Output() emitSearch: EventEmitter<MaterialSiteSearchRequest> = new EventEmitter();
  @Output() ganttToggleState: EventEmitter<boolean> = new EventEmitter();
  @Output() resetSearchEvent: EventEmitter<boolean> = new EventEmitter();

  private enumHelper?: EnumHelper;
  private cleanupSubject = new Subject<void>();

  searchForm: FormGroup;
  autocompleteResults: Array<AddressOption | MaterialSite> = [];
  autocompleteLoading = false;
  activeSearch: SearchPoi | null = null;
  valuesToShow = [0, 25, 50, 75, 100, 150, 200];
  selectedRadiusValue = 0;

  constructor(
    private materialFlowService: MaterialFlowService,
    private store: Store,
    private decimalPipe: DecimalPipe,
    private fb: FormBuilder,
    private geoService: GeoService,
    public dialog: MatDialog
  ) {
    this.searchForm = this.fb.group({
      address: [''],
    });
  }

  ngOnInit() {
    this.store
      .select(selectEnumHelper)
      .pipe(take(1))
      .subscribe(enumHelper => {
        this.enumHelper = enumHelper;
      });

    this.searchForm.controls['address'].valueChanges
      .pipe(debounceTime(500), takeUntil(this.cleanupSubject))
      .subscribe(change => {
        if (Boolean(change)) {
          if (typeof change === 'string') {
            if (change.trim().length <= 3) {
              return;
            }
            this.autocompleteLoading = true;
            this.geoService
              .getSuggestions$(change, 'GEO_SEARCH_ACCURACY_CITY')
              .pipe(take(1))
              .subscribe(suggestions => {
                let changeLowerCase = change.toLowerCase();
                let matchedMaterialSites =
                  this.materialSites?.filter(
                    materialSite =>
                      materialSite.name.toLocaleLowerCase().includes(changeLowerCase) ||
                      (materialSite?.address?.street ?? '').toLocaleLowerCase().includes(changeLowerCase)
                  ) ?? [];
                if (matchedMaterialSites.length > 5) {
                  matchedMaterialSites = matchedMaterialSites.slice(0, 5);
                }
                const materialSitesDelta = 10 - matchedMaterialSites.length;
                suggestions = suggestions.slice(0, materialSitesDelta);
                this.autocompleteResults = [...suggestions, ...matchedMaterialSites];
                this.autocompleteLoading = false;
              });
          } else {
            if (Utils.isMaterialSite(change)) {
              this.selectedMaterialSiteEvent.emit(change);
              this.activeSearch = new SearchPoi(change);
            } else {
              this.autocompleteLoading = true;
              this.geoService
                .getDetails$(this.searchForm.controls['address'].value.placeId)
                .pipe(take(1))
                .subscribe(suggestion => {
                  this.activeSearch = new SearchPoi(suggestion);
                  this.autocompleteLoading = false;
                });
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  setGanttToggleState(gannttToggleState: boolean) {
    this.ganttToggleState.emit(gannttToggleState);
  }

  resetSearchMarker() {
    this.activeSearch = null;
  }

  closeDetailBox() {
    this.selectedMaterialSiteEvent.emit(null);
  }

  resetFilter() {
    this.resetSearchEvent.emit();
  }
}
