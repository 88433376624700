<ng-container *ngIf='filterOverlayClosed; else filterOverlayOpen'>
    <div class='d-flex flex-row align-items-center justify-content-center clickable'
         (click)='filterOverlayClosed = false'>
        <div class='arrow-close-container d-flex align-items-center justify-content-center'>
            <app-icon class='ms-2' name='expand-more'></app-icon>
        </div>
        <div class='show-filter-button-container d-flex'>
            Filter anzeigen
        </div>
    </div>
</ng-container>
<ng-template #filterOverlayOpen>
    <div class='filter-overlay-container'>
        <div class='arrow-close-container d-flex align-items-center justify-content-center clickable'
             (click)='filterOverlayClosed = true'>
            <app-icon class='ms-2' name='expand-less'></app-icon>
        </div>
        <div class='filter-sheet'>
            <div class='material-filter'>
                <div class='topic'>
                    Materialfilter
                </div>
                <div>
                    <app-flat-button-multi
                            [disabled]='!isInMaterialFlowMode'
                            filter_name='Material'
                            [valuesToShow]='MATERIAL_TYPES'
                            [selectedValues]='selectedMaterialTypes'
                            (changedSelection)='updateMaterialSiteSearchRequest()'>
                    </app-flat-button-multi>
                </div>

                <ng-container *ngIf="selectedMaterialTypes.includes(materialTypeErsatzbaustoff!)">
                    <div>
                        <app-flat-button-multi
                                filter_name='Ersatzbaustoffe'
                                [valuesToShow]='ERSATZBAUSTOFFE'
                                [selectedValues]='selectedErsatzbaustoffe'
                                (changedSelection)='updateMaterialSiteSearchRequest()'>
                        </app-flat-button-multi>
                    </div>
                </ng-container>

                <div>
                    <app-flat-button-single
                            [disabled]='!isInMaterialFlowMode'
                            filter_name='Stoffstrom'
                            [valuesToShow]='MATERIAL_FLOW_DIRECTION'
                            [selectedValue]='selectedMaterialFlowDirection'
                            [wordingPipe]='materialFlowPipe' (changedSelection)='updateMaterialSiteSearchRequest()'>
                    </app-flat-button-single>
                </div>
                <div>
                    <app-flat-button-multi
                            filter_name='Qualität'
                            [valuesToShow]='QUALITY'
                            [selectedValues]='selectedQuality' (changedSelection)='updateMaterialSiteSearchRequest()'>
                    </app-flat-button-multi>
                </div>
                <div>
                    <app-flat-button-input
                            filter_name='Mindestmenge'
                            [selectedValue]='selectedMinValue'
                            (changedSelection)='updateMaterialSiteSearchRequest()'>
                    </app-flat-button-input>
                </div>
                <div>
                    <app-flat-button-date
                            filter_name='Verfügbar Ab'
                            [selectedValue]='selectedDateFrom'
                            (changedSelection)='updateMaterialSiteSearchRequest()'
                            [value_default]='"-"'>
                    </app-flat-button-date>
                </div>
                <div>
                    <app-flat-button-date
                            filter_name='Verfügbar Bis'
                            [selectedValue]='selectedDateTo'
                            (changedSelection)='updateMaterialSiteSearchRequest()'
                            [value_default]='"-"'>
                    </app-flat-button-date>
                </div>

                <div class='material-filter'>
                    <div class='topic mt-2'>
                        Projektfilter
                    </div>
                    <app-switch [(ngModel)]='showOwnProjects' (ngModelChange)='updateMaterialSiteSearchRequest()'
                                class='mt-2'>
                        Zeige meine Projekte
                    </app-switch>
                    <div>
                        <app-flat-button-multi [disabled]='!showOwnProjects'
                                               filter_name='Gefiltert nach'
                                               [valuesToShow]='PROJECT_TYPES'
                                               [selectedValues]='selectedProjectTypes'
                                               (changedSelection)='updateMaterialSiteSearchRequest()'>
                        </app-flat-button-multi>
                    </div>
                    <app-switch [(ngModel)]='showStaticSource' (ngModelChange)='updateMaterialSiteSearchRequest()'
                                class='mt-2'>
                        Zeige Quellen & Senken
                    </app-switch>
                    <div>
                        <app-flat-button-multi [disabled]='!showStaticSource'
                                               filter_name='Gefiltert nach'
                                               [valuesToShow]='STATIC_SOURCE_TYPES'
                                               [selectedValues]='selectedStaticSourceTypes'
                                               (changedSelection)='updateMaterialSiteSearchRequest()'>
                        </app-flat-button-multi>
                    </div>
                </div>
                <div class='align-items-center justify-content-center d-flex p-1' role='button'
                     (click)='resetSearch.emit()'>Filter zurücksetzen
                </div>
            </div>
        </div>

    </div>
</ng-template>


