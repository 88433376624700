<div [class.bg-gray]="!isBookingState">
  <div *ngIf="dataSource?.data" class="flex-column overflow-auto">
    <ng-container *ngIf="(isLoading$ | async)">
      <div class="d-flex loading-container align-items-center justify-content-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-container>
    <table matSort mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="materialColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Material</div></th>
        <td mat-cell *matCellDef="let material"><div class="colored-bg" appColorPicker [checkValue]="material?.type">{{material.type}}</div></td>
      </ng-container>
      <ng-container matColumnDef="materialFlowColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Stoffstrom</div></th>
        <td mat-cell *matCellDef="let material">{{enumHelper?.getDisplayEnumObject(material.flowDirection, MATERIAL_FLOW_DIRECTION)?.name}}</td>
      </ng-container>
      <ng-container matColumnDef="timePeriodColumn">
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label">Zeitraum</div></th>
          <td mat-cell *matCellDef="let material">
            <ng-container *ngIf="material.canAlwaysOccur; else timePeriod">{{'Zeitraum flexibel'}}</ng-container>
            <ng-template #timePeriod>{{material.occurrencePeriodStart | date:'dd.MM.yyyy'}} - {{material.occurrencePeriodEnd | date:'dd.MM.yyyy'}}</ng-template>
          </td>
      </ng-container>
      <ng-container matColumnDef="zuordnungsklasseColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Qualität</div></th>
        <td mat-cell *matCellDef="let material">
          <div class="d-flex flex-row flex-wrap" style="column-gap: 4px">
            <div *ngFor="let zuordnungsklasse of material.zuordnungsklassen; let i = index">
              <div>{{enumHelper?.getDisplayEnumObject(zuordnungsklasse, ZUORDNUNGSKLASSE)?.name}}<span *ngIf="i !== material.zuordnungsklassen.length - 1 && material.zuordnungsklassen.length > 1">,</span></div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="ersatzbaustoffColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Ersatzbaustoff</div></th>
        <td mat-cell *matCellDef="let material">{{enumHelper?.getDisplayEnumObject(material.ersatzbaustoff, ERSATZBAUSTOFF)?.name}}</td>
      </ng-container>
      <ng-container matColumnDef="quantityColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Menge</div></th>
        <td mat-cell *matCellDef="let material">{{material.quantity.massInTon ?? '-'}} t</td>
      </ng-container>
      <ng-container matColumnDef="volumeColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label">Volumen</div></th>
        <td mat-cell *matCellDef="let material">{{material.quantity.volumeInM3 ?? '-'}} m³</td>
      </ng-container>
      <ng-container matColumnDef="percentColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><div class="col-head-label"></div></th>
        <td mat-cell *matCellDef="let material">
          <div class="d-flex align-items-center justify-content-center position-relative">
            <img [src]="CIRCLE_ICON">
            <mat-progress-spinner class="position-absolute" color="primary" [diameter]="36" [strokeWidth]="2" [mode]="'determinate'" [value]="(material | overallProgressPipe)" ></mat-progress-spinner>
            <div class="progress-text">{{(material | overallProgressPipe) + '%'}}</div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="bookingButtonColumn">
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label"></div></th>
        <td mat-cell *matCellDef="let material"><img class="cursor-hand" [src]="BOOKING_ICON" (click)="materialSelected(material, true)"></td>
      </ng-container>
      <ng-container matColumnDef="detailButtonColumn">
        <th mat-header-cell *matHeaderCellDef><div class="col-head-label"></div></th>
        <td mat-cell *matCellDef="let material"><img class="cursor-hand" [src]="ARROW_ICON" (click)="materialSelected(material, false)"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
      <ng-container *ngIf="!isBookingState; else inBookingState">
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </ng-container>
      <ng-template #inBookingState>
        <tr mat-row class="cursor-hand" *matRowDef="let row; columns: displayedColumns;" (click)="materialSelected(row)" [class.selected-row]="isBookingState && selectedMaterialId === row.id"></tr>
      </ng-template>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <p class="text-center no-results">
            Es konnten keine Ergebnisse für die aktuelle Suche gefunden werden.
          </p>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20, 50, 100]"
                   [pageSize]="20"
                   [class.loading]="(isLoading$ | async)"
                   showFirstLastButtons
                   class="paginator"
                   aria-label="Seite von Materialien">
    </mat-paginator>
  </div>
</div>
