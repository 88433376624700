import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AbstractControlOptions, FormBuilder, FormGroup} from "@angular/forms"
import {
  SelectMaterialSiteDialogComponent
} from "../../feature/material-sites/components/material-site-detail/materials-info/edit-booking/select-material-site-dialog/select-material-site-dialog.component";
import {MaterialSite} from "../../model/material-site";
import {MatDialog} from "@angular/material/dialog";
import {combineLatest, Subject} from "rxjs";
import {debounceTime, startWith, takeUntil} from "rxjs/operators";
import {DensityCalculator} from "../../helper/densityCalculator";
import {NumberFormatter} from "../../helper/number-formatter";

@Component({
  selector: 'app-booking-input-tile',
  templateUrl: './booking-input-tile.component.html',
  styleUrls: ['./booking-input-tile.component.scss']
})
export class BookingInputTileComponent implements OnInit, OnDestroy {
  @Input() bookingForm: FormGroup;
  @Input() isMaterialFlowState: boolean = false;

  readonly MAGNIFIER_ICON = '../../assets/icons/magnifier_icon.svg';
  readonly CLOSE_ICON = '../../assets/icons/close_Icon.svg';
  readonly generalInformationIcon = '../../assets/icons/project_icon.png'

  public values = ['Masse', 'Volumen'];
  public calcCurrentMass = '';
  public calcTargetMass = '';
  public calcCurrentVolume = '';
  public calcTargetVolume = '';

  cleanupSubject = new Subject<void>();

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog) {
    this.bookingForm = this.formBuilder.group({
      materialSiteRef: null,
      materialSiteName: "",
      date: [],
      density: [''],
      volumeOrMass: ['Masse'],
      currentMass: [''],
      targetMass: [''],
      currentVolume: [''],
      targetVolume: [''],
      isMassPrimaryInput: undefined,
    }, {} as AbstractControlOptions);
  }

  ngOnInit(): void {
    this.bookingForm.controls['volumeOrMass'].valueChanges.pipe(debounceTime(300), takeUntil(this.cleanupSubject))
      .subscribe(selectedValue => {
        if (selectedValue === 'Masse') {
          this.bookingForm.controls['currentVolume'].disable();
          this.bookingForm.controls['currentVolume'].reset();
          this.bookingForm.controls['currentMass'].enable();
          this.bookingForm.controls['isMassPrimaryInput'].patchValue(true);
        } else if (selectedValue === 'Volumen') {
          this.bookingForm.controls['currentMass'].disable();
          this.bookingForm.controls['currentMass'].reset();
          this.bookingForm.controls['currentVolume'].enable();
          this.bookingForm.controls['isMassPrimaryInput'].patchValue(false);
        }
      });

    combineLatest([
      this.bookingForm.controls['density'].valueChanges.pipe(startWith(null)),
      this.bookingForm.controls['currentVolume'].valueChanges.pipe(startWith(null)),
      this.bookingForm.controls['currentMass'].valueChanges.pipe(startWith(null)),
      this.bookingForm.controls['targetMass'].valueChanges.pipe(startWith(null)),
      this.bookingForm.controls['targetVolume'].valueChanges.pipe(startWith(null)),
    ])
      .pipe(debounceTime(300), takeUntil(this.cleanupSubject))
      .subscribe((values) => {
        const [density, currentVolume, currentMass, targetMass, targetVolume] = values;
        let formattedDensity = NumberFormatter.ConvertFromNumericToDouble(density ?? '0');
        let formattedCurrentVolume = NumberFormatter.ConvertFromNumericToDouble(currentVolume ?? '0');
        let formattedCurrentMass = NumberFormatter.ConvertFromNumericToDouble(currentMass ?? '0');
        let formattedTargetMass = NumberFormatter.ConvertFromNumericToDouble(targetMass ?? '0');
        let formattedTargetVolume = NumberFormatter.ConvertFromNumericToDouble(targetVolume ?? '0');

        if (density && currentVolume) {
          this.calcCurrentMass = 'Die Masse beträgt '
            + DensityCalculator.calculateVolumeOrMass('Volumen', formattedDensity, formattedCurrentVolume)
            + ' t';
        } else {
          this.calcCurrentMass = '';
        }
        if (density && targetVolume) {
          this.calcTargetMass = 'Die Masse beträgt '
            + DensityCalculator.calculateVolumeOrMass('Volumen', formattedDensity, formattedTargetVolume)
            + ' t';
        } else {
          this.calcTargetMass = '';
        }

        if (density && currentMass) {
          this.calcCurrentVolume = 'Das Volumen beträgt '
            + DensityCalculator.calculateVolumeOrMass('Masse', formattedDensity, formattedCurrentMass)
            + ' m³';
        } else {
          this.calcCurrentVolume = '';
        }

        if (density && targetMass) {
          this.calcTargetVolume = 'Das Volumen beträgt '
            + DensityCalculator.calculateVolumeOrMass('Masse', formattedDensity, formattedTargetMass)
            + ' m³';
        } else {
          this.calcTargetVolume = '';
        }
      });
  }

  ngOnDestroy() {
    this.bookingForm.controls['currentMass'].enable();
    this.bookingForm.controls['currentVolume'].enable();

    this.cleanupSubject.next();
    this.cleanupSubject.complete();
  }

  openSearchMaterialSite() {
    const dialogRef = this.dialog.open(SelectMaterialSiteDialogComponent, {
      autoFocus: false,
      width: '100vw',
      maxWidth: '1000px',
    });

    dialogRef.afterClosed().subscribe(selectedMaterialSite => {
      if (selectedMaterialSite) {
        let materialSite = selectedMaterialSite as MaterialSite;
        this.bookingForm.controls['materialSiteName'].patchValue(materialSite.name)
        this.bookingForm.controls['materialSiteRef'].patchValue(materialSite.id)
      }
    });
  }
}
