import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @Output() fileDropped = new EventEmitter<Array<File>>();

  @HostBinding('class.dropHover') dropHoverClassActive = false;

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt: any): void {
    this.dropHoverClassActive = true;
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any): void {
    this.dropHoverClassActive = false;
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any): void {
    this.dropHoverClassActive = false;
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}
