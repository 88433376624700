import { createAction, props } from '@ngrx/store';
import { MaterialSiteSearchRequest } from '../../../model/material-site-search-request';
import { MaterialSite } from '../../../model/material-site';
import {MaterialSiteGanttSearchRequest} from "../../../model/material-site-gantt-search-request";

export const SEARCH_START = '[Material Flow] Search start';
export const SEARCH_SUCCESS = '[Material Flow] Search success';
export const SEARCH_FAILED = '[Material Flow] Search failed';
export const SET_SELECTED_MATERIAL_SITE = '[Material Flow] Set selected material-site';
export const GANTT_SEARCH_START = '[Material Flow] Gantt Search start';
export const GANTT_SEARCH_SUCCESS = '[Material Flow] Gantt Search success';
export const GANTT_SEARCH_FAILED = '[Material Flow] Gantt Search failed';
export const SET_SELECTED_MATERIAL_FLOW_STATE = '[Material Flow] Set selected material-flow state';

export const searchStart = createAction(SEARCH_START, props<{
  request: MaterialSiteSearchRequest,
  newRequest: boolean
}>());
export const searchSuccess = createAction(SEARCH_SUCCESS, props<{
  response: MaterialSite[],
  newRequest: boolean
}>());
export const searchFail = createAction(SEARCH_FAILED);

export const setSelectedMaterialSite = createAction(SET_SELECTED_MATERIAL_SITE, props<{ materialSite: MaterialSite | null }>());

export const ganttSearchStart = createAction(GANTT_SEARCH_START, props<{
  request: MaterialSiteGanttSearchRequest,
  newRequest: boolean
}>());
export const ganttSearchSuccess = createAction(GANTT_SEARCH_SUCCESS, props<{
  response: MaterialSite[],
  newRequest: boolean
}>());
export const ganttSearchFail = createAction(GANTT_SEARCH_FAILED);

export const setSelectedMaterialFlowState = createAction(SET_SELECTED_MATERIAL_FLOW_STATE, props<{ ganttChartSelection: boolean }>());
