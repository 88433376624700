import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  @Input() filter_name = 'filter_name';
  @Input() isActive = false;
  @Input() value = "";
  @Input() disabled = false;

  readonly arrow_down_icon = 'assets/icons/arrow_down.svg';
  readonly arrow_down_white_icon = 'assets/icons/arrow_down_white.svg';

  overlayOpen = false;

  constructor() { }

  ngOnInit(): void {

  }
}
