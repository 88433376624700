<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel" class="text-input w-100">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <img class="hintIcon" *ngIf="(inputHintIcon?.length ?? 0) > 0" matSuffix [src]="ICON_BASE + inputHintIcon" alt="Input type hint">
  <button type="button" [disabled]="control.disabled" matSuffix mat-icon-button aria-label="Clear" (click)="onClearButtonClick()" *ngIf="!isMultiLine && (control?.value?.length ?? 0) > 0" tabindex="-1">
    <img class="deleteIcon"  [src]="ICON_BASE + CLEAR_ICON" alt="Clear input">
  </button>
  <ng-container *ngIf="!isMultiLine; else multiLine">
    <input [type]="inputType"
           [formControl]="control"
           [errorStateMatcher]="errorStateMatcher"
           matInput>
  </ng-container>
  <ng-template #multiLine>
    <textarea class="textarea" matInput [formControl]="control"
              [errorStateMatcher]="errorStateMatcher">
    </textarea>
  </ng-template>
  <mat-hint *ngIf="(hint?.length ?? 0) > 0" >{{ hint }}</mat-hint>

<!-- Add more error messages here -> Content projection is not working with mat form field -->
  <mat-error *ngIf="errorTypes.includes('required') && control?.hasError('required')">Das ist ein Pflichtfeld</mat-error>
  <mat-error *ngIf="errorTypes.includes('email') && control?.hasError('email')">Bitte gültige E-Mail eingeben</mat-error>
  <mat-error *ngIf="(errorTypes.includes('minlength') && control?.hasError('minlength') ) ||
   (errorTypes.includes('maxlength') && control?.hasError('maxlength'))">
    Ungültige Zeichenanzahl
  </mat-error>
  <mat-error *ngIf="errorTypes.includes('invalidNumber') && control?.hasError('invalidNumber')">Bitte geben Sie einen gültigen Zahlenwert ein</mat-error>
  <mat-error *ngIf="errorTypes.includes('notSame') && control?.parent?.hasError('notSame')">Die eingegebenen Passwörter stimmen nicht überein!</mat-error>
<!--  -->
</mat-form-field>
