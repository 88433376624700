<mat-form-field class="text-input w-100" [appearance]="appearance">
  <mat-label *ngIf="label">{{ label }}</mat-label>
    <input [max]="max" [min]="min" [errorStateMatcher]="errorStateMatcher" [formControl]="control" matInput [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1" type="button">
      <img [src]="ICON_BASE + icon" alt="Date icon">
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="(hint?.length ?? 0) > 0" >{{ hint }}</mat-hint>

  <mat-error *ngIf="control?.hasError('required')">Das ist ein Pflichtfeld</mat-error>
  <mat-error *ngIf="control?.hasError('email')">Bitte gültige E-Mail eingeben</mat-error>

  <!-- Add more error messages here -> Content projection is not working with mat form field -->
  <!--  -->

</mat-form-field>
