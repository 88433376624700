<h2 mat-dialog-title>Passwort zurücksetzen</h2>
<form #pwForgotten="ngForm" (ngSubmit)="onSubmit(pwForgotten)">
<mat-dialog-content class="mat-typography">
  <div *ngIf="!passwordResetted">
    <div class="row">
      <div class="col-12 text">
        Bitte tragen Sie hier Ihre bei uns registrierte E-Mail Adresse ein. Sie erhalten anschließend einen Link zum Zurücksetzen Ihres Passwortes per Mail.
      </div>
    </div>
    <div class="row input-row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>E-Mail Adresse</mat-label>
          <input matInput
                 type="email"
                 class="form-control"
                 id="inputEmail"
                 ngModel
                 name="email"
                 required
                 email
          >
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row input-row" *ngIf="passwordResetted">
    <div class="col-12 text">
      Vielen Dank! Eine E-Mail zum Zurücksetzen Ihres Passwortes wird Ihnen in Kürze zugeschickt.
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="true">Schließen</button>
  <button mat-raised-button type="submit" color="primary" *ngIf="!passwordResetted">Senden</button>
</mat-dialog-actions>
</form>
