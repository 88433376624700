import {Directive, HostBinding, Input, OnChanges, SimpleChanges} from "@angular/core";

@Directive({
  selector: '[appColorPicker]'
})
export class ColorPickerDirective implements OnChanges{
  @Input() checkValue: string | undefined;
  @HostBinding('style.backgroundColor') colorValue = 'transparent';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['checkValue']) {
      switch (this.checkValue) {
        case 'Ton':
          this.colorValue = "#D8BC9B";
          break;
        case 'Lehm':
          this.colorValue = "#DBB090";
          break;
        case 'Ersatzbaustoff':
          this.colorValue = "#E8CAE3";
          break;
        case 'Sand':
          this.colorValue = "#EBCF87";
          break;
        case 'Kies':
          this.colorValue = "#D6D4D3";
          break;
        case 'Boden':
          this.colorValue = "#D8D1BB";
          break;
        case 'Schotter':
          this.colorValue = "#B5B5B5";
          break;
        default:
          this.colorValue = "transparent";
          break;
      }
    }
  }
}
