import {DisplayEnumObject} from './display-enum-object';
import {Utils} from '../helper/utils';
import {Bodenart} from '../enum/bodenart';
import {ENUM_CONSTS, EnumIdentifier} from '../types/enum-identifier';
import {MaterialType} from '../enum/material-type';
import {MaterialSiteType} from '../enum/material-site-type';
import {ProjectPhase} from '../enum/project-phase';
import {ProjectType} from "../enum/project-type";
import {StaticSourceType} from "../enum/static-source-type";
import {Deponieklasse} from "../enum/deponieklasse";
import {MaterialFlowDirection} from "../enum/material-flow-direction";
import {Zuordnungsklasse} from "../enum/zuordnungsklasse";
import {Ersatzbaustoff} from "../enum/ersatzbaustoff";
import {TeamUserRole} from "../enum/team-user-role";
import {FilterRadius} from "../enum/filter-radius";
import {MaterialFlowDirectionUserNeed} from "../enum/material-flow-direction-user-need";

export class EnumHelper {
  bodenart: DisplayEnumObject[];
  materialType: DisplayEnumObject[];
  materialSiteType: DisplayEnumObject[];
  projectPhase: DisplayEnumObject[];
  projectType: DisplayEnumObject[];
  staticSourceType: DisplayEnumObject[];
  deponieklasse: DisplayEnumObject[];
  materialFlowDirection: DisplayEnumObject[];
  materialFlowDirectionUserNeed: DisplayEnumObject[];
  zuordnungsklasse: DisplayEnumObject[];
  ersatzbaustoffe: DisplayEnumObject[];
  teamUserRole: DisplayEnumObject[];
  radius: DisplayEnumObject[];

  constructor() {
    this.bodenart = Utils.convertStrEnumToArray(Bodenart);
    this.materialType = Utils.convertStrEnumToArray(MaterialType);
    this.materialSiteType = Utils.convertStrEnumToArray(MaterialSiteType);
    this.projectPhase = Utils.convertStrEnumToArray(ProjectPhase);
    this.projectType = Utils.convertStrEnumToArray(ProjectType);
    this.staticSourceType = Utils.convertStrEnumToArray(StaticSourceType);
    this.deponieklasse = Utils.convertStrEnumToArray(Deponieklasse);
    this.materialFlowDirection = Utils.convertStrEnumToArray(MaterialFlowDirection);
    this.materialFlowDirectionUserNeed = Utils.convertStrEnumToArray(MaterialFlowDirectionUserNeed);
    this.zuordnungsklasse = Utils.convertStrEnumToArray(Zuordnungsklasse);
    this.ersatzbaustoffe = Utils.convertStrEnumToArray(Ersatzbaustoff);
    this.teamUserRole = Utils.convertStrEnumToArray(TeamUserRole);
    this.radius = Utils.convertRadiusStrEnumToArray(FilterRadius);
  }

  getDisplayEnumObject(enumValue: string | undefined, enumName: EnumIdentifier): DisplayEnumObject | undefined {
    if (enumValue) {
      switch (enumName) {
        case ENUM_CONSTS.BODENART: {
          return this.bodenart?.find(bodenart => bodenart.value === enumValue);
        }
        case ENUM_CONSTS.MATERIAL_TYPE: {
          return this.materialType?.find(materialType => materialType.value === enumValue);
        }
        case ENUM_CONSTS.MATERIAL_SITE_TYPE: {
          return this.materialSiteType?.find(materialType => materialType.value === enumValue);
        }
        case ENUM_CONSTS.PROJECT_PHASE: {
          return this.projectPhase?.find(materialType => materialType.value === enumValue);
        }
        case ENUM_CONSTS.PROJECT_TYPE: {
          return this.projectType?.find(materialType => materialType.value === enumValue);
        }
        case ENUM_CONSTS.STATIC_SOURCE_TYPE: {
          return this.staticSourceType?.find(staticSourceType => staticSourceType.value === enumValue);
        }
        case ENUM_CONSTS.DEPONIEKLASSEN: {
          return this.staticSourceType?.find(staticSourceType => staticSourceType.value === enumValue);
        }
        case ENUM_CONSTS.MATERIAL_FLOW_DIRECTION: {
          return this.materialFlowDirection?.find(materialFlowDirection => materialFlowDirection.value === enumValue);
        }
        case ENUM_CONSTS.MATERIAL_FLOW_DIRECTION_USER_NEED: {
          return this.materialFlowDirectionUserNeed?.find(materialFlowDirectionUserNeed => materialFlowDirectionUserNeed.value === enumValue);
        }
        case ENUM_CONSTS.ZUORDNUNGSKLASSE: {
          return this.zuordnungsklasse?.find(zuordnungsklasse => zuordnungsklasse.value === enumValue);
        }
        case ENUM_CONSTS.ERSATZBAUSTOFFE: {
          return this.ersatzbaustoffe?.find(ersatzbaustoffe => ersatzbaustoffe.value === enumValue);
        }
        case ENUM_CONSTS.TEAM_USER_ROLE: {
          return this.teamUserRole?.find(teamUserRole => teamUserRole.value === enumValue);
        }
        case ENUM_CONSTS.RADIUS: {
          return this.radius?.find(radius => radius.value === enumValue);
        }
        default: {
          return undefined;
        }
      }
    }
    return undefined;
  }

  getProjectEnumList(): DisplayEnumObject[] | undefined {
    let projectEnumList: DisplayEnumObject[];
    const internalProjectEnum = this.getDisplayEnumObject("internalProject", ENUM_CONSTS.MATERIAL_SITE_TYPE);
    const externalProjectEnum = this.getDisplayEnumObject("externalProject", ENUM_CONSTS.MATERIAL_SITE_TYPE);
    if (internalProjectEnum && externalProjectEnum) {
      projectEnumList = [internalProjectEnum, externalProjectEnum]
      return projectEnumList;
    } else {
      return undefined;
    }
  }

}
