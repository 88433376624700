import {Component, Input, OnInit} from '@angular/core';
import {ViewMode} from "../../feature/material-sites/ViewMode";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {ProjectsStateInterface} from "../../feature/material-sites/interface/projects-state.interface";
import {changeViewMode, importProjectListStart} from "../../feature/material-sites/store/materialSites.actions";
import {selectViewMode} from "../../feature/material-sites/store/materialSites.reducer";
import {MatDialog} from "@angular/material/dialog";
import {NewMaterialSiteDialogComponent} from "../../feature/material-sites/components/new-material-site-dialog/new-material-site-dialog.component";
import { Const } from '../../helper/const';


@Component({
  selector: 'app-feature-bar',
  templateUrl: './feature-bar.component.html',
  styleUrls: ['./feature-bar.component.scss']
})
export class FeatureBarComponent implements OnInit {
  @Input() isStaticSource = false;
  public ModeEnum = ViewMode;

  readonly ROUTES = Const.ROUTES;

  readonly map_view_icon = 'assets/icons/map.svg';
  readonly project_list_view_icon = 'assets/icons/project_list_icon.svg';
  readonly add_icon = 'assets/icons/add.svg';
  readonly import_projects_icon = 'assets/icons/import_projects_icon.svg';

  viewMode$: Observable<ViewMode>;

  constructor(private store: Store<ProjectsStateInterface>, public dialog: MatDialog) {
    this.viewMode$ = store.select(selectViewMode);
  }

  ngOnInit(): void {
  }

  changeViewModeInStore(VM: ViewMode) {
    this.store.dispatch(changeViewMode({payload: VM}))
  }

  onNewProject() {
    const dialogRef = this.dialog.open(NewMaterialSiteDialogComponent, {
      disableClose: true,
      width: '1000px',
      data: {
        isStaticSource: this.isStaticSource
      },
    });
  }

  onNewStaticSource() {
    const dialogRef = this.dialog.open(NewMaterialSiteDialogComponent, {
      disableClose: true,
      width: '1000px',
      data: {
        isStaticSource: this.isStaticSource
      },
    });
  }

  onImportProjectList() {

  }

  // @ts-ignore
  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.store.dispatch(importProjectListStart({file: event.target.files[0]}))
    }
  }

}
