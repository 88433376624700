import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ProjectsStateInterface} from '../../interface/projects-state.interface';
import {Store} from '@ngrx/store';
import * as FromProjectsActions from '../../store/materialSites.actions';
import {
  createProjectStart,
  getAddressFromGeoStart,
  resetAutoCompleteAndLookupResults
} from '../../store/materialSites.actions';
import {Address} from '../../../../model/address';
import deLang from 'i18n-iso-countries/langs/de.json';
import {
  selectAddressOptions,
  selectAutosuggestions,
  selectAutosuggestLoading,
  selectLoading,
  selectLocationLookupResult,
} from '../../store/materialSites.reducer';
import {debounceTime, take, takeUntil} from 'rxjs/operators';
import {Observable, Subject, combineLatest} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MaterialSiteType} from '../../../../enum/material-site-type';
import {MaterialSite} from '../../../../model/material-site';
import {GeoLocation} from '../../../../model/geo-location';
import {ContactPerson} from '../../../../model/contact-person';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getAlpha2Code, registerLocale} from 'i18n-iso-countries';
import {DisplayEnumObject} from '../../../../model/display-enum-object';
import {EnumHelper} from '../../../../model/enum-helper';
import {selectEnumHelper} from '../../../../core/store/core.reducer';
import {ENUM_CONSTS} from '../../../../types/enum-identifier';
import {StaticSourceType} from '../../../../enum/static-source-type';
import {AddressOption} from '../../../../interface/address-option';

@Component({
  selector: 'app-new-material-site-dialog',
  templateUrl: './new-material-site-dialog.component.html',
  styleUrls: ['./new-material-site-dialog.component.scss'],
})
export class NewMaterialSiteDialogComponent implements OnInit, OnDestroy {
  readonly clear_icon = '/assets/icons/clear.svg';

  newMaterialSiteForm: FormGroup;
  MATERIAL_SITE_TYPE = MaterialSiteType;
  STATIC_SOURCE_TYPE = StaticSourceType;

  private cleaningSubject = new Subject<void>();

  enumHelper$: Observable<EnumHelper> = this.store.select(selectEnumHelper);
  loading$: Observable<boolean> = this.store.select(selectLoading);
  autosuggestLoading$: Observable<boolean> = this.store.select(selectAutosuggestLoading);
  autocompleteResults$: Observable<AddressOption[] | null> = this.store.select(selectAutosuggestions);
  lookupResult$: Observable<AddressOption | null> = this.store.select(selectLocationLookupResult);
  addressFromGeoResult$: Observable<AddressOption[] | null> = this.store.select(selectAddressOptions);

  defaultMaterialSiteType: DisplayEnumObject | undefined;

  staticSourceTypeArray: DisplayEnumObject[] = [];
  deponieklassenArray: DisplayEnumObject[] = [];
  projectTypesArray: DisplayEnumObject[] = [];
  materialSiteTypesArray: DisplayEnumObject[] | undefined = [];

  constructor(
    private store: Store<ProjectsStateInterface>,
    private dialogRef: MatDialogRef<NewMaterialSiteDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { isStaticSource: boolean }
  ) {
    this.newMaterialSiteForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      projectType: [{value: '', disabled: true}, Validators.required],
      street: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      address: ['', Validators.required],
      lat: [null, Validators.required],
      lng: [null, Validators.required],
      staticSourceTypesControl: [{value: '', disabled: true}, Validators.required],
      deponieklassenControl: [{value: '', disabled: true}, Validators.required],
      addressSuffix: [null],
      mapSelect: [false]
    });
  }

  ngOnInit(): void {
    registerLocale(deLang);
    this.enumHelper$.pipe(take(1)).subscribe(enumHelper => {
      this.projectTypesArray = enumHelper.projectType;
      this.staticSourceTypeArray = enumHelper.staticSourceType;
      this.deponieklassenArray = enumHelper.deponieklasse;

      if (this.data.isStaticSource === true){
        this.materialSiteTypesArray = enumHelper.materialSiteType;
        this.defaultMaterialSiteType = enumHelper.getDisplayEnumObject('staticSource', ENUM_CONSTS.MATERIAL_SITE_TYPE);
        this.newMaterialSiteForm.controls['type'].patchValue(this.defaultMaterialSiteType);
        this.newMaterialSiteForm.controls['type'].disable();
        this.newMaterialSiteForm.controls['staticSourceTypesControl'].enable();
      } else {
        this.materialSiteTypesArray = enumHelper.getProjectEnumList();
        this.defaultMaterialSiteType = enumHelper.getDisplayEnumObject('internalProject', ENUM_CONSTS.MATERIAL_SITE_TYPE);
        this.newMaterialSiteForm.controls['type'].patchValue(this.defaultMaterialSiteType);
        this.newMaterialSiteForm.controls['projectType'].enable();
      }
    });

    combineLatest([this.lookupResult$, this.addressFromGeoResult$]).pipe(takeUntil(this.cleaningSubject))
      .subscribe(([response, addressOptions] ) => {
      if (response) {
        this.newMaterialSiteForm.controls['zip'].patchValue(response.zip);
        this.newMaterialSiteForm.controls['city'].patchValue(response.city);
        this.newMaterialSiteForm.controls['lat'].patchValue(response.lat);
        this.newMaterialSiteForm.controls['lng'].patchValue(response.lon);
        this.newMaterialSiteForm.controls['street'].patchValue(response.street);
        if (response.country) {
          this.newMaterialSiteForm.controls['country'].patchValue(getAlpha2Code(response.country, 'de'));
        } else {
          this.newMaterialSiteForm.controls['country'].patchValue('DE');
        }
      }

      if (addressOptions && addressOptions.length > 0) {
        let addressOption = addressOptions[0];
        this.newMaterialSiteForm.controls['zip'].patchValue(addressOption?.zip);
        this.newMaterialSiteForm.controls['city'].patchValue(addressOption?.city);
        this.newMaterialSiteForm.controls['street'].patchValue(addressOption?.street);
        this.newMaterialSiteForm.controls['address'].patchValue(addressOption, {emitEvent: false});
        if (addressOption.country) {
          this.newMaterialSiteForm.controls['country'].patchValue(getAlpha2Code(addressOption?.country, 'de'));
        } else {
          this.newMaterialSiteForm.controls['country'].patchValue('DE');
        }
      }
    });

    this.newMaterialSiteForm.controls['address'].valueChanges
      .pipe(debounceTime(500), takeUntil(this.cleaningSubject))
      .subscribe(change => {
        if (typeof change === 'string') {
          if (change.trim().length <= 5) {
            return;
          }
          this.store.dispatch(FromProjectsActions.getAutocompletionStart({query: change}));
        } else {
          this.store.dispatch(
            FromProjectsActions.getLookUpStart({id: this.newMaterialSiteForm.controls['address'].value?.placeId})
          );
        }
      });

    this.newMaterialSiteForm.controls['staticSourceTypesControl'].valueChanges.pipe(debounceTime(300), takeUntil(this.cleaningSubject))
      .subscribe(selectedStaticSourceType => {
        if (selectedStaticSourceType.name === StaticSourceType.Deponie){
          this.newMaterialSiteForm.controls['deponieklassenControl'].enable();
        }
        else {
          this.newMaterialSiteForm.controls['deponieklassenControl'].disable();
          this.newMaterialSiteForm.controls['deponieklassenControl'].reset();
        }
      });

    this.newMaterialSiteForm.controls['mapSelect'].valueChanges.pipe(takeUntil(this.cleaningSubject))
      .subscribe(isMapSelected => {
        if (isMapSelected === true){
          this.newMaterialSiteForm.controls['address'].disable();
          this.newMaterialSiteForm.controls['addressSuffix'].disable();
        }
        else {
          this.newMaterialSiteForm.controls['address'].enable();
          this.newMaterialSiteForm.controls['addressSuffix'].enable();
        }
      });
  }

  onCreateProject() {
    let geoLocation: GeoLocation = {
      latitude: this.newMaterialSiteForm.controls['lat'].value,
      longitude: this.newMaterialSiteForm.controls['lng'].value,
    };

    let address: Address = {
      street: this.newMaterialSiteForm.controls['street'].value,
      zip: this.newMaterialSiteForm.controls['zip'].value,
      city: this.newMaterialSiteForm.controls['city'].value,
      country: this.newMaterialSiteForm.controls['country'].value,
      additionalAddressInformation: this.newMaterialSiteForm.controls['addressSuffix'].value
    };

    const defaultMaterialSite: MaterialSite = {
      id: '',
      type: this.newMaterialSiteForm.controls['type'].value?.value,
      name: this.newMaterialSiteForm.controls['name'].value,
      internalId: '',
      description: '',
      address: address,
      geoLocation: geoLocation,
      projectType: undefined,
      projectPhase: undefined,
      staticSourceTypes: undefined,
      deponieklassen: undefined,
      attachments: [],
      contactPerson: new ContactPerson(),
      materials: [],
      projectStart: undefined,
      projectEnd: undefined,
      projectPrincipal: undefined,
      projectSubContractor: undefined,
      projectContractVolume: undefined,
      projectAreaSizeInM2: undefined,
    }

    let newMaterialSite: MaterialSite;

    if (this.newMaterialSiteForm.controls['type'].value?.name === MaterialSiteType.staticSource) {
      const staticSourcesValues = (this.newMaterialSiteForm.controls['staticSourceTypesControl'].value as DisplayEnumObject[]).map(sourceType => {
        return sourceType.value;
      });

      let deponieklassenValues = undefined;
      if (this.newMaterialSiteForm.controls['deponieklassenControl'].value){
        deponieklassenValues = (this.newMaterialSiteForm.controls['deponieklassenControl'].value as DisplayEnumObject[]).map(deponieklasse => {
          return deponieklasse.value;
        });
      }
      newMaterialSite = {
        ...defaultMaterialSite,
        staticSourceTypes: staticSourcesValues,
        deponieklassen: deponieklassenValues,
      };
    } else {
      newMaterialSite = {
        ...defaultMaterialSite,
        projectType: this.newMaterialSiteForm.controls['projectType'].value?.value,
      };
    }
    this.store.dispatch(createProjectStart({project: newMaterialSite}));
  }

  ngOnDestroy(): void {
    this.cleaningSubject.next();
    this.cleaningSubject.complete();
    this.store.dispatch(resetAutoCompleteAndLookupResults());
  }

  onSubmit() {
    if (this.newMaterialSiteForm.invalid) {
      return;
    }
    this.onCreateProject();
  }

  onClose() {
    this.dialogRef.close();
  }

  isDeponieSelected() {
    if (this.newMaterialSiteForm.controls['staticSourceTypesControl'].value == undefined) {
      this.newMaterialSiteForm.controls['deponieklassenControl'].disable();
      return false;
    }
    for (let staticSourceType of this.newMaterialSiteForm.controls['staticSourceTypesControl'].value as DisplayEnumObject[]) {
      if (staticSourceType.value === this.STATIC_SOURCE_TYPE.Deponie) {
        this.newMaterialSiteForm.controls['deponieklassenControl'].enable();
        return true;
      }
    }
    return false;
  }

  resetForm(): void {
    this.newMaterialSiteForm.controls['street'].reset();
    this.newMaterialSiteForm.controls['zip'].reset();
    this.newMaterialSiteForm.controls['country'].reset();
    this.newMaterialSiteForm.controls['lat'].reset();
    this.newMaterialSiteForm.controls['lng'].reset();
    this.newMaterialSiteForm.controls['address'].reset(undefined, {emitEvent: false});
  }

  getGeoCoordinates(geoCoordinates: GeoLocation) {
    this.newMaterialSiteForm.controls['lat'].patchValue(geoCoordinates.latitude);
    this.newMaterialSiteForm.controls['lng'].patchValue(geoCoordinates.longitude);
    this.store.dispatch(getAddressFromGeoStart({geolocation: geoCoordinates}));
  }
}
