import {Pipe, PipeTransform} from "@angular/core";
import {NumberFormatter} from "../../helper/number-formatter";

@Pipe({
  name: 'doubleToNumeric',
})
export class DoubleToNumericPipe implements PipeTransform {
  transform(value: number | undefined): any {
    return NumberFormatter.FromDoubleToNumeric(value);
  }
}
