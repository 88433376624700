import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {environment} from '../../environments/environment';
import {Material} from '../model/material';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MaterialSite} from '../model/material-site';
import {MaterialTransaction} from '../model/material-transaction';
import { MaterialSiteSearchRequest } from '../model/material-site-search-request';

@Injectable({providedIn: 'root'})
export class MaterialSiteService {

  readonly GET_MATERIAL_SITES_URL = environment.API.BASE + environment.API.MATERIALSITE;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {
  }

  private static getHeader(jwtToken: string): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(jwtToken),

      })
    }
  }

  loadMaterialSites$(teamID: string | undefined): Observable<MaterialSite[]> {
    return this.apiService.loadMaterialSites$(teamID);
  }

  searchMaterialSites$(request: MaterialSiteSearchRequest, teamID: string): Observable<MaterialSite[]> {
    return this.apiService.searchMaterialSites$(request, teamID);
  }

  createMaterialSite$(teamID: string | undefined, newMaterialSite: MaterialSite): Observable<MaterialSite> {
    return this.apiService.createMaterialSite$(teamID, newMaterialSite);
  }

  updateProject$(teamID: string | undefined, materialSite: MaterialSite): Observable<MaterialSite> {
    return this.apiService.updateMaterialSite$(teamID, materialSite);
  }

  deleteProject$(teamID: string | undefined, materialSiteID: string | undefined): Observable<void> {
    return this.apiService.deleteProject$(teamID, materialSiteID);
  }

  createMaterialSiteAttachment$(teamID: string, materialSiteID: string, formData: FormData, filename: string): Observable<MaterialSite> {
    const httpOptions = ApiService.getHeader(teamID, undefined, true);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/' + materialSiteID + environment.API.ATTACHMENT + '?filename=' + filename;
    return this.httpClient.post<MaterialSite>(url, formData, httpOptions)
  }

  deleteMaterialSiteAttachment$(teamID: string, materialSiteID: string, attachmentId: string): Observable<MaterialSite> {
    const httpOptions = ApiService.getHeader(teamID);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.ATTACHMENT + '/' + attachmentId;
    return this.httpClient.delete<MaterialSite>(url, httpOptions)
  }

  getMaterialSite$(teamID: string, materialSiteID: string): Observable<MaterialSite> {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/' + materialSiteID;
    return this.httpClient.get<MaterialSite>(url, httpOptions);
  }

  createNewMaterial$(material: Partial<Material>, teamID: string, materialSiteID: string): Observable<Material> {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/' + materialSiteID + environment.API.MATERIAL;
    return this.httpClient.post<Material>(url, JSON.stringify(material), httpOptions);
  }

  updateMaterial$(material: Partial<Material>, teamID: string, materialSiteID: string, materialID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/' + materialSiteID + environment.API.MATERIAL + '/' + materialID;
    return this.httpClient.put<Material>(url, JSON.stringify(material), httpOptions);
  }

  deleteMaterial$(teamID: string, materialSiteID: string, materialID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url = environment.API.BASE + environment.API.MATERIALSITE + '/' + materialSiteID + environment.API.MATERIAL + '/' + materialID;
    return this.httpClient.delete<Material>(url, httpOptions);
  }

  createMaterialAttachment$(teamID: string, materialSiteID: string, materialID: string, formData: FormData, filename: string): Observable<Material> {
    const httpOptions = ApiService.getHeader(teamID, undefined, true);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.MATERIAL + '/' + materialID +
      environment.API.ATTACHMENT + '?filename=' + filename;
    return this.httpClient.post<Material>(url, formData, httpOptions)
  }

  deleteMaterialAttachment$(teamID: string, materialSiteID: string, materialID: string, attachmentId: string): Observable<Material> {
    const httpOptions = ApiService.getHeader(teamID);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.MATERIAL + '/' + materialID +
      environment.API.ATTACHMENT + '/' + attachmentId;
    return this.httpClient.delete<Material>(url, httpOptions)
  }

  createMaterialTransaction$(materialTransaction: Partial<MaterialTransaction>, materialSiteID: string, materialID: string, teamID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.MATERIAL + '/' + materialID +
      environment.API.MATERIAL_TRANSACTION
    // console.log(JSON.stringify(materialTransaction));
    return this.httpClient.post<MaterialTransaction>(url, JSON.stringify(materialTransaction), httpOptions);
  }

  updateMaterialTransaction$(materialTransaction: MaterialTransaction, materialSiteID: string, materialID: string, materialTransactionID: string, teamID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.MATERIAL + '/' + materialID +
      environment.API.MATERIAL_TRANSACTION + '/' + materialTransactionID;
    return this.httpClient.put<MaterialTransaction>(url, JSON.stringify(materialTransaction), httpOptions);
  }

  deleteMaterialTransaction$(materialTransactionID: string, materialSiteID: string, materialID: string, teamID: string) {
    const httpOptions = ApiService.getHeader(teamID);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE + '/' + materialSiteID +
      environment.API.MATERIAL + '/' + materialID +
      environment.API.MATERIAL_TRANSACTION + '/' + materialTransactionID;
    return this.httpClient.delete<MaterialTransaction>(url, httpOptions);
  }

  importMaterialSiteCSV$(teamID: string, formData: FormData): Observable<{success: boolean, errors: [{lineNumber: number, reason: string, message: string}]}> {
    const httpOptions = ApiService.getHeader(teamID, undefined, true);
    const url =
      environment.API.BASE +
      environment.API.MATERIALSITE +
      environment.API.IMPORT_MATERIAL_SITE;
    return this.httpClient.post<{success: boolean, errors: [{lineNumber: number, reason: string, message: string}]}>(url, formData, httpOptions)
  }

  searchMaterialSitesForGanttChart$(request: MaterialSiteSearchRequest, teamID: string): Observable<MaterialSite[]> {
    const httpOptions = ApiService.getHeader(teamID);
    const newRequest = structuredClone(request);
    const url = environment.API.BASE + environment.API.MATERIALSITE + environment.API.GANTT_SEARCH;
    return this.httpClient.post<MaterialSite[]>(url, JSON.stringify(newRequest), httpOptions);
  }
}
