import { createAction, props } from '@ngrx/store';
import { ViewMode } from '../ViewMode';
import { MaterialSite } from '../../../model/material-site';
import { Material } from '../../../model/material';
import { MaterialTransaction } from '../../../model/material-transaction';
import { ProjectFilter } from '../../../model/project-filter';
import { AddressOption } from '../../../interface/address-option';
import {MaterialSiteSearchRequest} from "../../../model/material-site-search-request";
import {GeoLocation} from "../../../model/geo-location";

export const SEARCH_START = '[Projects] Search start';
export const SEARCH_SUCCESS = '[Projects] Search success';
export const SEARCH_FAILED = '[Projects] Search failed';
export const CHANGE_VIEW_MODE = '[Projects] Change View Mode';
export const SET_SELECTED_PROJECT = '[Projects] Set selected material-sites';

export const CREATE_PROJECT_START = '[Projects] Create material-site start';
export const CREATE_PROJECT_SUCCESS = '[Projects] Create material-site success';
export const CREATE_PROJECT_FAIL = '[Projects] Create material-site fail';

export const CREATE_MATERIAL_START = '[Projects] Create material start';
export const CREATE_MATERIAL_SUCCESS = '[Projects] Create material success';
export const CREATE_MATERIAL_FAIL = '[Projects] Create material fail';

export const UPDATE_MATERIAL_START = '[Projects] Update material start';
export const UPDATE_MATERIAL_SUCCESS = '[Projects] Update material success';
export const UPDATE_MATERIAL_FAIL = '[Projects] Update material fail';

export const DELETE_MATERIAL_START = '[Projects] Delete material start';
export const DELETE_MATERIAL_SUCCESS = '[Projects] Delete material success';
export const DELETE_MATERIAL_FAIL = '[Projects] Delete material fail';

export const SET_SELECTED_MATERIAL = '[Projects] Set selected material';

export const UPDATE_PROJECT_START = '[Projects] Update material-sites start';
export const UPDATE_PROJECT_SUCCESS = '[Projects] Update material-sites success';
export const UPDATE_PROJECT_FAIL = '[Projects] Update material-sites fail';

export const DELETE_PROJECT_START = '[Projects] Delete material-sites start';
export const DELETE_PROJECT_SUCCESS = '[Projects] Delete material-sites success';
export const DELETE_PROJECT_FAIL = '[Projects] Delete material-sites fail';

export const GET_MATERIAL_SITE_START = '[Projects] Get material-site start';
export const GET_MATERIAL_SITE_SUCCESS = '[Projects] Get material-site success';
export const GET_MATERIAL_SITE_FAIL = '[Projects] Get material-site fail';

export const CREATE_MATERIALSITE_ATTACHMENT_START = '[Projects] Create material-site attachment start';
export const CREATE_MATERIALSITE_ATTACHMENT_SUCCESS = '[Projects] Create material-site attachment success';
export const CREATE_MATERIALSITE_ATTACHMENT_FAIL = '[Projects] Create material-site attachment fail';

export const IMPORT_PROJECTLIST_START = '[Projects] Import project-list start';
export const IMPORT_PROJECTLIST_SUCCESS = '[Projects] Import project-list success';
export const IMPORT_PROJECTLIST_FAIL = '[Projects] Import project-list fail';

export const DELETE_MATERIALSITE_ATTACHMENT_START = '[Projects] Delete material-site attachment start';
export const DELETE_MATERIALSITE_ATTACHMENT_SUCCESS = '[Projects] Delete material-site attachment success';
export const DELETE_MATERIALSITE_ATTACHMENT_FAIL = '[Projects] Delete material-site attachment fail';

export const CREATE_MATERIAL_ATTACHMENT_START = '[Projects] Create material attachment start';
export const CREATE_MATERIAL_ATTACHMENT_SUCCESS = '[Projects] Create material attachment success';
export const CREATE_MATERIAL_ATTACHMENT_FAIL = '[Projects] Create material attachment fail';

export const DELETE_MATERIAL_ATTACHMENT_START = '[Projects] Delete material attachment start';
export const DELETE_MATERIAL_ATTACHMENT_SUCCESS = '[Projects] Delete material attachment success';
export const DELETE_MATERIAL_ATTACHMENT_FAIL = '[Projects] Delete material attachment fail';

export const CREATE_MATERIAL_TRANSACTION_START = '[Projects] Create material transaction start';
export const CREATE_MATERIAL_TRANSACTION_SUCCESS = '[Projects] Create material transaction success';
export const CREATE_MATERIAL_TRANSACTION_FAIL = '[Projects] Create material transaction fail';

export const UPDATE_MATERIAL_TRANSACTION_START = '[Projects] Update material transaction start';
export const UPDATE_MATERIAL_TRANSACTION_SUCCESS = '[Projects] Update material transaction success';
export const UPDATE_MATERIAL_TRANSACTION_FAIL = '[Projects] Update material transaction fail';

export const DELETE_MATERIAL_TRANSACTION_START = '[Projects] Delete material transaction start';
export const DELETE_MATERIAL_TRANSACTION_SUCCESS = '[Projects] Delete material transaction success';
export const DELETE_MATERIAL_TRANSACTION_FAIL = '[Projects] Delete material transaction fail';

export const SET_SELECTED_MATERIAL_TRANSACTION = '[Projects] Set selected material transaction';
export const SET_NEW_MATERIAL_TRANSACTION = '[Projects] Set new material transaction';

export const GET_AUTOCOMPLETION_START = '[Projects] Get autocompletion start';
export const GET_AUTOCOMPLETION_SUCCESS = '[Projects] Get autocompletion success';
export const GET_AUTOCOMPLETION_FAIL = '[Projects] Get autocompletion fail';

export const RESET_AUTOCOMPLETION_AND_LOOKUP_RESULTS = '[Projects] Reset autocompletion and lookup results';

export const GET_LOOKUP_START = '[Projects] Get look up start';
export const GET_LOOKUP_SUCCESS = '[Projects] Get look up success';
export const GET_LOOKUP_FAIL = '[Projects] Get look up fail';

export const GET_MATERIAL_SITES_TO_BOOK_FROM_START = '[Projects] Get Material Sites to book from Start'
export const GET_MATERIAL_SITES_TO_BOOK_FROM_SUCCES = '[Projects] Get Material Sites to book from Success'

export const GET_ADDRESS_FROM_GEO_START = '[Projects] Get Address from Geo start';
export const GET_ADDRESS_FROM_GEO_SUCCESS = '[Projects] Get Address from Geo success';
export const GET_ADDRESS_FROM_GEO_FAIL = '[Projects] Get Address from Geo fail';

export const RESET_ADDRESS_FROM_GEO_START = '[Projects] Reset Address from Geo Start';


export const searchStart = createAction(SEARCH_START, props<{
  newRequest: boolean,
  filter?: ProjectFilter
}>());
export const searchSuccess = createAction(SEARCH_SUCCESS, props<{
  response: MaterialSite[],
  newRequest: boolean
}>());
export const searchFail = createAction(SEARCH_FAILED);

export const importProjectListStart = createAction(IMPORT_PROJECTLIST_START, props<{file: File }>());
export const importProjectListSuccess = createAction(IMPORT_PROJECTLIST_SUCCESS);
export const importProjectListFail = createAction(IMPORT_PROJECTLIST_FAIL);

export const changeViewMode = createAction(CHANGE_VIEW_MODE, props<{ payload: ViewMode }>());

export const setSelectedProject = createAction(SET_SELECTED_PROJECT, props<{ project: MaterialSite }>());

export const createProjectStart = createAction(CREATE_PROJECT_START, props<{ project: MaterialSite }>());

export const createProjectSuccess = createAction(CREATE_PROJECT_SUCCESS, props<{ project: MaterialSite }>());

export const createProjectFail = createAction(CREATE_PROJECT_FAIL);

export const updateProjectStart = createAction(UPDATE_PROJECT_START, props<{
  project: MaterialSite,
}>());

export const updateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS, props<{
  materialSite: MaterialSite,
}>());
export const updateProjectFail = createAction(UPDATE_PROJECT_FAIL);

export const deleteProjectStart = createAction(DELETE_PROJECT_START, props<{
  materialSiteID: string | undefined,
  request: any,
  newRequest: boolean
}>());
export const deleteProjectSuccess = createAction(DELETE_PROJECT_SUCCESS, props<{
  newRequest: boolean,
  materialSiteID: string | undefined,
}>());
export const deleteProjectFail = createAction(DELETE_PROJECT_FAIL);


export const createMaterialStart = createAction(CREATE_MATERIAL_START, props<{
  material: Partial<Material>,
}>());

export const createMaterialSuccess = createAction(
  CREATE_MATERIAL_SUCCESS, props<{
    material: Material,
    materialSiteID: string,
  }>());

export const createMaterialFail = createAction(CREATE_MATERIAL_FAIL);

export const updateMaterialStart = createAction(UPDATE_MATERIAL_START, props<{
  material: Partial<Material>
}>());

export const updateMaterialSuccess = createAction(
  UPDATE_MATERIAL_SUCCESS, props<{
    material: Material,
    materialSiteID: string,
  }>());

export const updateMaterialFail = createAction(UPDATE_MATERIAL_FAIL);

export const deleteMaterialStart = createAction(DELETE_MATERIAL_START);
export const deleteMaterialSuccess = createAction(
  DELETE_MATERIAL_SUCCESS, props<{
    material: Material,
    materialID: string,
  }>());
export const deleteMaterialFail = createAction(DELETE_MATERIAL_FAIL);

export const createMaterialAttachmentStart = createAction(CREATE_MATERIAL_ATTACHMENT_START, props<{ files: File[] }>());
export const createMaterialAttachmentSuccess = createAction(CREATE_MATERIAL_ATTACHMENT_SUCCESS, props<{ material: Material }>());
export const createMaterialAttachmentFail = createAction(CREATE_MATERIAL_ATTACHMENT_FAIL);

export const deleteMaterialAttachmentStart = createAction(DELETE_MATERIAL_ATTACHMENT_START, props<{ attachmentID: string }>());
export const deleteMaterialAttachmentSuccess = createAction(DELETE_MATERIAL_ATTACHMENT_SUCCESS, props<{
  attachmentID: string,
}>());
export const deleteMaterialAttachmentFail = createAction(DELETE_MATERIAL_ATTACHMENT_FAIL);

export const setSelectedMaterial = createAction(SET_SELECTED_MATERIAL, props<{ material: Material }>());

export const getMaterialSiteStart = createAction(GET_MATERIAL_SITE_START, props<{
  materialSiteID: string
}>());

export const getMaterialSiteSuccess = createAction(
  GET_MATERIAL_SITE_SUCCESS, props<{
    materialSite: MaterialSite
  }>());

export const getMaterialSiteFail = createAction(GET_MATERIAL_SITE_FAIL);


export const createMaterialSiteAttachmentStart = createAction(CREATE_MATERIALSITE_ATTACHMENT_START, props<{ files: File[] }>());
export const createMaterialSiteAttachmentSuccess = createAction(CREATE_MATERIALSITE_ATTACHMENT_SUCCESS, props<{ materialSite: MaterialSite }>());
export const createMaterialSiteAttachmentFail = createAction(CREATE_MATERIALSITE_ATTACHMENT_FAIL);

export const deleteMaterialSiteAttachmentStart = createAction(DELETE_MATERIALSITE_ATTACHMENT_START, props<{ attachmentID: string }>());
export const deleteMaterialSiteAttachmentSuccess = createAction(DELETE_MATERIALSITE_ATTACHMENT_SUCCESS, props<{
  attachmentID: string,
}>());

export const deleteMaterialSiteAttachmentFail = createAction(DELETE_MATERIALSITE_ATTACHMENT_FAIL);

export const createMaterialTransactionStart = createAction(CREATE_MATERIAL_TRANSACTION_START, props<{
  materialTransaction: MaterialTransaction
}>());

export const createMaterialTransactionSuccess = createAction(
  CREATE_MATERIAL_TRANSACTION_SUCCESS, props<{
    materialTransaction: MaterialTransaction
  }>());

export const createMaterialTransactionFail = createAction(CREATE_MATERIAL_TRANSACTION_FAIL);

export const updateMaterialTransactionStart = createAction(UPDATE_MATERIAL_TRANSACTION_START, props<{
  materialTransaction: MaterialTransaction
}>());

export const updateMaterialTransactionSuccess = createAction(
  UPDATE_MATERIAL_TRANSACTION_SUCCESS, props<{
    materialTransaction: MaterialTransaction,
  }>());

export const updateMaterialTransactionFail = createAction(UPDATE_MATERIAL_TRANSACTION_FAIL);

export const deleteMaterialTransactionStart = createAction(DELETE_MATERIAL_TRANSACTION_START);

export const deleteMaterialTransactionSuccess = createAction(
  DELETE_MATERIAL_TRANSACTION_SUCCESS, props<{
    materialTransaction: MaterialTransaction,
    materialTransactionID: string
  }>());

export const deleteMaterialTransactionFail = createAction(DELETE_MATERIAL_TRANSACTION_FAIL);

export const setSelectedMaterialTransaction = createAction(SET_SELECTED_MATERIAL_TRANSACTION, props<{ materialTransaction: MaterialTransaction | null }>());
export const setNewMaterialTransaction = createAction(SET_NEW_MATERIAL_TRANSACTION, props<{ isInTransactionCreateMode: boolean }>());

export const getAutocompletionStart = createAction(GET_AUTOCOMPLETION_START, props<{query: string, addressOption?: AddressOption }>());
export const getAutocompletionSuccess = createAction(GET_AUTOCOMPLETION_SUCCESS, props<{autosuggestions: AddressOption[]}>());
export const getAutocompletionFail = createAction(GET_AUTOCOMPLETION_FAIL);

export const resetAutoCompleteAndLookupResults = createAction(RESET_AUTOCOMPLETION_AND_LOOKUP_RESULTS);


export const getLookUpStart = createAction(GET_LOOKUP_START, props<{id: string}>());
export const getLookUpSuccess = createAction(GET_LOOKUP_SUCCESS, props<{autosuggestion: AddressOption }>());
export const getLookUpFail = createAction(GET_LOOKUP_FAIL);

export const searchMaterialSitesToBookFromStart = createAction(GET_MATERIAL_SITES_TO_BOOK_FROM_START, props<{
  request: MaterialSiteSearchRequest,
  newRequest: boolean
}>());
export const searchMaterialSitesToBookFromSuccess = createAction(GET_MATERIAL_SITES_TO_BOOK_FROM_SUCCES, props<{
  response: MaterialSite[],
  newRequest: boolean
}>());

export const getAddressFromGeoStart = createAction(GET_ADDRESS_FROM_GEO_START, props<{geolocation: GeoLocation}>());
export const getAddressFromGeoSuccess = createAction(GET_ADDRESS_FROM_GEO_SUCCESS, props<{addressOptions: AddressOption[] }>());
export const getAddressFromGeoFail = createAction(GET_ADDRESS_FROM_GEO_FAIL);

export const resetAddressFromGeoStart = createAction(RESET_ADDRESS_FROM_GEO_START);
