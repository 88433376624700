import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {InfoBoxPreferences} from "../../model/info-box-preferences";
import {SectionType} from "../../model/section-type";
import {MaterialSiteSearchRequest} from "../../model/material-site-search-request";

export const PreferencesActions = createActionGroup({
  source: 'Preferences',
  events: {
    'Get Info Box Preferences Start': emptyProps(),
    'Get Info Box Preferences Success': props<{infoBoxPreferences: InfoBoxPreferences}>(),
    'Set Info Box Preferences Start': props<{sectionType: SectionType, show: boolean}>(),
    'Set Info Box Preferences Success': emptyProps(),

    'Get View Mode Materials Preferences Start': emptyProps(),
    'Get View Mode Materials Preferences Success': props<{materialsViewModeIsTable: boolean}>(),
    'Set View Mode Materials Preferences Start': props<{materialsViewModeIsTable: boolean}>(),
    'Set View Mode Materials Preferences Success': emptyProps(),

    'Get Filter Preferences Start': emptyProps(),
    'Get Filter Preferences Success': props<{materialSiteSearchRequest: MaterialSiteSearchRequest}>(),
    'Set Filter Preferences Success': emptyProps(),

    'Get Material Site Search Request For Material Flow Component': emptyProps(),
    'Set Material Site Search Request For Material Flow Component': props<{materialSiteSearchRequest: MaterialSiteSearchRequest}>(),

  },
});
