<div class="d-flex flex-row justify-content-between head-row">
  <h2>
    Legende
  </h2>
  <div>
    <img class="cursor-hand" src="assets/icons/close_Icon.svg" (click)="onClose()">
  </div>
</div>
<div class="d-flex flex-row flex-wrap gap-4">
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_my_search.svg'>
    <h5>
      Meine Suche
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_internal_project.svg'>
    <h5>
      Meine Projekte
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_aufbereitungsanlage.svg'>
    <h5>
      Aufbereitungsanlage
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_deponie.svg'>
    <h5>
      Deponie
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_kiesgrube.svg'>
    <h5>
      Kiesgrube
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_sandgrube.svg'>
    <h5>
      Sandgrube
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_steinbruch.svg'>
    <h5>
      Steinbruch
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_tongrube.svg'>
    <h5>
      Tongrube
    </h5>
  </div>
  <div class="d-flex flex-row align-items-center gap-1">
    <img class="icon" src='assets/icons/pin_group.svg'>
    <h5>
      Menge von nahe beieinander liegenden Pins
    </h5>
  </div>
</div>


