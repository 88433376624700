import {formatNumber} from "@angular/common";

export class NumberFormatter {

  static ConvertFromNumericToDouble(value: string): any {
    if (value){
      value.toString();
      value = value.replace(/\./g, '');
      value = value.replace(/,/g, '.');
    }
    return value;
  }

  static FromDoubleToNumeric(value: number | undefined) {
    let newValue = null;
    if (value !== undefined) {
      newValue =  formatNumber(value, "de", "1.2-3")
    }
    return  newValue
  }
}
