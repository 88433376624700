<div *ngIf="(loading$ | async) === false" class="p-2">
    <div class="row justify-content-between align-items-center topic-row">
      <div class="col-10">
        <ng-container *ngIf="data.isStaticSource === false; else isStaticSource">
          <h2>Neues Projekt erstellen</h2>
        </ng-container>
        <ng-template #isStaticSource>
          <h2>Neue Quelle / Senke erstellen</h2>
        </ng-template>
      </div>
      <div class="col-2 close-button">
        <img class="cursor-hand" src="../../../../../assets/icons/close_Icon.svg" (click)="onClose()">
      </div>
  </div>
  <div class="container form-container">
    <form [formGroup]="newMaterialSiteForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-4">
          <app-text-input label="Projektname" formControlName="name" ></app-text-input>
        </div>
        <div class="col-4" *ngIf="data.isStaticSource === false">
          <app-select-input
            [label]="'Projekttyp'"
            [options]="materialSiteTypesArray"
            [displayProperty]="'name'"
            formControlName="type" >
          </app-select-input>
        </div>
        <div class="col-4">
          <div class="row gy-3">
            <div class="col-12">
              <ng-container *ngIf="newMaterialSiteForm.controls['type'].value?.name !== MATERIAL_SITE_TYPE.staticSource; else staticType">
                <app-select-input
                  [label]="'Bauvorhaben'"
                  [options]="projectTypesArray"
                  [displayProperty]="'name'"
                  formControlName="projectType" >
                </app-select-input>
              </ng-container>
              <ng-template #staticType>
                <app-select-input
                  [label]="'Quellen/Senken'"
                  [multiple]=" true"
                  *ngIf="newMaterialSiteForm.controls['type'].value?.name === MATERIAL_SITE_TYPE.staticSource"
                  [options]="staticSourceTypeArray"
                  [displayProperty]="'name'"
                  formControlName="staticSourceTypesControl" >
                </app-select-input>
              </ng-template>
            </div>
          </div>
        </div>
        <ng-container *ngIf="staticType && isDeponieSelected()">
          <div class="col-4">
            <app-select-input
              [label]="'Deponieklassen'"
              [multiple]="true"
              [options]="deponieklassenArray"
              [displayProperty]="'name'"
              formControlName="deponieklassenControl" >
            </app-select-input>
          </div>
        </ng-container>

      </div>
      <hr>
         <div class="row align-items-center">
           <div class="col-4">
             <app-address-input
               [label]="'Bitte Adresse des Projekts angeben'"
               (clearButtonClicked)="resetForm()"
               [addressOptions]="(autocompleteResults$ | async) ?? []"
               [loadingState]="(autosuggestLoading$ | async)"
               formControlName="address">
             </app-address-input>
           </div>
           <div class="col-4">
             <app-text-input
               [label]="'Adresszusatz'"
               (clearButtonClicked)="resetForm()"
               formControlName="addressSuffix">
             </app-text-input>
           </div>
           <div class="col-4">
             <div class="d-flex pb-3">
               <app-switch [label]="'Standort auf Karte auswählen'" formControlName="mapSelect"></app-switch>
               <h5 class="header5">Standort auf Karte auswählen</h5>
             </div>
           </div>
         </div>
      <hr>

      <div class="row mb-2 map-row" *ngIf="newMaterialSiteForm.controls['mapSelect'].value === true">
        <div class="col-12">
          <app-static-map [height]="'50vh'" [width]="'auto'"
                          [lat]="newMaterialSiteForm.controls['lat'].value"
                          [lng]="newMaterialSiteForm.controls['lng'].value"
                          [zoom]="newMaterialSiteForm.controls['lat'].value? 10 : 6"
                          (geoCoordinates)="getGeoCoordinates($event)"
                          style="border-radius: 4px">
          </app-static-map>
        </div>
      </div>
      <button mat-raised-button class="w-100 submit-button" color="primary" mat-dialog-close="true" type="submit"
              *ngIf="(loading$ | async) === false"
              [disabled]="newMaterialSiteForm.invalid">
        Projekt erstellen
      </button>
    </form>
  </div>
</div>
<app-loading-spinner *ngIf="(loading$ | async)"></app-loading-spinner>


